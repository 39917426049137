import { useDatabase, useDatabaseObjectData } from 'reactfire';
import { mapBoundaryPath } from 'database';
import { IMapBoundaryDoc } from 'database/maps/MapBoundary';

export const useMapBoundary = (folderID: string, mapID: string): IMapBoundaryDoc | undefined => {
    const database = useDatabase();
    const mapRef = database.ref(mapBoundaryPath({ folderID, mapID }));
    const { data: boundary } = useDatabaseObjectData<IMapBoundaryDoc>(mapRef, { idField: 'id' });

    if (boundary.data) return boundary;
    else return;
};

export default useMapBoundary;
