import FriendlyError from 'core/FriendlyError';
import { encodeEmail, foldersPath, folderUsersPath } from 'database/Config';
import { IUserDoc, UserRole } from 'database/users';
import { FolderActionError } from '.';
import { IFolder, IFolderDoc } from '../Folder';
import firebase from 'firebase';
import uuid from 'short-uuid';

export const createFolder = async (
    database: firebase.database.Database,
    authUser: { uid: string; email: string | null; name: string },
    newFolder: IFolder
): Promise<IFolderDoc> => {
    try {
        const folderID = (await database.ref(foldersPath()).push()).key;

        if (!folderID) throw new Error('Unable to create folder new folderID invalid');
        if (!authUser.email) throw new Error('Unable to create folder user email is required');

        const folderDoc: IFolderDoc = {
            ...newFolder,
            owner: authUser.uid,
            id: folderID,
        };

        const newUser: IUserDoc = {
            id: uuid().generate(),
            role: UserRole.OWNER,
            name: authUser.name || 'Owner',
            email: encodeEmail(authUser.email),
        };

        const updates = {
            [foldersPath({ folderID })]: folderDoc,
            [folderUsersPath({ folderID, email: authUser.email })]: newUser,
        };

        await database.ref().update(updates);

        return folderDoc;
    } catch (error) {
        throw new FriendlyError(FolderActionError.CREATE_FAILED, null, error);
    }
};

export default createFolder;
