import React, { ChangeEvent } from 'react';
import PageMeta from '../../../../components/page/PageMeta';
import { Box, createStyles, Fab, InputAdornment, makeStyles, TextField, Theme } from '@material-ui/core';
import { useParams, Route, Routes, useNavigate } from 'react-router-dom';
import MapRoute from './map/Map';
import NewMapRoute from './views/NewMap';
import MapsBreadcrumbs from './components/MapsBreadcrumbs';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import MapsList from './components/MapsList';
import useFolder from '../hooks/useFolder';
import HiddenForRoles from '../users/components/HiddenForRole';
import { IMap, IMapComparatorParams, UserRole } from 'database';
import useUser from '../users/hooks/useUser';
import { useDebounce } from '@react-hook/debounce';
import MapTable from './components/MapTable';
import MapsToolbar from './components/MapsToolbar';
import useLocalStorage from 'hooks/useLocalStorage';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        extendedIcon: {
            marginRight: theme.spacing(1),
        },
        searchContainer: {
            [theme.breakpoints.up('xs')]: {
                marginRight: theme.spacing(3),
            },
        },
        searchInput: {
            maxWidth: theme.breakpoints.width('sm'),
        },
    })
);

export type MapsViewMode = 'grid' | 'table';

const Maps: React.FunctionComponent = () => {
    let { folderID } = useParams();
    const folder = useFolder(folderID);
    const user = useUser(folderID);
    const [viewMode, setViewMode] = useLocalStorage<MapsViewMode>('mapsListViewMode', 'grid');

    const classes = useStyles();
    const [comparator, setComparator] = useDebounce<IMapComparatorParams>({ searchTerm: '', sort: { field: 'reference' } }, 250);

    const navigate = useNavigate();

    const newMap = () => {
        navigate('new');
    };

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setComparator({ ...comparator, searchTerm: event.target.value });
    };

    const handleSortChanged = (field: keyof IMap, reverse?: boolean) => {
        setComparator({ ...comparator, sort: { field, reverse } });
    };

    return (
        <Box pl={3} pr={3} flex={1}>
            <PageMeta title={`${folder.name} | Maps`} />

            <Box display="flex" flexDirection="column" height="100%">
                <Box mt={3} mb={2}>
                    <MapsBreadcrumbs folderID={folderID}></MapsBreadcrumbs>
                </Box>

                <Box display="flex" flexWrap="wrap-reverse" alignItems="center">
                    <Box className={classes.searchContainer} mb={3} flexGrow={1}>
                        <TextField
                            className={classes.searchInput}
                            fullWidth={true}
                            label="Search Maps"
                            type="search"
                            variant="outlined"
                            onChange={handleSearch}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>

                    <HiddenForRoles user={user} roles={[UserRole.ASSIGNEE, UserRole.OVERSEER]}>
                        <Box mb={3}>
                            <Fab onClick={newMap} color="primary" variant="extended">
                                <AddIcon className={classes.extendedIcon} />
                                New Map
                            </Fab>
                        </Box>
                    </HiddenForRoles>
                </Box>

                <Box mb={2}>
                    <MapsToolbar
                        viewMode={viewMode}
                        onSortChanged={handleSortChanged}
                        onViewModeChanged={(mode) => setViewMode(mode)}
                        folderID={folderID}
                    />
                </Box>

                {viewMode === 'table' && <MapTable comparator={comparator} folderID={folderID} />}

                {viewMode === 'grid' && <MapsList comparator={comparator} folderID={folderID} />}
            </Box>
        </Box>
    );
};

export const MapsRoute: React.FunctionComponent = () => {
    return (
        <Routes>
            <Route path="/" element={<Maps />}></Route>
            <Route path="new" element={<NewMapRoute />}></Route>
            <Route path=":mapID/*" element={<MapRoute />}></Route>
        </Routes>
    );
};

export default MapsRoute;
