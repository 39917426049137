import firebase from 'firebase';
import { mapBoundaryPath } from 'database/Config';

export const deleteBoundary = async (database: firebase.database.Database, folderID: string, mapID: string) => {
    try {
        await database.ref(mapBoundaryPath({ mapID, folderID })).set(null);
    } catch (error) {
        console.error(error);
        throw new Error('Unable to delete map boundary');
    }
};
