import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useDatabase } from 'reactfire';
import { IUser, deleteUser, updateUser, IUserDoc } from 'database';

type UseUserActions = {
    running: boolean;
    deleteUser: (user: IUserDoc) => Promise<void>;
    updateUser: (fields: Partial<IUser>, user: IUserDoc) => Promise<void>;
};

export const useUserActions = (folderID: string): UseUserActions => {
    const [running, setRunning] = useState(false);
    const database = useDatabase();
    const { enqueueSnackbar } = useSnackbar();

    const handleDelete = async (user: IUserDoc) => {
        setRunning(true);
        await deleteUser(database, folderID, user)
            .then(() => enqueueSnackbar(`${user.name} Deleted`, { variant: 'info' }))
            .catch((error) => enqueueSnackbar(error.message, { variant: 'error' }))
            .finally(() => setRunning(false));
    };

    const handleUpdate = async (fields: Partial<IUser>, user: IUserDoc) => {
        setRunning(true);
        await updateUser(database, folderID, user, fields)
            .then(() => enqueueSnackbar(`${user.name} Updated`, { variant: 'success' }))
            .catch((error) => enqueueSnackbar(error.message, { variant: 'error' }))
            .finally(() => setRunning(false));
    };

    return {
        deleteUser: handleDelete,
        updateUser: handleUpdate,
        running,
    };
};

export default useUserActions;
