import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useDatabase } from 'reactfire';
import { updateMap, IMapDoc, IMapDetails } from 'database';

type UseUpdateMap = {
    running: boolean;
    updateMap: (existingMap: IMapDoc, mapFields: Partial<IMapDoc>, mapDetails: Partial<IMapDetails>) => Promise<IMapDoc | void>;
};

export const useUpdateMap = (folderID: string): UseUpdateMap => {
    const database = useDatabase();
    const [running, setRunning] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const update = async (existingMap: IMapDoc, mapFields: Partial<IMapDoc>, mapDetails: Partial<IMapDetails>) => {
        setRunning(true);

        return await updateMap(database, folderID, existingMap, mapFields, mapDetails)
            .then((map) => {
                enqueueSnackbar(`Map ${map.name} Updated`, { variant: 'success' });
                return map;
            })
            .catch((error) => {
                console.error(error);
                enqueueSnackbar('Unable to update map', { variant: 'error' });
            })
            .finally(() => setRunning(false));
    };

    return {
        updateMap: update,
        running,
    };
};

export default useUpdateMap;
