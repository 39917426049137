import { IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core';
import { DeleteForever } from '@material-ui/icons';

import TextFieldDialog, { TextFieldDialogOptions } from 'components/dialogs/TextFieldDialog';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useDatabase } from 'reactfire';
import useFolder from '../../hooks/useFolder';
import { useSnackbar } from 'notistack';
import { deleteAllUsers } from 'database';
import useUser from '../../users/hooks/useUser';

const ConfirmDeleteDialogOptions: TextFieldDialogOptions = {
    initialValue: '',
    label: 'Enter Folder Name',
    title: 'Confirm',
    confirmLabel: 'Delete Users',
    description: `Enter the folder name to confirm.
    This action can not be undone.`,
};

export const UserSettings: React.FunctionComponent = () => {
    const [showConfirm, setShowConfirm] = React.useState(false);
    const { folderID } = useParams();
    const database = useDatabase();
    const { enqueueSnackbar } = useSnackbar();
    const owner = useUser(folderID);
    const folder = useFolder(folderID);

    const deleteAll = async () => {
        await deleteAllUsers(database, folderID, owner)
            .catch((error) => {
                enqueueSnackbar(error, { variant: 'error' });
            })
            .then(() => enqueueSnackbar('All users deleted', { variant: 'info' }));
        setShowConfirm(false);
    };

    return (
        <React.Fragment>
            <Typography variant="subtitle2">Users</Typography>

            <List dense={true}>
                <ListItem button onClick={() => setShowConfirm(true)}>
                    <ListItemText primary="Delete Users" secondary={`Delete all users in the ${folder.name} folder`} />
                    <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="Delete All Users">
                            <DeleteForever />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            </List>

            <TextFieldDialog
                onSubmit={deleteAll}
                open={showConfirm}
                onClose={() => setShowConfirm(false)}
                options={ConfirmDeleteDialogOptions}
                textToMatch={folder.name}
                matchErrorMsg={`Must match folder name ${folder.name}`}
            ></TextFieldDialog>
        </React.Fragment>
    );
};

export default UserSettings;
