import { AppBar, Box, CircularProgress, CssBaseline, Drawer, Hidden, IconButton, List, Theme, Toolbar, Typography } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import FoldersIcon from '@material-ui/icons/Folder';
import MenuIcon from '@material-ui/icons/MenuOutlined';
import clsx from 'clsx';
import useDeviceType from 'hooks/useDeviceType';
import React, { Suspense } from 'react';
import ListItemLink from '../components/lists/ListItemLink';
import Config from '../Config';
import FolderNav from '../views/folders/folder/components/FolderNav';
import ProfileMenu from '../views/profile/ProfileMenu';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            height: '100%',
        },
        drawer: {
            [theme.breakpoints.up('md')]: {
                width: drawerWidth,
                flexShrink: 0,
            },
        },
        appBar: {
            [theme.breakpoints.up('md')]: {
                transition: theme.transitions.create(['margin', 'width'], {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
            },
        },
        appBarShift: {
            [theme.breakpoints.up('md')]: {
                width: `calc(100% - ${drawerWidth}px)`,
                marginLeft: drawerWidth,
                transition: theme.transitions.create(['margin', 'width'], {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
            },
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        drawerPaper: {
            width: drawerWidth,
            border: 'none',
        },
        main: {
            paddingTop: 64,
            [theme.breakpoints.down('sm')]: {
                paddingTop: 56,
            },
            height: '100%',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            flexWrap: 'wrap',
            [theme.breakpoints.up('md')]: {
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                marginLeft: -drawerWidth,
            },
        },
        contentShift: {
            [theme.breakpoints.up('md')]: {
                transition: theme.transitions.create('margin', {
                    easing: theme.transitions.easing.easeOut,
                    duration: theme.transitions.duration.enteringScreen,
                }),
                marginLeft: 0,
            },
        },
        title: {
            flexGrow: 1,
        },
        loader: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            margin: 'auto',
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            justifyContent: 'flex-end',
        },

        version: {
            width: '100%',
            textAlign: 'center',
            position: 'absolute',
            bottom: theme.spacing(1),
        },
    })
);

interface Props {}

export const MainLayout: React.FunctionComponent<Props> = ({ children }) => {
    const classes = useStyles();

    const { isMobile } = useDeviceType();

    const [mobileOpen, setMobileOpen] = React.useState(isMobile);
    const [desktopOpen, setDesktopOpen] = React.useState(!isMobile);

    const container = window !== undefined ? () => window.document.body : undefined;

    const toggleDrawerMobile = () => {
        if (isMobile) setMobileOpen(!mobileOpen);
    };

    const toggleDrawerDesktop = () => {
        if (!isMobile) setDesktopOpen(!desktopOpen);
    };

    const MenuItems = (
        <div onClick={toggleDrawerMobile}>
            <div className={classes.toolbar} />

            <List>
                <ListItemLink primary="All Folders" icon={<FoldersIcon />} to="/folders" />
                <FolderNav />
            </List>

            <Box className={classes.version}>
                <Typography variant="overline" color="textSecondary">
                    {Config.VERSION}
                </Typography>
            </Box>
        </div>
    );

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: desktopOpen,
                })}
                color="primary"
                elevation={0}
                position="fixed"
            >
                <Toolbar>
                    <Hidden mdUp>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawerMobile}
                            edge="start"
                            className={clsx(classes.menuButton)}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Hidden>

                    <Hidden smDown>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawerDesktop}
                            edge="start"
                            className={clsx(classes.menuButton)}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Hidden>

                    <Typography className={classes.title} variant="h6" noWrap>
                        {Config.APP_NAME}
                    </Typography>
                    <ProfileMenu />
                </Toolbar>
            </AppBar>

            <nav className={classes.drawer} aria-label="side navigation">
                {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                <Hidden mdUp implementation="css">
                    <Drawer
                        container={container}
                        variant="temporary"
                        open={mobileOpen}
                        onClose={toggleDrawerMobile}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                        ModalProps={{
                            keepMounted: true, // Better open performance on mobile.
                        }}
                    >
                        {MenuItems}
                    </Drawer>
                </Hidden>
                <Hidden smDown implementation="css">
                    <Drawer
                        className={classes.drawer}
                        variant="persistent"
                        anchor="left"
                        open={desktopOpen}
                        classes={{
                            paper: classes.drawerPaper,
                        }}
                    >
                        {MenuItems}
                    </Drawer>
                </Hidden>
            </nav>
            <main
                className={clsx(classes.main, {
                    [classes.contentShift]: desktopOpen,
                })}
            >
                <Suspense fallback={<CircularProgress className={classes.loader} />}>{children}</Suspense>
            </main>
        </div>
    );
};

export default MainLayout;
