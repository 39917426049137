import FriendlyError from 'core/FriendlyError';
import { folderUsersPath, sharedMapsPath } from 'database/Config';
import { UserActionError } from '..';
import firebase from 'firebase';
import { IUserDoc } from '../User';

export const deleteUser = async (database: firebase.database.Database, folderID: string, { id, email }: IUserDoc): Promise<void> => {
    try {
        if (!id || !folderID) throw new Error('Unable to delete user invalid params');

        const updates: Record<string, null> = {
            [sharedMapsPath({ folderID, userID: email || id })]: null,
            [folderUsersPath({ folderID, email: email || id })]: null,
        };

        await database.ref().update(updates);
    } catch (error) {
        throw new FriendlyError(UserActionError.DELETE_FAILED, null, error);
    }
};

export default deleteUser;
