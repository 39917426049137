import { createMuiTheme, ThemeOptions } from '@material-ui/core';
import dark from './Dark';
import light from './Light';

export type ThemeName = 'light' | 'dark';

const themes: { [key in ThemeName]: ThemeOptions } = {
    dark,
    light,
};

export const getTheme = (name: ThemeName) => {
    return createMuiTheme(themes[name]);
};
