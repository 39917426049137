import { Button } from '@material-ui/core';
import { IMapDoc, MapAction } from 'database';
import React from 'react';

type Props = {
    map: IMapDoc;
    handleMapAction: (action: MapAction, map: IMapDoc) => void;
};

export const MapActionButton: React.FunctionComponent<Props> = ({ map, handleMapAction }) => {
    const action = map.returned ? MapAction.ASSIGN : MapAction.RETURN;

    return (
        <Button
            variant="contained"
            color={map.returned ? 'primary' : 'secondary'}
            onClick={(event) => {
                event.stopPropagation();
                event.preventDefault();
                handleMapAction(action, map);
            }}
        >
            {action}
        </Button>
    );
};

export default MapActionButton;
