import FriendlyError from 'core/FriendlyError';
import { folderInvitesPath, sharedFoldersPath } from 'database/Config';
import firebase from 'firebase';
import { InviteActionError } from '..';
import { IInviteDoc } from '../Invite';

export const acceptInvite = async (database: firebase.database.Database, folderID: string, invite: IInviteDoc): Promise<void> => {
    try {
        const updates = {
            [sharedFoldersPath({ folderID, email: invite.toEmail })]: true,
            [folderInvitesPath({ folderID, inviteID: invite.id })]: null,
        };

        await database.ref().update(updates);
    } catch (error) {
        throw new FriendlyError(InviteActionError.ACCEPT_INVITE, null, error);
    }
};

export default acceptInvite;
