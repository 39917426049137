import FriendlyError from 'core/FriendlyError';
import { folderUsersPath } from 'database/Config';
import firebase from 'firebase';
import { IUserDoc } from '../User';

type FetchUsersResult = {
    error?: FriendlyError;
    users?: Record<string, IUserDoc>;
};

const fetchError = 'Unable to fetch users';

export const usersByID = (users: Record<string, IUserDoc>): Record<string, IUserDoc> => {
    const usersByID: Record<string, IUserDoc> = {};
    const emails = Object.keys(users);
    for (const email of emails) {
        const user = users[email];
        usersByID[user.id] = user;
    }

    return usersByID;
};

export const fetchUsers = async (
    database: firebase.database.Database,
    folderID: string,
    primaryKey: 'email' | 'id' = 'id'
): Promise<FetchUsersResult> => {
    try {
        const ref = database.ref(folderUsersPath({ folderID })).orderByChild('name');
        const usersRef = await ref.once('value');

        // User emails to user docs
        let users = usersRef.val() as Record<string, IUserDoc>;

        for (const email of Object.keys(users)) {
            const user = users[email];
            //Set email to null if the email is the same as id hack to allow us to add users without emails but keep them in the system
            if (user.id === email) user.email = '';
        }

        if (primaryKey === 'id') {
            users = usersByID(users);
        }

        return { users };
    } catch (error) {
        return { error: new FriendlyError(fetchError, null, error) };
    }
};

export default fetchUsers;
