import FriendlyError from 'core/FriendlyError';
import { MapCSVRow, MAP_TAG_COLOR_SEPERATOR, MAP_USER_SEPERATOR, SupportedDateFormats } from 'core/MapperCSV';
import { fetchMapDetails, fetchMaps, IMapDetails, IMapDoc } from 'database/maps';
import firebase from 'firebase';
import Papa from 'papaparse';
import moment from 'moment';
import { IUser, fetchUsers } from 'database';
import { ITagDoc } from 'database/tags';
import fetchTags from 'database/tags/actions/fetchTags';
import { decodeEmail } from 'database/Config';

type ExportResult = {
    error?: FriendlyError;
    csv?: string;
};

const mapFields: Array<keyof MapCSVRow> = [
    'Reference',
    'Color',
    'Map Name',
    'Overseers',
    'Assignees',
    'Tags',
    'Date Assigned',
    'Date Returned',
    'Notes',
];

const mapToCSVRow = (
    map: IMapDoc,
    mapDetails: IMapDetails | null,
    folderUsers: Record<string, IUser>,
    folderTags: Record<string, ITagDoc>
): MapCSVRow => {
    const overseers = Object.keys(map.overseers || {})
        .map((id) => mapperUserToCSVUser(folderUsers[id]))
        .sort();
    const assignees = Object.keys(map.assignees || {})
        .map((id) => mapperUserToCSVUser(folderUsers[id]))
        .sort();
    const tags = Object.keys(map.tags || {}).map((id) => `${folderTags[id].name}${MAP_TAG_COLOR_SEPERATOR}${folderTags[id].color}`);

    return {
        Reference: map.reference || '',
        Color: map.color || '',
        'Map Name': map.name,
        'Date Assigned': moment(map.dateAssigned).format('DD/MM/YYYY') as SupportedDateFormats,
        'Date Returned': map.dateReturned ? (moment(map.dateReturned).format('DD/MM/YYYY') as SupportedDateFormats) : null,
        Overseers: overseers.join(', ').trim(),
        Assignees: assignees.join(', ').trim(),
        Tags: tags.join(', ').trim(),
        Notes: mapDetails?.notes || '',
    };
};

const mapperUserToCSVUser = (user: IUser): string => {
    if (!user.email) return user.name;

    return `${user.name}${MAP_USER_SEPERATOR}${decodeEmail(user.email)}`;
};

export const exportMaps = async (database: firebase.database.Database, folderID: string): Promise<ExportResult> => {
    const { maps } = await fetchMaps(database, folderID);
    const { mapDetails } = await fetchMapDetails(database, folderID);

    const { users } = await fetchUsers(database, folderID);
    const { tags } = await fetchTags(database, folderID);

    const error = new FriendlyError('Unable to export maps');

    if (maps && users && tags) {
        try {
            const rows = maps.map((map) => mapToCSVRow(map, mapDetails ? mapDetails[map.id] : null, users, tags));
            const csv = Papa.unparse({ fields: mapFields, data: JSON.stringify(rows) });

            return { csv };
        } catch (error) {
            console.error('Unable to unparse maps into csv', error);
        }
    }
    return { error };
};

export default exportMaps;
