import React, { useState } from 'react';
import { Link as NavLink } from 'react-router-dom';
import { Card, CardHeader, CardActionArea, Avatar, IconButton } from '@material-ui/core';
import FolderIcon from '@material-ui/icons/FolderOpen';
import MoreIcon from '@material-ui/icons/MoreVert';
import { FolderAction, IFolderDoc } from 'database';
import FolderMenu from './FolderMenu';

interface FolderCardProps {
    folder: IFolderDoc;
    className?: string;
    onFolderAction: (action: FolderAction, folder: IFolderDoc) => void;
    showMenu?: boolean;
}

export const FolderCard: React.FunctionComponent<FolderCardProps> = ({ folder, className, onFolderAction, showMenu = true }) => {
    const { name, id } = folder;
    const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null);

    const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const closeMenu = () => {
        setAnchorEl(null);
    };

    return (
        <Card className={className}>
            <CardActionArea to={`${id}`} component={NavLink}>
                <CardHeader
                    avatar={
                        <Avatar>
                            <FolderIcon />
                        </Avatar>
                    }
                    action={
                        showMenu ? (
                            <React.Fragment>
                                <IconButton
                                    aria-controls="user-menu"
                                    aria-haspopup="true"
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        event.preventDefault();
                                        openMenu(event);
                                    }}
                                >
                                    <MoreIcon />
                                </IconButton>

                                <FolderMenu onClose={closeMenu} folder={folder} anchorEl={anchorEl} onFolderAction={onFolderAction} />
                            </React.Fragment>
                        ) : null
                    }
                    title={name}
                />
            </CardActionArea>
        </Card>
    );
};

export default FolderCard;
