import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React from 'react';
import { DropzoneArea } from 'material-ui-dropzone';
import { createStyles, makeStyles } from '@material-ui/core/styles';

type Props = {
    open: boolean;
    allowed?: Array<string>;
    onClose: () => void;
    onSubmit: (file: Array<File>) => void;
};

const useStyles = makeStyles((theme) =>
    createStyles({
        previewChip: {
            minWidth: 160,
            maxWidth: 210,
        },
    })
);

export const FileDialog: React.FunctionComponent<Props> = ({ onClose, open, onSubmit, allowed = [] }) => {
    const [files, setFiles] = React.useState<File[]>([]);
    const classes = useStyles();

    const handleSubmit = () => {
        onSubmit(files);
    };

    return (
        <Dialog disableBackdropClick fullWidth={true} maxWidth={'xs'} open={open} onClose={() => onClose()} aria-labelledby="form-dialog-title">
            <DialogTitle>Import Maps</DialogTitle>
            <DialogContent>
                <Box mb={2}>
                    <DropzoneArea
                        onChange={(loadedFiles) => setFiles(loadedFiles)}
                        showPreviews={true}
                        showPreviewsInDropzone={false}
                        useChipsForPreview
                        previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                        previewChipProps={{ classes: { root: classes.previewChip } }}
                        previewText="Selected File"
                        acceptedFiles={allowed}
                        filesLimit={1}
                        showAlerts={false}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => onClose()} variant="outlined">
                    Cancel
                </Button>
                <Button type="submit" onClick={handleSubmit} variant="contained" color="primary">
                    Import
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default FileDialog;
