import React from 'react';
import { useParams } from 'react-router-dom';
import EditMapForm from '../../components/forms/EditMapForm';

interface Props {}

export const EditMapRoute: React.FunctionComponent<Props> = () => {
    let { folderID, mapID } = useParams();

    return <EditMapForm folderID={folderID} mapID={mapID} />;
};

export default EditMapRoute;
