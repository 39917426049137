import { useUser as useAuthUser, useDatabase, useDatabaseObjectData } from 'reactfire';
import { folderUsersPath, IUserDoc } from 'database';
import firebase from 'firebase';

export default function useUser(folderID: string): IUserDoc {
    const { data: firebaseUser } = useAuthUser<firebase.User>();
    let email = firebaseUser.email as string;
    if (!email) console.error('Use user was called but the current user has no email!!');
    const ref = useDatabase().ref(folderUsersPath({ folderID, email }));
    const { data: user } = useDatabaseObjectData<IUserDoc>(ref, { idField: 'id' });

    return user;
}
