import { IDoc } from 'database';

export interface ITag {
    name: string;
    color: string;
}

export interface ITagDoc extends ITag, IDoc {}

export enum TagActionError {
    CREATE_FAILED = 'Unable to create Tag',
    DELETE_FAILED = 'Unable to delete Tag',
    UPDATE_FAILED = 'Unable to update Tag',
}
