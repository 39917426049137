import CustomError from './CustomError';

export default class FriendlyError extends CustomError {
    public static UNEXPECTED = 'Unexpected error occurred';

    public static PERMISSION_DENIED = 'Permission Denied';

    private static parseMessage(msg: string, params: { [key: string]: string }): string {
        const keys = Object.keys(params);
        for (const key of keys) {
            msg = msg.replace(new RegExp(`{{${key}}}`, 'g'), params[key]);
        }
        return msg;
    }

    constructor(friendlyMsg: string, params?: { [key: string]: string } | null, error?: any) {
        if (error && error.code === 'PERMISSION_DENIED') super(FriendlyError.PERMISSION_DENIED);
        else {
            super(params ? FriendlyError.parseMessage(friendlyMsg, params) : friendlyMsg);
        }
    }
}
