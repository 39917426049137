import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@material-ui/core';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import * as yup from 'yup';

export type TextFieldDialogOptions = {
    initialValue: string;
    label: string;
    confirmLabel: string;
    cancelLabel?: string;
    title: string;
    description?: string;
};

type Props = {
    open: boolean;
    onSubmit: (text: string) => Promise<void>;
    onClose: () => void;
    options: TextFieldDialogOptions;
    textToMatch?: string;
    matchErrorMsg?: string;
};

export const TextFieldDialog: React.FunctionComponent<Props> = ({ onSubmit, open, onClose, options, textToMatch, matchErrorMsg }) => {
    const submit = ({ text }: { text: string }, { setSubmitting, resetForm }: FormikHelpers<{ text: string }>) => {
        setSubmitting(true);
        onSubmit(text).finally(() => {
            resetForm();
            onClose();
        });
    };

    const schema = yup.object({
        text: textToMatch ? yup.string().equals([textToMatch], matchErrorMsg) : yup.string().required('Required'),
    });

    return (
        <Formik isInitialValid={false} validationSchema={schema} initialValues={{ text: options.initialValue }} onSubmit={submit}>
            {({ resetForm, isSubmitting, submitForm, isValid, isInitialValid }) => (
                <Dialog
                    fullWidth={true}
                    maxWidth={'xs'}
                    open={open}
                    onClose={() => {
                        onClose();
                        resetForm();
                    }}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle>{options.title}</DialogTitle>
                    <DialogContent>
                        {!!options.description && <DialogContentText id="text-field-dialog-description">{options.description}</DialogContentText>}

                        <Form noValidate autoComplete="off">
                            <Field
                                required
                                disabled={isSubmitting}
                                fullWidth
                                component={TextField}
                                variant="outlined"
                                name="text"
                                type="text"
                                label={options.label}
                            />
                        </Form>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                onClose();
                                resetForm();
                            }}
                            color="primary"
                        >
                            {options.cancelLabel || 'Cancel'}
                        </Button>
                        <Button disabled={!isInitialValid && !isValid} type="submit" onClick={submitForm} variant="contained" color="primary">
                            {options.confirmLabel}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Formik>
    );
};

export default TextFieldDialog;
