import { Box, createStyles, makeStyles } from '@material-ui/core';
import { IMapBoundary } from 'database/maps/MapBoundary';
import { LatLngTuple, featureGroup, geoJSON } from 'leaflet';
import React, { useEffect, useState } from 'react';
import { MapContainer, TileLayer, GeoJSON, useMap } from 'react-leaflet';
import { useResizeDetector } from 'react-resize-detector';

const useStyles = makeStyles(() =>
    createStyles({
        root: {
            height: '100%',
            width: '100%',
            overflow: 'hidden',
            flex: 1,
        },
        mapContainer: {
            width: '100%',
            height: '100%',
        },
    })
);

type MapProps = {
    center?: LatLngTuple;
    zoom?: number;
    scrollWheelZoom?: boolean;
    dragging?: boolean;
    boundaries?: Array<IMapBoundary>;
};

const defaultPosition: LatLngTuple = [51.906852, -2.499222];

const Resizer = () => {
    const map = useMap();
    const { width, height, ref } = useResizeDetector();

    useEffect(() => {
        map.invalidateSize();
    }, [width, height, map]);

    return <div ref={ref}></div>;
};

export const Map: React.FunctionComponent<MapProps> = ({ center = defaultPosition, boundaries }) => {
    const classes = useStyles();
    const group = boundaries?.length ? featureGroup(boundaries.map((b) => geoJSON(b.data))) : null;
    const [showMap, setShowMap] = useState(false);

    // Hack to fix Weird timing issue on showing the map on some pages
    useEffect(() => {
        requestAnimationFrame(() => {
            setShowMap(true);
        });
    });

    return (
        <Box className={classes.root}>
            {showMap && (
                <MapContainer
                    bounds={group ? group.getBounds() : undefined}
                    className={classes.mapContainer}
                    zoom={group ? undefined : 12}
                    center={center}
                    maxZoom={16}
                >
                    <TileLayer
                        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                        url="https://api.os.uk/maps/raster/v1/zxy/Road_3857/{z}/{x}/{y}.png?key=HRgzG89MI1P0aX8ZGiHcbykZG1OEksnB"
                    />
                    {boundaries &&
                        boundaries.map((boundary, index) => {
                            return <GeoJSON style={{ color: boundary.color }} key={index} data={boundary.data} />;
                        })}
                    <Resizer />
                </MapContainer>
            )}
        </Box>
    );
};

export default Map;
