import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Typography } from '@material-ui/core';
import { Form, Formik, Field } from 'formik';
import React from 'react';
import { TextField } from 'formik-material-ui';
import { SupportedDateFormats } from 'core/MapperCSV';
import useExportMaps from '../hooks/useExportMaps';

type MapsExportOptions = {
    dateFormat: SupportedDateFormats;
};

type Props = {
    folderID: string;
    open: boolean;
    onClose: () => void;
};

const DATE_FORMAT_OPTIONS = ['DD-MM-YYYY', 'MM-DD-YYYY', 'YYYY-MM-DD'];

export const MapsExportDialog: React.FunctionComponent<Props> = ({ onClose, open, folderID }) => {
    const { exportMaps, running } = useExportMaps(folderID);

    const initialValues: MapsExportOptions = {
        dateFormat: 'DD-MM-YYYY',
    };
    const mapsExport = async () => {
        exportMaps().then(() => {
            onClose();
        });
    };
    return (
        <Formik initialValues={initialValues} onSubmit={mapsExport}>
            {({ submitForm, isSubmitting }) => (
                <Dialog
                    disableBackdropClick
                    fullWidth={true}
                    maxWidth={'xs'}
                    open={open}
                    onClose={() => onClose()}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle>Export Maps</DialogTitle>
                    <DialogContent>
                        <Form autoComplete="off">
                            <Typography>Export Options</Typography>
                            <Box pt={2} mb={2}>
                                <Field
                                    required
                                    fullWidth
                                    component={TextField}
                                    type="text"
                                    name="dateFormat"
                                    label="Date Format"
                                    select
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                >
                                    {DATE_FORMAT_OPTIONS.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Field>
                            </Box>
                        </Form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => onClose()} variant="outlined">
                            Cancel
                        </Button>
                        <Button type="submit" disabled={isSubmitting || running} onClick={submitForm} variant="contained" color="primary">
                            Export {running && <CircularProgress size={24} />}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Formik>
    );
};

export default MapsExportDialog;
