import { IMap, IMapDoc } from './Map';
import moment from 'moment';

export class MapUtils {
    static getRelevantDate(map: IMap, format = 'LL') {
        const mapDate = map.returned ? map.dateReturned : map.dateAssigned;
        return format ? moment(mapDate || '').format(format) : mapDate;
    }

    static filterOverdue(maps: IMap[]): Array<IMap> {
        const cutOffDate = moment().subtract(6, 'M');
        return maps.filter((map) => !map.returned && moment(map.dateAssigned).isBefore(cutOffDate));
    }

    static filterReturned(maps: IMap[]): Array<IMap> {
        return maps.filter((map) => map.returned);
    }

    static filterAssigned(maps: IMap[]): Array<IMap> {
        return maps.filter((map) => Object.keys(map.assignees || {}).length);
    }

    /**
     * Returns that days since map was assigned or returned
     * @param map
     */
    static getMapAgeFromNow(map: IMapDoc): { label: string; days: number } {
        const mapDate = moment(map.returned ? map.dateReturned : map.dateAssigned);
        const days = moment().diff(mapDate, 'd');
        return { label: mapDate.fromNow(), days };
    }
}
