import React from 'react';
import { Avatar, Box, ButtonBase, createStyles, makeStyles, Paper, SvgIconTypeMap, Theme, Typography } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { NavLink } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: '100%',
            height: '100%',
        },
        padding: {
            [theme.breakpoints.down('sm')]: {
                padding: theme.spacing(2),
            },
            padding: theme.spacing(3),
        },
        indicator: {
            width: theme.spacing(6),
            height: theme.spacing(6),
        },
    })
);

type FolderStatTileProps = {
    icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
    amount: number;
    label: string;
    color: string;
    link: string;
};

export const FolderStatTile: React.FunctionComponent<FolderStatTileProps> = ({ icon: Icon, amount, label, color, link }) => {
    const classes = useStyles();

    return (
        <ButtonBase className={classes.root} to={link} component={NavLink}>
            <Paper className={classes.root}>
                <Box className={classes.padding} display="flex">
                    <Box>
                        <Avatar className={classes.indicator} style={{ backgroundColor: color }}>
                            <Icon fontSize="large" />
                        </Avatar>
                    </Box>
                    <Box ml={3}>
                        <Typography variant="h4">
                            <b>{amount}</b>
                        </Typography>
                        <Typography color="textSecondary" variant="subtitle1">
                            {label}
                        </Typography>
                    </Box>
                </Box>
            </Paper>
        </ButtonBase>
    );
};

export default FolderStatTile;
