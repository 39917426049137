import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, Typography } from '@material-ui/core';
import { Form, Formik, Field } from 'formik';
import React from 'react';
import useImportMaps from '../hooks/useImportMaps';
import { TextField } from 'formik-material-ui';
import { DropzoneArea } from 'material-ui-dropzone';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { SupportedDateFormats } from 'core/MapperCSV';

type MapsImportOptions = {
    dateFormat: SupportedDateFormats;
};

type Props = {
    folderID: string;
    open: boolean;
    onClose: () => void;
};

const DATE_FORMAT_OPTIONS = ['DD-MM-YYYY', 'MM-DD-YYYY', 'YYYY-MM-DD'];

const useStyles = makeStyles((theme) =>
    createStyles({
        previewChip: {
            minWidth: 160,
            maxWidth: 210,
        },
    })
);

export const MapsImportDialog: React.FunctionComponent<Props> = ({ onClose, open, folderID }) => {
    const { importMaps, running } = useImportMaps(folderID);
    const [files, setFiles] = React.useState<File[]>([]);
    const classes = useStyles();

    const initialValues: MapsImportOptions = {
        dateFormat: 'DD-MM-YYYY',
    };

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting }) => {
                if (files && files.length) {
                    importMaps(files[0], values.dateFormat).then(() => {
                        setSubmitting(false);
                        onClose();
                    });
                }
            }}
        >
            {({ submitForm, isSubmitting }) => (
                <Dialog
                    disableBackdropClick
                    fullWidth={true}
                    maxWidth={'xs'}
                    open={open}
                    onClose={() => onClose()}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle>Import Maps</DialogTitle>
                    <DialogContent>
                        <Form autoComplete="off">
                            <Box mb={2}>
                                <DropzoneArea
                                    onChange={(loadedFiles) => setFiles(loadedFiles)}
                                    showPreviews={true}
                                    showPreviewsInDropzone={false}
                                    useChipsForPreview
                                    previewGridProps={{ container: { spacing: 1, direction: 'row' } }}
                                    previewChipProps={{ classes: { root: classes.previewChip } }}
                                    previewText="Selected File"
                                    acceptedFiles={['.csv']}
                                    filesLimit={1}
                                    showAlerts={false}
                                />
                            </Box>
                            <Typography>Import Options</Typography>
                            <Box pt={2} mb={2}>
                                <Field
                                    required
                                    fullWidth
                                    component={TextField}
                                    type="text"
                                    name="dateFormat"
                                    label="Date Format"
                                    select
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                >
                                    {DATE_FORMAT_OPTIONS.map((option) => (
                                        <MenuItem key={option} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Field>
                            </Box>
                        </Form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => onClose()} variant="outlined">
                            Cancel
                        </Button>
                        <Button type="submit" disabled={isSubmitting} onClick={submitForm} variant="contained" color="primary">
                            Import {running && <CircularProgress size={24} />}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Formik>
    );
};

export default MapsImportDialog;
