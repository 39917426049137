import React, { Suspense, useEffect } from 'react';
import SignIn from './views/sign-in/SignIn';
import MainLayout from './layouts/MainLayout';
import Profile from './views/profile/Profile';
import FoldersRoute from './views/folders/Folders';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import CustomThemeProvider from './providers/CustomThemeProvider';
import NotFound from './NotFound';
import { BrowserRouter, Routes, Route, Navigate, useNavigate } from 'react-router-dom';
import { AuthCheck, FirebaseAppProvider, preloadAuth, preloadDatabase, preloadUser, useFirebaseApp } from 'reactfire';

import Config from 'Config';
import firebase from 'firebase';
import SplashScreen from 'components/splash/SplashScreen';
import InviteRoute from 'views/folders/folder/invites/InviteRoute';

const preloadSDKs = (firebaseApp: firebase.app.App) => {
    return Promise.all([preloadDatabase({ firebaseApp }), preloadAuth({ firebaseApp })]);
};

const preloadData = async (firebaseApp: firebase.app.App) => {
    await preloadUser({ firebaseApp });
};

const SignInFallback = () => {
    const navigate = useNavigate();

    useEffect(() => {
        navigate('/sign-in');
    });

    return null;
};

export const App = () => {
    return (
        <FirebaseAppProvider firebaseConfig={Config.firebaseConfig} suspense={true}>
            <Suspense fallback={<SplashScreen />}>
                <Main />
            </Suspense>
        </FirebaseAppProvider>
    );
};

const Main = () => {
    const firebaseApp = useFirebaseApp();
    preloadSDKs(firebaseApp).then(() => {
        Config.configureFirebase(firebaseApp);
        preloadData(firebaseApp);
    });

    return (
        <CustomThemeProvider>
            <MuiPickersUtilsProvider utils={MomentUtils}>
                <SnackbarProvider anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }} maxSnack={3}>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/sign-in">
                                <SignIn />
                            </Route>

                            <Route
                                path="invite/:folderID/:inviteID"
                                element={
                                    <AuthCheck fallback={<SignInFallback />}>
                                        <InviteRoute />
                                    </AuthCheck>
                                }
                            ></Route>

                            <Navigate to="folders" />
                            <Route
                                path="folders/*"
                                element={
                                    <AuthCheck fallback={<SignInFallback />}>
                                        <MainLayout>
                                            <FoldersRoute />
                                        </MainLayout>
                                    </AuthCheck>
                                }
                            ></Route>
                            <Route
                                path="profile"
                                element={
                                    <AuthCheck fallback={<SignInFallback />}>
                                        <MainLayout>
                                            <Profile />
                                        </MainLayout>
                                    </AuthCheck>
                                }
                            ></Route>

                            <Route path="*">
                                <NotFound />
                            </Route>
                        </Routes>
                    </BrowserRouter>
                </SnackbarProvider>
            </MuiPickersUtilsProvider>
        </CustomThemeProvider>
    );
};

export default App;
