import { IUser } from 'database/users';
import { IMap, IMapDoc } from './Map';

export interface IMapComparatorParams {
    searchTerm?: string;
    sort: {
        field: keyof IMap;
        reverse?: boolean;
    };
}

export class MapComparator {
    static includeMap = (map: IMap, { searchTerm }: IMapComparatorParams, users?: Record<string, IUser>): boolean => {
        if (searchTerm) {
            const term = searchTerm.toLowerCase();
            let include = map.name.toLowerCase().indexOf(term) > -1 || map.reference?.toLowerCase() === term;

            if (!include && users) {
                include = !!Object.keys(map.assignees || {}).find((id) => users[id].name.toLowerCase().indexOf(term) > -1);
            }

            return include;
        }

        return true;
    };

    static sortMaps = (maps: Array<IMapDoc>, field: keyof IMapDoc, reverse = false) => {
        return maps.sort((a, b) => {
            let aValue = reverse ? b[field] : (a[field] as unknown);
            let bValue = reverse ? a[field] : (b[field] as unknown);

            switch (field) {
                case 'name':
                    return (aValue as string).localeCompare(bValue as string);
                case 'reference':
                    return (aValue as string).localeCompare(bValue as string, undefined, { numeric: true });
                case 'dateAssigned':
                case 'dateReturned':
                    if (!aValue) return 1;
                    if (!bValue) return -1;

                    return (aValue as string).localeCompare(bValue as string);
                default:
                    return 0;
            }
        });
    };
}

export default MapComparator;
