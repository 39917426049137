import React, { useState } from 'react';
import { Box, Button, createStyles, Grid, makeStyles, Theme, Typography } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import { DatePicker, KeyboardDatePicker } from 'formik-material-ui-pickers';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import SelectUserField from '../../../users/components/SelectUserField';
import MapFormFields from './MapFormFields';
import { MapSchema } from 'database';
import useDeviceType from 'hooks/useDeviceType';
import Editor from 'components/editor/Editor';
import { EditorState } from 'draft-js';
import { withErrorBoundary } from 'react-error-boundary';
import SelectTagsField from '../../../tags/components/SelectTagsField';
import TagFormDialog, { TagFormFields } from 'views/folders/folder/tags/components/TagFormDialog';
import useCreateTag from 'views/folders/folder/tags/hooks/useCreateTag';
import ColorPicker from 'components/color/ColorPicker';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        selectedBtn: {
            backgroundColor: `${theme.palette.primary.main} !important`,
            color: `${theme.palette.primary.contrastText} !important`,
        },
    })
);

type MapFormProps = {
    initialValues?: MapFormFields;
    folderID: string;
    onSubmit: (fields: MapFormFields) => Promise<void>;
};

const MapFormComponent: React.FunctionComponent<MapFormProps> = ({ initialValues = new MapFormFields(), onSubmit, folderID }) => {
    const classes = useStyles();
    const { isDesktop } = useDeviceType();
    const [showTagDialog, setShowTagDialog] = useState(false);
    const { createTag } = useCreateTag(folderID);

    const saveTag = async (fields: TagFormFields) => {
        await createTag(fields.toTag());
    };

    const [editorState, setEditorState] = useState(() => {
        return initialValues.notes ? EditorState.createWithContent(initialValues.notes) : EditorState.createEmpty();
    });

    const saveMap = async (fields: MapFormFields) => {
        fields.notes = editorState.getCurrentContent();
        onSubmit(fields);
    };

    return (
        <Formik initialValues={initialValues} validationSchema={MapSchema} onSubmit={saveMap}>
            {({ isSubmitting, errors, touched, setFieldValue, values }) => (
                <Form noValidate autoComplete="off">
                    <Box mb={2} display="flex" justifyContent="flex-end" alignItems="center">
                        <Button size="large" disabled={isSubmitting} variant="contained" color="primary" type="submit">
                            Save Map
                        </Button>
                    </Box>
                    <Box mb={2}>
                        <Typography variant="h6">Details</Typography>
                    </Box>
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                            <Field
                                required
                                disabled={isSubmitting}
                                fullWidth
                                component={TextField}
                                variant="outlined"
                                name="name"
                                type="text"
                                label="Map Name"
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <Field
                                disabled={isSubmitting}
                                fullWidth
                                component={TextField}
                                variant="outlined"
                                name="reference"
                                type="text"
                                label="Reference"
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <ColorPicker
                                disabled={isSubmitting}
                                onChange={(color) => setFieldValue('color', color)}
                                label="Colour"
                                color={values.color || initialValues.color}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <SelectUserField
                                folderID={folderID}
                                label="Overseers"
                                name="overseers"
                                disabled={isSubmitting}
                                error={Array.isArray(errors['overseers']) ? '' : touched['overseers'] ? errors['overseers'] : ''}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Box mb={2}>
                                <Typography variant="h6">Assignment</Typography>
                            </Box>

                            <ToggleButtonGroup
                                onChange={(_event, value) => {
                                    const returned = value === 'yes';
                                    setFieldValue('returned', returned);
                                }}
                                size="large"
                                exclusive
                                value={values.returned ? 'yes' : 'no'}
                            >
                                <ToggleButton classes={{ selected: classes.selectedBtn }} value="yes" aria-label="map returned">
                                    Returned
                                </ToggleButton>
                                <ToggleButton classes={{ selected: classes.selectedBtn }} value="no" aria-label="map assigned">
                                    Assigned
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>

                        {values.returned && (
                            <Grid item xs={12} md={6}>
                                <Field
                                    disabled={isSubmitting}
                                    disableFuture={true}
                                    format={isDesktop ? 'DD/MM/yyyy' : 'LL'}
                                    component={isDesktop ? KeyboardDatePicker : DatePicker}
                                    variant={isDesktop ? 'inline' : 'dialog'}
                                    fullWidth
                                    inputVariant="outlined"
                                    name="dateReturned"
                                    label="Date Returned"
                                />
                            </Grid>
                        )}

                        {!values.returned && (
                            <React.Fragment>
                                <Grid item xs={12} sm={6}>
                                    <Field
                                        disabled={isSubmitting}
                                        format={isDesktop ? 'DD/MM/yyyy' : 'LL'}
                                        component={isDesktop ? KeyboardDatePicker : DatePicker}
                                        disableFuture={true}
                                        variant={isDesktop ? 'inline' : 'dialog'}
                                        fullWidth
                                        inputVariant="outlined"
                                        name="dateAssigned"
                                        label="Date Assigned"
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <SelectUserField
                                        required
                                        folderID={folderID}
                                        label="Assignees"
                                        name="assignees"
                                        disabled={isSubmitting}
                                        error={Array.isArray(errors['assignees']) ? '' : touched['assignees'] ? errors['assignees'] : ''}
                                    />
                                </Grid>
                            </React.Fragment>
                        )}

                        <Grid item xs={12}>
                            <Box mb={2}>
                                <Typography variant="h6">Tags</Typography>
                            </Box>
                            <Box display="flex" alignItems="center">
                                <Box flex={1} mr={2}>
                                    <SelectTagsField
                                        folderID={folderID}
                                        label="Tags"
                                        name="tags"
                                        disabled={isSubmitting}
                                        error={Array.isArray(errors['tags']) ? '' : touched['tags'] ? errors['tags'] : ''}
                                    />
                                </Box>

                                <Box>
                                    <Button variant="contained" color="primary" onClick={() => setShowTagDialog(true)}>
                                        New Tag
                                    </Button>
                                </Box>
                            </Box>

                            <TagFormDialog
                                options={{ title: 'New Tag', submitLabel: 'Save' }}
                                open={showTagDialog}
                                onClose={() => setShowTagDialog(false)}
                                onSubmit={(fields) => saveTag(fields)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Box mb={2}>
                                <Typography variant="h6">Notes</Typography>
                            </Box>
                            <Box pb={8}>
                                <Editor editorState={editorState} onChange={(newState) => setEditorState(newState)} />
                            </Box>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    );
};

const FallbackComponent: React.FunctionComponent = () => {
    return <Typography>Unable to load map form</Typography>;
};

export const MapForm = withErrorBoundary(MapFormComponent, { FallbackComponent });

export default MapForm;
