import FriendlyError from 'core/FriendlyError';
import { mapHistoryPath, mapsPath, sharedMapsPath } from 'database/Config';
import { IMapHistory } from 'database';
import { IUser } from 'database/users';
import { IMapDoc } from '../Map';
import firebase from 'firebase';

export type AssignMapFields = {
    dateAssigned: string;
    assignees: Record<string, true>;
};

export const assignMap = async (
    database: firebase.database.Database,
    folderID: string,
    mapID: string,
    userPerformingAction: IUser,
    users: Record<string, IUser>,
    { dateAssigned, assignees }: AssignMapFields
) => {
    try {
        if (mapID && dateAssigned && assignees) {
            if (!Object.keys(assignees).length) throw new Error('Assignee is required');
            const assigneeNames = Object.keys(assignees).map((id) => users[id].name);

            const newFields: Partial<IMapDoc> = {
                returned: false,
                dateAssigned: dateAssigned,
                dateReturned: null,
                assignees,
            };

            const history: IMapHistory = {
                type: 'ASSIGNED',
                createdBy: userPerformingAction.name,
                userName: assigneeNames.join(', '),
                date: dateAssigned,
            };

            const updates: Record<string, any> = {};

            for (const field of Object.keys(newFields)) {
                updates[`${mapsPath({ folderID, mapID })}/${field}`] = (newFields as any)[field];
            }

            //Share this map with the assignees
            for (const userID of Object.keys(assignees)) {
                const user = users[userID];
                //Only assign map to shared path using the email field;
                if (user.email) {
                    updates[sharedMapsPath({ folderID, userID: user.email, mapID })] = true;
                }
            }

            await database.ref().update(updates);
            await database.ref(mapHistoryPath({ folderID, mapID })).push(history);
        } else throw new Error('Unable to assign Map Invalid args');
    } catch (error) {
        throw new FriendlyError('Unable to assign map', null, error);
    }
};

export default assignMap;
