import { IMapComparatorParams, IMapDoc } from 'database';
import useMaps from '../hooks/useMaps';
import { GridApi, GridReadyEvent, RowDoubleClickedEvent, ValueFormatterParams, ValueGetterParams } from 'ag-grid-community';
import { AgGridReact, AgGridColumnProps } from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-material.css';
import moment from 'moment';
import useUsers from '../../users/hooks/useUsers';
import { useContext, useEffect, useState } from 'react';
import { CustomThemeContext } from 'providers/CustomThemeProvider';
import '../../../../../themes/ag-grid.scss';
import { Box } from '@material-ui/core';
import MapAvatar from '../map/components/MapAvatar';
import { CellRenderer } from 'react-virtualized';
import { useNavigate } from 'react-router';

type Props = {
    folderID: string;
    comparator: IMapComparatorParams;
};

interface RowFormatter extends ValueFormatterParams {
    data: IMapDoc;
}

interface RowValueGetter extends ValueGetterParams {
    data: IMapDoc;
}

interface RowRenderer extends CellRenderer {
    data: IMapDoc;
}

const dateFilter = (filterLocalDateAtMidnight: Date, cellValue: string) => {
    const cellDate = new Date(cellValue);

    if (cellDate < filterLocalDateAtMidnight) {
        return -1;
    } else if (cellDate > filterLocalDateAtMidnight) {
        return 1;
    }
    return 0;
};

const dateFormatter = ({ value }: RowFormatter) => {
    if (!value) return '';
    return moment(value).format('DD/MM/YYYY');
};

const daysSince = ({ data, colDef }: RowValueGetter) => {
    if (colDef.field === 'dateReturned' && data.returned) return moment().diff(moment(data.dateReturned), 'days');
    if (colDef.field === 'dateAssigned' && !data.returned) return moment().diff(moment(data.dateAssigned), 'days');
    return 0;
};

const returnedGetter = (params: ValueGetterParams) => {
    if (params.data.returned === true) return 'Returned';
    return 'Assigned';
};

const referenceComparator = (a: string, b: string) => {
    return a.localeCompare(b, undefined, { numeric: true, sensitivity: 'base' });
};

export const MapTable: React.FunctionComponent<Props> = ({ folderID, comparator }) => {
    const users = useUsers(folderID);
    const maps = useMaps(folderID);
    const { currentTheme } = useContext(CustomThemeContext);
    const darkMode = Boolean(currentTheme === 'dark');
    const [gridApi, setGridApi] = useState<GridApi | null>(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (gridApi) {
            gridApi.setQuickFilter(comparator?.searchTerm);
        }
    }, [comparator, gridApi]);

    const onGridReady = (params: GridReadyEvent) => {
        setGridApi(params.api);
    };

    const userGetter = ({ colDef, data }: ValueGetterParams) => {
        if (!colDef.field || !data) return '';
        const userField = data[colDef.field];

        if (userField) {
            const userIDs = Object.keys(userField);

            return userIDs
                .filter((id) => users[id])
                .map((id) => users[id].name)
                .join(', ');
        }

        return '';
    };

    const view = (event: RowDoubleClickedEvent) => {
        navigate(`${event.data.id}`);
    };

    const columnDefs: Array<AgGridColumnProps> = [
        {
            width: 150,
            field: 'reference',
            filter: 'agTextColumnFilter',
            sortable: true,
            comparator: referenceComparator,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            checkboxSelection: true,
            headerName: '',
            sort: 'asc',
            getQuickFilterText: (params) => {
                return params.data.reference;
            },
            cellRendererFramework: ({ data }: RowRenderer) => {
                return (
                    <Box height="100%" display="flex" alignItems="center" justifyContent="center">
                        <MapAvatar size="sm" map={data} />
                    </Box>
                );
            },
        },
        {
            field: 'name',
            filter: 'agTextColumnFilter',
            sortable: true,
            getQuickFilterText: (params) => {
                return params.data.name;
            },
        },
        {
            field: 'overseers',
            filter: 'agTextColumnFilter',
            sortable: true,
            valueGetter: userGetter,
        },
        {
            headerName: 'Status',
            field: 'returned',
            filter: 'agTextColumnFilter',
            sortable: true,
            valueGetter: returnedGetter,
        },
        {
            field: 'assignees',
            filter: 'agTextColumnFilter',
            sortable: true,
            valueGetter: userGetter,
        },
        {
            field: 'dateAssigned',
            filter: 'agDateColumnFilter',
            sortable: true,
            valueFormatter: dateFormatter,
            getQuickFilterText: () => '',
            filterParams: {
                comparator: dateFilter,
            },
        },
        {
            field: 'dateReturned',
            filter: 'agDateColumnFilter',
            sortable: true,
            valueFormatter: dateFormatter,
            getQuickFilterText: () => '',
            filterParams: {
                comparator: dateFilter,
            },
        },
        {
            field: 'dateReturned',
            headerName: 'Days Returned',
            filter: 'agNumberColumnFilter',
            sortable: true,
            valueGetter: daysSince,
            getQuickFilterText: () => '',
        },
        {
            field: 'dateAssigned',
            headerName: 'Days Assigned',
            filter: 'agNumberColumnFilter',
            sortable: true,
            valueGetter: daysSince,
            getQuickFilterText: () => '',
        },
    ];

    return (
        <Box style={{ overflow: 'hidden', flexGrow: 1 }} className={darkMode ? 'ag-theme-material--dark' : 'ag-theme-material--light'}>
            <Box pb={2} height="100%" width="100%">
                <AgGridReact
                    suppressRowClickSelection={true}
                    onRowDoubleClicked={view}
                    rowSelection={'multiple'}
                    suppressCellSelection={true}
                    onGridReady={onGridReady}
                    rowData={maps}
                    columnDefs={columnDefs}
                ></AgGridReact>
            </Box>
        </Box>
    );
};

export default MapTable;
