import React from 'react';
import { Editor as DraftEditor, EditorState, RichUtils } from 'draft-js';
import { Box, createStyles, makeStyles } from '@material-ui/core';
import EditorToolbar from './EditorToolbar';

const useStyles = makeStyles((theme) => {
    const borderColor = theme.palette.type === 'light' ? 'rgba(0, 0, 0, 0.23)' : 'rgba(255, 255, 255, 0.23)';

    return createStyles({
        root: {
            borderRadius: theme.shape.borderRadius,
            border: `1px solid ${borderColor}`,
            '&:hover': {
                borderColor: theme.palette.text.primary,
            },
            '&:focus': {
                borderColor: theme.palette.primary.main,
                borderWidth: 2,
            },
        },
    });
});

type Props = {
    editorState: EditorState;
    onChange: (editorState: EditorState) => void;
    showToolbar?: boolean;
    readOnly?: boolean;
};

export const Editor: React.FunctionComponent<Props> = ({ editorState, onChange, readOnly, showToolbar = true }) => {
    const editor = React.useRef<DraftEditor>(null);
    const classes = useStyles();

    const handleKeyCommand = (command: string, editorState: EditorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            onChange(newState);
            return 'handled';
        }
        return 'not-handled';
    };

    const focusEditor = () => {
        if (editor && editor.current) editor.current.focus();
    };

    const toggleStyle = (command: string) => {
        onChange(RichUtils.toggleInlineStyle(editorState, command));
        focusEditor();
    };

    const toggleBlockType = (type: string) => {
        onChange(RichUtils.toggleBlockType(editorState, type));
        focusEditor();
    };

    return (
        <Box className={classes.root}>
            {showToolbar && <EditorToolbar toggleBlockType={toggleBlockType} toggleStyle={toggleStyle} editorState={editorState} />}
            <Box p={2}>
                <DraftEditor ref={editor} handleKeyCommand={handleKeyCommand} editorState={editorState} onChange={onChange} readOnly={readOnly} />
            </Box>
        </Box>
    );
};

export default Editor;
