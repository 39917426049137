import AccountCircle from '@material-ui/icons/AccountCircle';
import React, { Suspense, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Avatar, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemText, Menu, MenuItem, Switch } from '@material-ui/core';
import { useAuth, useUser } from 'reactfire';
import ProfileIcon from '@material-ui/icons/AccountBox';
import SignOutIcon from '@material-ui/icons/ExitToApp';
import ThemeIcon from '@material-ui/icons/Palette';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CustomThemeContext } from '../../providers/CustomThemeProvider';
import { withErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/fallbacks/ErrorFallback';
import firebase from 'firebase';
import { ThemeName } from 'themes/Theme';
import useLocalStorage from 'hooks/useLocalStorage';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        menuItem: {
            minWidth: 140,
            padding: theme.spacing(1),
            paddingLeft: theme.spacing(2),
        },
        profile: {
            minWidth: 220,
        },
        menuItemIcon: {
            marginRight: theme.spacing(1),
            color: theme.palette.grey[600],
        },
        profileContainer: {
            outline: 'none',
        },
    })
);

export const ProfileMenuComponent: React.FunctionComponent = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const auth = useAuth();
    const navigate = useNavigate();
    const classes = useStyles();
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_theme, setLocalTheme] = useLocalStorage<ThemeName>('appTheme');
    const { currentTheme, setTheme } = useContext(CustomThemeContext);
    const darkMode = Boolean(currentTheme === 'dark');

    const { data: user } = useUser<firebase.User>();

    const toggleDarkMode = () => {
        setTheme(darkMode ? 'light' : 'dark');
        setLocalTheme(darkMode ? 'light' : 'dark');
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSignOut = () => {
        handleClose();
        navigate('/sign-in', { replace: true });
        auth.signOut();
    };

    const handleProfile = () => {
        handleClose();
        navigate('/profile');
    };

    return (
        <React.Fragment>
            <IconButton aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" onClick={handleClick} color="inherit">
                <AccountCircle />
            </IconButton>
            <Menu
                id="profile-menu"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                open={Boolean(anchorEl)}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                onClose={handleClose}
            >
                <MenuItem className={classes.profileContainer} button={false}>
                    <List>
                        <ListItem>
                            <ListItemAvatar>
                                {user.displayName ? <Avatar>{user.displayName.substring(0, 2).toUpperCase()}</Avatar> : <ProfileIcon />}
                            </ListItemAvatar>
                            <ListItemText primary={user.displayName} secondary={user.email} />
                        </ListItem>
                    </List>
                </MenuItem>
                <Divider />
                <MenuItem className={classes.menuItem} onClick={handleProfile}>
                    <ProfileIcon className={classes.menuItemIcon} />
                    Profile
                </MenuItem>
                <MenuItem className={classes.menuItem} onClick={() => toggleDarkMode()}>
                    <ThemeIcon className={classes.menuItemIcon} />
                    Dark Mode
                    <Switch checked={darkMode} color="primary" name="checkedB" inputProps={{ 'aria-label': 'primary checkbox' }} />
                </MenuItem>
                <MenuItem className={classes.menuItem} onClick={handleSignOut}>
                    <SignOutIcon className={classes.menuItemIcon} />
                    Sign Out
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
};

const ProfileMenuWithSuspense: React.FunctionComponent = () => {
    return (
        <Suspense
            fallback={
                <IconButton aria-label="account of current user" aria-controls="menu-appbar" aria-haspopup="true" color="inherit">
                    <AccountCircle />
                </IconButton>
            }
        >
            <ProfileMenuComponent />
        </Suspense>
    );
};

export const ProfileMenu = withErrorBoundary(ProfileMenuWithSuspense, { FallbackComponent: ErrorFallback });

export default ProfileMenu;
