import { mapBoundaryPath, mapDetailsPath, mapsPath, sharedMapsPath } from 'database/Config';
import { IMap, IMapDoc } from '../Map';
import { IMapDetails } from '../MapDetails';
import firebase from 'firebase';

type CreateMapReturnType = {
    updates: Record<string, IMapDoc | IMapDetails | true>;
    map: IMapDoc;
};

export const createMap = async (
    database: firebase.database.Database,
    folderID: string,
    newMap: IMap,
    mapDetails: IMapDetails,
    autoSync = true
): Promise<CreateMapReturnType> => {
    const newMapID = database.ref(mapsPath({ folderID })).push().key;

    if (!newMapID) throw new Error('Unable to create map no id was created');
    const sharedWith = [...Object.keys(newMap.assignees || {}), ...Object.keys(newMap.overseers || {})];

    const mapDoc: IMapDoc = {
        ...newMap,
        id: newMapID,
    };

    const updates: { [path: string]: any } = {
        [mapsPath({ folderID, mapID: newMapID })]: mapDoc,
        [`${mapBoundaryPath({ folderID, mapID: newMapID })}/color`]: mapDoc.color || '',
    };

    if (Object.keys(mapDetails).length) {
        const mapDetailsDoc: IMapDetails = {
            ...mapDetails,
        };

        if (mapDetailsDoc) updates[mapDetailsPath({ folderID, mapID: newMapID })] = mapDetailsDoc;
    }

    for (const userID of sharedWith) {
        updates[sharedMapsPath({ folderID, userID, mapID: mapDoc.id })] = true;
    }

    if (autoSync) {
        await database.ref().update(updates);
    }

    return { updates, map: mapDoc };
};

export default createMap;
