import FriendlyError from 'core/FriendlyError';
import { foldersPath } from 'database/Config';
import { FolderActionError } from '.';
import { IFolderDoc } from '../Folder';
import firebase from 'firebase';

export const updateFolder = async (database: firebase.database.Database, folderID: string, fields: Partial<IFolderDoc>): Promise<void> => {
    try {
        const folderLocation = foldersPath({ folderID });

        await database.ref(folderLocation).update(fields);
    } catch (error) {
        throw new FriendlyError(FolderActionError.UPDATE_FAILED, { id: folderID }, error);
    }
};

export default updateFolder;
