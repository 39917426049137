import { IDoc } from 'database';

export enum UserRole {
    OWNER = 'owner',
    ADMIN = 'admin',
    OVERSEER = 'overseer',
    ASSIGNEE = 'assignee',
}

export interface IUser {
    name: string;
    role: UserRole;
    email: string | null;
    latestInviteID?: string;
}

export interface IUserDoc extends IDoc, IUser {
    //Email should always be set if no email was set then id is used
    email: string;
}

export const userIsOverseerOrAbove = (user: IUser) => {
    switch (user.role) {
        case UserRole.ADMIN:
            return true;
        case UserRole.OVERSEER:
            return true;
        default:
            return false;
    }
};

export enum UserAction {
    DELETE = 'Delete',
    EDIT = 'Edit',
    INVITE = 'Invite',
}
