import { Typography } from '@material-ui/core';
import { Editor, EditorState, convertFromRaw } from 'draft-js';
import React, { useState } from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { markdownToDraft } from 'markdown-draft-js';

type Props = {
    notes: string;
};

const MapNotesComponent: React.FunctionComponent<Props> = ({ notes }) => {
    const [editorState, setEditorState] = useState(() => EditorState.createWithContent(convertFromRaw(markdownToDraft(notes))));

    return <Editor editorState={editorState} readOnly={true} onChange={(state) => setEditorState(state)} />;
};

const FallbackComponent: React.FunctionComponent = () => {
    return <Typography>Unable To Load Map Notes</Typography>;
};

export const MapNotes = withErrorBoundary(MapNotesComponent, { FallbackComponent });

export default MapNotes;
