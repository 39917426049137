import { Box, Container, Typography } from '@material-ui/core';
import React from 'react';
import { useParams } from 'react-router-dom';
import PageMeta from '../../../../../components/page/PageMeta';
import NewMapForm from '../components/forms/NewMapForm';
import MapsBreadcrumbs from '../components/MapsBreadcrumbs';

interface Props {}

export const NewMapRoute: React.FunctionComponent<Props> = () => {
    const { folderID } = useParams();

    return (
        <Box flex={1}>
            <PageMeta title="New Map" />

            <Container>
                <Box mt={3} mb={2}>
                    <MapsBreadcrumbs folderID={folderID}>
                        <Typography variant="h6">New</Typography>
                    </MapsBreadcrumbs>
                </Box>
                <NewMapForm folderID={folderID} />
            </Container>
        </Box>
    );
};

export default NewMapRoute;
