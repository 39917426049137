import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useDatabase } from 'reactfire';
import { IUser, addUser } from 'database';

type UseAddUser = {
    running: boolean;
    addUser: (newUser: IUser) => Promise<void>;
};

export default function useAddUser(folderID: string): UseAddUser {
    const database = useDatabase();
    const [running, setRunning] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const add = async (newUser: IUser) => {
        setRunning(true);

        return await addUser(database, folderID, newUser)
            .then((user) => {
                enqueueSnackbar(`User ${user.name} Created`, { variant: 'success' });
            })
            .catch(() => {
                enqueueSnackbar('Unable to create User', { variant: 'error' });
            })
            .finally(() => setRunning(false));
    };

    return {
        addUser: add,
        running,
    };
}
