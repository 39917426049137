import { exportMaps } from 'database';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useDatabase } from 'reactfire';
import moment from 'moment';

type UseExportMaps = (
    folderID: string
) => {
    exportMaps: () => Promise<void>;
    running: boolean;
};

export const useExportMaps: UseExportMaps = (folderID: string) => {
    const [running, setRunning] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const database = useDatabase();

    const exportAllMaps = async () => {
        setRunning(true);
        const { csv, error } = await exportMaps(database, folderID);
        if (csv) {
            const hiddenElement = document.createElement('a');
            const universalBOM = '\uFEFF';
            hiddenElement.href = 'data:text/csv; charset=utf-8,' + encodeURIComponent(universalBOM + csv);
            hiddenElement.target = '_blank';
            hiddenElement.download = `${moment().format('YYYY-MM-DD')}-mapper.csv`;
            hiddenElement.click();
            hiddenElement.remove();
        } else if (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
        }
        setRunning(false);
    };

    return {
        running,
        exportMaps: exportAllMaps,
    };
};

export default useExportMaps;
