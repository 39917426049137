import { ListItemIcon, Menu, MenuItem, Typography } from '@material-ui/core';
import React from 'react';
import { IUserDoc, UserAction } from 'database';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import ShareIcon from '@material-ui/icons/ShareOutlined';
import useUser from '../hooks/useUser';

type Props = {
    user: IUserDoc;
    anchorEl: HTMLButtonElement | null;
    onUserAction: (action: UserAction, user: IUserDoc) => void;
    onClose: () => void;
    folderID: string;
};

const UserMenu: React.FunctionComponent<Props> = ({ folderID, user, anchorEl, onUserAction, onClose }) => {
    const authedUser = useUser(folderID);

    const handleAction = (action: UserAction, user: IUserDoc) => {
        onClose();
        onUserAction(action, user);
    };
    const userIsMe = Boolean(authedUser.email === user.email);

    return (
        <Menu onClose={onClose} anchorEl={anchorEl} open={Boolean(anchorEl)}>
            <MenuItem button onClick={() => handleAction(UserAction.EDIT, user)}>
                <ListItemIcon>
                    <EditIcon fontSize="small" />
                </ListItemIcon>
                <Typography variant="inherit">Edit User</Typography>
            </MenuItem>

            {!userIsMe && Boolean(user.email) && (
                <MenuItem button onClick={() => handleAction(UserAction.INVITE, user)}>
                    <ListItemIcon>
                        <ShareIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">Invite</Typography>
                </MenuItem>
            )}
            {!userIsMe && (
                <MenuItem button onClick={() => handleAction(UserAction.DELETE, user)}>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">Delete User</Typography>
                </MenuItem>
            )}
        </Menu>
    );
};

export default UserMenu;
