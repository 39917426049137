import FriendlyError from 'core/FriendlyError';
import { mapDetailsPath } from 'database/Config';
import firebase from 'firebase';
import { IMapDetails } from '../MapDetails';

type FetchMapsResult = {
    error?: FriendlyError;
    mapDetails?: Record<string, IMapDetails>;
};

const fetchMapsError = 'Unable to fetch maps';

export const fetchMapDetails = async (database: firebase.database.Database, folderID: string): Promise<FetchMapsResult> => {
    try {
        const ref = database.ref(mapDetailsPath({ folderID }));
        const mapDetails = (await ref.once('value')).val();
        return { mapDetails };
    } catch (error) {
        return { error: new FriendlyError(fetchMapsError, null, error) };
    }
};

export default fetchMapDetails;
