import { IUser } from './User';

export interface IUserComparatorParams {
    searchTerm?: string;
}

export class UserComparator {
    static includeMap = (user: IUser, { searchTerm }: IUserComparatorParams, users?: Record<string, IUser>): boolean => {
        if (searchTerm) {
            const term = searchTerm.toLowerCase();
            return user.name.toLowerCase().indexOf(term) > -1 || user.email?.toLowerCase() === term;
        }
        return true;
    };
}

export default UserComparator;
