import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import { MapsRoute } from './maps/Maps';
import { Box, createStyles, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import PageMeta from 'components/page/PageMeta';
import FolderStatTile from './components/FolderStatTile';
import MapIcon from '@material-ui/icons/TerrainOutlined';
import UsersIcon from '@material-ui/icons/PeopleOutline';
import { blue, deepPurple, green, red } from '@material-ui/core/colors';
import MapOverview from '../../../components/maps/Map';
import FolderBreadcrumbs from './components/FolderBreadcrumbs';
import Users from './users/Users';
import useFolder from './hooks/useFolder';
import useDeviceType from 'hooks/useDeviceType';
import useFolderStats from './hooks/useFolderStats';
import SettingsRoute from './settings/Settings';
import useMapBoundaries from './maps/hooks/useMapBoundaries';

const useStyles = makeStyles((theme) =>
    createStyles({
        map: {
            overflow: 'hidden',
            height: '76vh',
            borderRadius: 6,
            position: 'relative',
        },
        folderActivity: {
            maxHeight: '76vh',
            overflow: 'auto',
        },
    })
);

export const Folder: React.FunctionComponent = () => {
    const classes = useStyles();
    const { folderID } = useParams();
    const { isDesktop } = useDeviceType();
    const folder = useFolder(folderID);
    const folderStats = useFolderStats(folderID);
    const boundaries = useMapBoundaries(folderID);

    return (
        <Box flex={1} p={2} pl={3} pr={3}>
            <PageMeta title={folder.name} />

            <Box mt={1} mb={2}>
                <FolderBreadcrumbs folderID={folderID} />
            </Box>

            <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <FolderStatTile
                        color={red[300]}
                        icon={MapIcon}
                        amount={folderStats.maps.overdue}
                        label="Overdue Maps"
                        link="maps?filters[overdue]"
                    />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <FolderStatTile
                        color={blue[300]}
                        icon={MapIcon}
                        amount={folderStats.maps.returned}
                        label="Returned Maps"
                        link="maps?filters[returned]"
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <FolderStatTile
                        color={green[300]}
                        icon={MapIcon}
                        amount={folderStats.maps.assigned}
                        label="Assigned Maps"
                        link="maps?filters[assigned]"
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                    <FolderStatTile color={deepPurple[400]} icon={UsersIcon} amount={folderStats.users.total} label="Users" link="users" />
                </Grid>

                <Grid item xs={12} md={12} lg={9}>
                    <Paper elevation={3} className={classes.map}>
                        <MapOverview boundaries={boundaries} dragging={isDesktop} scrollWheelZoom={isDesktop} />
                    </Paper>
                </Grid>

                <Grid item xs={12} md={6} lg={3}>
                    <Paper className={classes.folderActivity}>
                        <Box pt={3} pl={3} pb={3}>
                            <Typography variant="h6">Activity</Typography>

                            <Box textAlign="center" mt={2} pr={3}>
                                <Typography variant="body2">No Activity</Typography>
                            </Box>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

const FolderWithSuspense = () => {
    return (
        <Suspense fallback={null}>
            <Folder />
        </Suspense>
    );
};

export const FolderRoute: React.FunctionComponent = () => {
    return (
        <Routes>
            <Route path="/" element={<FolderWithSuspense />}></Route>
            <Route path="maps/*" element={<MapsRoute />}></Route>
            <Route path="users/*" element={<Users />}></Route>
            <Route path="settings/*" element={<SettingsRoute />}></Route>
        </Routes>
    );
};

export default FolderRoute;
