import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useDatabase } from 'reactfire';
import useFolder from '../../../hooks/useFolder';
import { IMap, createMap, IMapDetails } from 'database';

type UseCreateMap = {
    running: boolean;
    createMap: (newMap: IMap, mapDetails: IMapDetails) => Promise<void>;
};

export default function useCreateMap(folderID: string): UseCreateMap {
    const database = useDatabase();
    const folder = useFolder(folderID);
    const [running, setRunning] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const create = async (newMap: IMap, mapDetails: IMapDetails) => {
        setRunning(true);

        try {
            await createMap(database, folder.id, newMap, mapDetails)
                .then((map) => {
                    enqueueSnackbar(`Map ${newMap.name} Created`, { variant: 'success' });
                    return map;
                })
                .catch((error) => {
                    console.error(error);
                    enqueueSnackbar('Unable to create map', { variant: 'error' });
                })
                .finally(() => setRunning(false));
        } catch (error) {
            enqueueSnackbar(`Unable to create map ${error}`, { variant: 'error' });
        }
    };

    return {
        createMap: create,
        running,
    };
}
