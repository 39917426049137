type FoldersPathParams = {
    folderID?: string;
};

export const foldersPath = ({ folderID }: FoldersPathParams = {}): string => {
    return `/folders${folderID ? `/${folderID}` : ''}`;
};

type SharedFoldersPathParams = { email: string; folderID?: string };

export const sharedFoldersPath = ({ email, folderID }: SharedFoldersPathParams): string => {
    email = encodeEmail(email);
    return `/sharedFolders/${email}${folderID ? `/${folderID}` : ''}`;
};

type FolderUsersPathParams = { folderID: string; email?: string };

export const folderUsersPath = ({ folderID, email }: FolderUsersPathParams): string => {
    email = encodeEmail(email);
    if (email && !folderID) throw new Error('Folder Users Path Invalid params');
    return `/folderUsers/${folderID}${email ? `/${email}` : ''}`;
};

type MapsPathParams = { folderID: string; mapID?: string };

export const mapsPath = ({ folderID, mapID }: MapsPathParams): string => {
    if (mapID && !folderID) throw new Error('Maps Path Invalid params');

    return `/maps/${folderID}${mapID ? `/${mapID}` : ''}`;
};

type SharedMapsPathParams = { folderID: string; userID?: string; mapID?: string };

export const sharedMapsPath = ({ folderID, userID, mapID }: SharedMapsPathParams): string => {
    if ((mapID && !userID) || !folderID) throw new Error('Shared maps path Invalid params');
    return `/sharedMaps/${folderID}${userID ? `/${userID}` : ''}${mapID ? `/${mapID}` : ''}`;
};

type MapDetailsPathParams = { folderID: string; mapID?: string };
export const mapDetailsPath = ({ folderID, mapID }: MapDetailsPathParams): string => {
    if (mapID && !folderID) throw new Error('Map Details Path Invalid params');

    return `/mapDetails/${folderID}${mapID ? `/${mapID}` : ''}`;
};

type MapBoundaryPathParams = { folderID: string; mapID?: string };
export const mapBoundaryPath = ({ folderID, mapID }: MapBoundaryPathParams): string => {
    if (mapID && !folderID) throw new Error('Map Boundary Path Invalid params');

    return `/mapBoundary/${folderID}${mapID ? `/${mapID}` : ''}`;
};

type MapHistoryPathParams = { folderID: string; mapID?: string; historyItemID?: string };
export const mapHistoryPath = ({ folderID, mapID, historyItemID }: MapHistoryPathParams): string => {
    if (mapID && !folderID) throw new Error('Map History Path Invalid params');

    return `/mapHistory/${folderID}${mapID ? `/${mapID}` : ''}${historyItemID ? `/${historyItemID}` : ''}`;
};

type FolderTagsPathParams = { folderID: string; tagID?: string };
export const folderTagsPath = ({ folderID, tagID }: FolderTagsPathParams): string => {
    if (tagID && !folderID) throw new Error('Folder tags Path Invalid params');

    return `/tags/${folderID}${tagID ? `/${tagID}` : ''}`;
};

type FolderInvitesPathParams = { folderID: string; inviteID?: string };
export const folderInvitesPath = ({ folderID, inviteID }: FolderInvitesPathParams) => {
    if (!folderID) throw new Error('FolderID passed but was invalid');
    return `/folderInvites/${folderID}${inviteID ? `/${inviteID}` : ''}`;
};

export const encodeEmail = (email?: string | null): string => {
    if (!email) return '';
    const isEmail = email.indexOf('@') > -1;
    if (isEmail) email = email.trim().toLowerCase().replace(/\./g, '%2e');
    return email;
};

export const decodeEmail = (email?: string | null): string => {
    if (!email) return '';
    return email.replace(/%2e/g, '.');
};
