import moment from 'moment';
import * as yup from 'yup';
import { IMap } from './Map';

const assignees = yup
    .array()
    .nullable()
    .when('returned', {
        is: false,
        then: yup.array().min(1, 'At least one assignee is required').required('Assignee is required'),
    });

const dateAssigned = yup
    .date()
    .nullable()
    .when('returned', {
        is: (returned) => returned === false,
        then: yup.date().required('Date assigned is required'),
    });

const dateReturned = yup
    .date()
    .nullable()
    .max(moment().add(1, 'day').toDate(), 'Date returned must be in the past')
    .when('returned', {
        is: (returned) => returned === true,
        then: yup.date().required('Date returned is required'),
    });

export const MapSchema = yup.object<IMap>().shape({
    name: yup.string().required('Map name is required'),
    reference: yup.string(),
    returned: yup.boolean().required('Map must either be returned or assigned'),
    dateReturned,
    dateAssigned,
    overseers: yup.array().nullable(),
    assignees,
    tags: yup.array().nullable(),
});

export const AssignMapSchema = yup.object().shape({
    dateAssigned: yup.date().required('Date assigned is required'),
    assignees: yup.array().min(1, 'At least one assignee is required').required('Assignee is required'),
});
