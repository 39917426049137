import { Typography } from '@material-ui/core';
import React, { Suspense } from 'react';
import AppLink from '../../../../../components/links/AppLink';
import FolderBreadcrumbs from '../../components/FolderBreadcrumbs';
import useMap from '../map/hooks/useMap';

type MapsBreadcrumbsProps = {
    folderID: string;
    mapID?: string;
    children?: React.ReactNode;
};

const MapName: React.FunctionComponent<{ folderID: string; mapID: string; hasChildren: boolean }> = ({ folderID, mapID, hasChildren }) => {
    const map = useMap(folderID, mapID);

    return (
        <AppLink to={`/folders/${folderID}/maps/${mapID}`}>
            <Typography color="textPrimary" variant="h6">
                {map.name}
            </Typography>
        </AppLink>
    );
};

export const MapsBreadcrumbs: React.FunctionComponent<MapsBreadcrumbsProps> = ({ folderID, mapID, children }) => {
    return (
        <FolderBreadcrumbs folderID={folderID}>
            {!mapID && !children ? (
                <Typography variant="h6">Maps</Typography>
            ) : (
                <AppLink to={`/folders/${folderID}/maps`}>
                    <Typography color="textPrimary" variant="h6">
                        Maps
                    </Typography>
                </AppLink>
            )}

            {mapID && (
                <Suspense fallback={null}>
                    <MapName folderID={folderID} mapID={mapID} hasChildren={!!children} />
                </Suspense>
            )}
            {children}
        </FolderBreadcrumbs>
    );
};

export default MapsBreadcrumbs;
