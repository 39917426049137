import React from 'react';
import { Box, ButtonGroup, Button, Popper, Grow, Paper, ClickAwayListener, MenuList, MenuItem, makeStyles, createStyles } from '@material-ui/core';
import BoldIcon from '@material-ui/icons/FormatBold';
import ItalicIcon from '@material-ui/icons/FormatItalic';
import UnderlineIcon from '@material-ui/icons/FormatUnderlined';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ListIcon from '@material-ui/icons/FormatListBulleted';
import { EditorState } from 'draft-js';

type Props = {
    toggleStyle: (command: string) => void;
    toggleBlockType: (type: string) => void;
    editorState: EditorState;
};

const INLINE_STYLES = [
    { icon: <BoldIcon />, label: 'Bold', style: 'BOLD' },
    { icon: <ItalicIcon />, label: 'Italic', style: 'ITALIC' },
    { icon: <UnderlineIcon />, label: 'Underline', style: 'UNDERLINE' },
];

const BLOCK_TYPES = [{ label: 'List', type: 'unordered-list-item', icon: <ListIcon /> }];

type Heading = {
    label: string;
    style: string;
};
const HEADINGS: Heading[] = [
    { label: 'Heading 1', style: 'header-one' },
    { label: 'Heading 2', style: 'header-two' },
    { label: 'Heading 3', style: 'header-three' },
    { label: 'Heading 4', style: 'header-four' },
    { label: 'Heading 5', style: 'header-five' },
    { label: 'Heading 6', style: 'header-six' },
];

const useStyles = makeStyles((theme) =>
    createStyles({
        headingsMenu: {
            zIndex: 10,
        },
    })
);

const EditorToolbar: React.FunctionComponent<Props> = ({ editorState, toggleBlockType, toggleStyle }) => {
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const [selectedHeading, setSelectedHeading] = React.useState<Heading>(HEADINGS[0]);
    const classes = useStyles();

    const currentStyle = editorState.getCurrentInlineStyle();
    const selection = editorState.getSelection();
    const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();

    const toggleHeading = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        toggleBlockType(selectedHeading.style);
    };

    const handleHeadingClicked = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, heading: Heading) => {
        event.preventDefault();
        setSelectedHeading(heading);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
        event.preventDefault();
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) return;
        setOpen(false);
    };

    return (
        <Box p={1} display="flex" flexWrap="wrap">
            <Box mr={1} mb={1}>
                <ButtonGroup size="small" variant="outlined" ref={anchorRef} aria-label="split button">
                    <Button color={selectedHeading.style === blockType ? 'secondary' : 'default'} onMouseDown={toggleHeading}>
                        {selectedHeading.label}
                    </Button>
                    <Button
                        size="small"
                        aria-controls={open ? 'headings-button-menu' : undefined}
                        aria-expanded={open ? 'true' : undefined}
                        aria-label="select heading format"
                        aria-haspopup="menu"
                        onMouseDown={(event) => {
                            event.preventDefault();
                            handleToggle();
                        }}
                    >
                        <ArrowDropDownIcon />
                    </Button>
                </ButtonGroup>
                <Popper className={classes.headingsMenu} open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="headings-button-menu">
                                        {HEADINGS.map((option) => (
                                            <MenuItem
                                                key={option.label}
                                                selected={option.label === selectedHeading.label}
                                                onMouseDown={(event) => handleHeadingClicked(event, option)}
                                            >
                                                {option.label}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Box>

            <Box mr={1}>
                <ButtonGroup size="small" aria-label="Text style">
                    {INLINE_STYLES.map((action) => {
                        return (
                            <Button
                                key={action.label}
                                color={currentStyle.has(action.style) ? 'secondary' : 'default'}
                                size="small"
                                aria-label={action.label}
                                onMouseDown={(event) => {
                                    event.preventDefault();
                                    toggleStyle(action.style);
                                }}
                            >
                                {action.icon}
                            </Button>
                        );
                    })}
                </ButtonGroup>
            </Box>

            <Box>
                <ButtonGroup size="small" aria-label="Text block types">
                    {BLOCK_TYPES.map((action) => {
                        return (
                            <Button
                                key={action.label}
                                color={action.type === blockType ? 'secondary' : 'default'}
                                size="small"
                                aria-label={action.label}
                                onMouseDown={(event) => {
                                    event.preventDefault();
                                    toggleBlockType(action.type);
                                }}
                            >
                                {action.icon}
                            </Button>
                        );
                    })}
                </ButtonGroup>
            </Box>
        </Box>
    );
};

export default EditorToolbar;
