import React, { Suspense } from 'react';
import { useNavigate } from 'react-router-dom';
import useCreateMap from '../../map/hooks/useCreateMap';
import { MapForm } from './MapForm';
import MapFormFields from './MapFormFields';

interface INewMapFormProps {
    folderID: string;
}

const NewMapFormComponent: React.FunctionComponent<INewMapFormProps> = ({ folderID }) => {
    const { createMap, running } = useCreateMap(folderID);
    const navigate = useNavigate();

    const save = async (fields: MapFormFields) => {
        const newMap = MapFormFields.getMap(fields);
        const mapDetails = MapFormFields.getMapDetails(fields);
        if (!running) await createMap(newMap, mapDetails);
        navigate('../');
    };

    return <MapForm folderID={folderID} onSubmit={save} />;
};

export const NewMapForm: React.FunctionComponent<INewMapFormProps> = (props) => {
    return (
        <Suspense fallback={null}>
            <NewMapFormComponent {...props} />
        </Suspense>
    );
};

export default NewMapForm;
