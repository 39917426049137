import { Box, Container } from '@material-ui/core';
import React, { Suspense } from 'react';
import { Route, useParams, Routes, Outlet } from 'react-router-dom';
import PageMeta from '../../../../../components/page/PageMeta';
import MapsBreadcrumbs from '../components/MapsBreadcrumbs';
import useMap from './hooks/useMap';
import EditMap from './views/EditMap';
import MapDashboard from './views/MapDashboard';

const Map: React.FunctionComponent = () => {
    const { folderID, mapID } = useParams();

    const map = useMap(folderID, mapID);

    return (
        <React.Fragment>
            <PageMeta title={`Maps | ${map.name}`} />
            <Container>
                <Box mb={2} mt={3}>
                    <MapsBreadcrumbs folderID={folderID} mapID={mapID} />
                </Box>

                <Outlet />
            </Container>
        </React.Fragment>
    );
};

const MapWithSuspense = () => {
    return (
        <Suspense fallback={null}>
            <Map />
        </Suspense>
    );
};

export const MapRoute: React.FunctionComponent = () => {
    return (
        <Routes>
            <Route path="/" element={<MapWithSuspense />}>
                <Route path="/" element={<MapDashboard />}></Route>
                <Route path="/edit" element={<EditMap />}></Route>
            </Route>
        </Routes>
    );
};

export default MapRoute;
