import { useDatabase, useDatabaseObjectData } from 'reactfire';
import { foldersPath, IFolderDoc } from 'database';

export const useFolder = (folderID: string): IFolderDoc => {
    const database = useDatabase();

    const folderRef = database.ref(foldersPath({ folderID }));
    const { data: folder } = useDatabaseObjectData<IFolderDoc>(folderRef, { idField: 'id' });

    return folder;
};

export default useFolder;
