import { Divider, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core';
import { DeleteForever } from '@material-ui/icons';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ExportIcon from '@material-ui/icons/CloudDownloadOutlined';

import TextFieldDialog, { TextFieldDialogOptions } from 'components/dialogs/TextFieldDialog';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useDatabase } from 'reactfire';
import useFolder from '../../hooks/useFolder';
import MapsImportDialog from './MapsImportDialog';
import { deleteAllMaps } from 'database';
import { useSnackbar } from 'notistack';
import MapsExportDialog from './MapsExportDialog';

const ConfirmDeleteDialogOptions: TextFieldDialogOptions = {
    initialValue: '',
    label: 'Enter Folder Name',
    title: 'Confirm',
    confirmLabel: 'Delete Maps',
    description: `Enter the folder name to confirm.
    This action can not be undone.`,
};

export const MapSettings: React.FunctionComponent = () => {
    const [showImportDialog, setShowImportDialog] = React.useState(false);
    const [showExportDialog, setShowExportDialog] = React.useState(false);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const { folderID } = useParams();

    const database = useDatabase();
    const { enqueueSnackbar } = useSnackbar();

    const folder = useFolder(folderID);

    const deleteAll = async () => {
        await deleteAllMaps(database, folderID)
            .catch((error) => {
                enqueueSnackbar(error, { variant: 'error' });
            })
            .then(() => enqueueSnackbar('All maps deleted', { variant: 'info' }));
        setShowConfirm(false);
    };

    return (
        <React.Fragment>
            <Typography variant="subtitle2">Maps</Typography>

            <List dense={true}>
                <ListItem button onClick={() => setShowImportDialog(true)}>
                    <ListItemText primary="Import Maps" secondary="Import maps from a valid custom CSV" />
                    <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="Import Maps">
                            <ArrowRightIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
                <Divider />

                <ListItem button onClick={() => setShowExportDialog(true)}>
                    <ListItemText primary="Export Maps" secondary={`Export all maps into a formatted CSV`} />
                    <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="Export All Maps">
                            <ExportIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
                <Divider />

                <ListItem button onClick={() => setShowConfirm(true)}>
                    <ListItemText primary="Delete Maps" secondary={`Delete all maps in the ${folder.name} folder`} />
                    <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="Delete All Maps">
                            <DeleteForever />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            </List>

            <MapsImportDialog folderID={folderID} onClose={() => setShowImportDialog(false)} open={showImportDialog} />
            <MapsExportDialog folderID={folderID} onClose={() => setShowExportDialog(false)} open={showExportDialog} />

            <TextFieldDialog
                onSubmit={deleteAll}
                open={showConfirm}
                onClose={() => setShowConfirm(false)}
                options={ConfirmDeleteDialogOptions}
                textToMatch={folder.name}
                matchErrorMsg={`Must match folder name ${folder.name}`}
            ></TextFieldDialog>
        </React.Fragment>
    );
};

export default MapSettings;
