import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import React, { Suspense, useState } from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { FolderAction, IFolderDoc } from 'database';

import ErrorOutline from '@material-ui/icons/ErrorOutline';
import useFolders from '../hooks/useFolders';
import useSharedFolders from '../hooks/useSharedFolders';
import FolderCard from '../folder/components/FolderCard';
import TextFieldDialog, { TextFieldDialogOptions } from 'components/dialogs/TextFieldDialog';
import useFolderActions from '../folder/hooks/useFolderActions';

const NoFolders = () => {
    return (
        <Box p={3} textAlign="center">
            <Typography variant="subtitle1">You have no Folders.</Typography>
        </Box>
    );
};

const useStyles = makeStyles({
    root: {
        flexGrow: 1,
    },
});

const FoldersListComponent: React.FunctionComponent<{}> = () => {
    const classes = useStyles();
    const folders = useFolders();
    const sharedFolders = useSharedFolders();
    const { deleteFolder, updateFolder } = useFolderActions();
    const [selectedFolder, setSelectedFolder] = useState<IFolderDoc | null>(null);
    const [showRenameDialog, setShowRenameDialog] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);

    if (!folders.length && !sharedFolders.length) {
        return <NoFolders />;
    }

    const handleClose = () => {
        setShowConfirm(false);
        setShowRenameDialog(false);
        setSelectedFolder(null);
    };

    const folderAction = (action: FolderAction, folder: IFolderDoc) => {
        setSelectedFolder(folder);
        switch (action) {
            case FolderAction.DELETE:
                return setShowConfirm(true);
            case FolderAction.RENAME:
                return setShowRenameDialog(true);
        }
    };

    const onRename = async (name: string) => {
        if (selectedFolder) return updateFolder(selectedFolder, { name }).finally(() => handleClose());
    };

    const onDelete = async () => {
        if (selectedFolder) deleteFolder(selectedFolder).finally(() => handleClose());
    };

    const ConfirmDeleteDialogOptions: TextFieldDialogOptions = {
        initialValue: '',
        label: 'Enter Folder Name',
        title: 'Confirm',
        confirmLabel: 'Delete Folder',
        description: `Enter the folder name to confirm.
        This action can not be undone.`,
    };

    const textFieldDialogOptions: TextFieldDialogOptions = {
        label: 'Folder Name',
        initialValue: selectedFolder?.name || '',
        title: 'Rename Folder',
        confirmLabel: 'Rename',
    };

    return (
        <React.Fragment>
            <Grid container className={classes.root} spacing={2}>
                {folders.map((folder, index) => (
                    <Grid key={index} item xs={12} sm={6} md={6} lg={4}>
                        <FolderCard folder={folder} onFolderAction={folderAction} />
                    </Grid>
                ))}
            </Grid>

            <Grid container className={classes.root} spacing={2}>
                {sharedFolders.map((folder, index) => (
                    <Grid key={index} item xs={12} sm={6} md={6} lg={4}>
                        <FolderCard folder={folder} onFolderAction={folderAction} showMenu={false} />
                    </Grid>
                ))}
            </Grid>

            <TextFieldDialog open={showRenameDialog} onClose={handleClose} options={textFieldDialogOptions} onSubmit={onRename}></TextFieldDialog>

            {selectedFolder && (
                <TextFieldDialog
                    onSubmit={onDelete}
                    open={showConfirm}
                    onClose={() => setShowConfirm(false)}
                    options={ConfirmDeleteDialogOptions}
                    textToMatch={selectedFolder.name}
                    matchErrorMsg={`Must match folder name ${selectedFolder.name}`}
                ></TextFieldDialog>
            )}
        </React.Fragment>
    );
};

const FolderListWithSuspense: React.FunctionComponent<{}> = (props) => {
    return (
        <Suspense fallback={'Loading folders...'}>
            <FoldersListComponent {...props} />
        </Suspense>
    );
};

const FallbackComponent: React.FunctionComponent = () => {
    return (
        <Box p={3} textAlign="center">
            <ErrorOutline />
            <Typography variant="subtitle1">Unable to load Folders</Typography>
        </Box>
    );
};

const FoldersList = withErrorBoundary(FolderListWithSuspense, { FallbackComponent });

export default FoldersList;
