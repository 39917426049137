import { useDatabase, useDatabaseObjectData } from 'reactfire';
import { folderUsersPath, IUserDoc, usersByID } from 'database';

export default function useUsers(folderID: string): Record<string, IUserDoc> {
    const usersRef = useDatabase().ref(folderUsersPath({ folderID }));
    const { data: users } = useDatabaseObjectData<Record<string, IUserDoc>>(usersRef);
    //TODO FIX Remove stupid react fire: key
    delete users['NO_ID_FIELD' as any];
    return usersByID(users);
}
