import firebase from 'firebase';
import { useDatabase, useDatabaseListData, useUser } from 'reactfire';
import { IFolderDoc } from 'database';

type UseFoldersPramas = {
    limit?: number;
};

export default function useFolders({ limit }: UseFoldersPramas = {}): Array<IFolderDoc> {
    const { data: user } = useUser<firebase.User>();

    let foldersRef = useDatabase().ref(`/folders`).orderByChild('owner').equalTo(user.uid);
    if (limit) {
        foldersRef = foldersRef.limitToFirst(limit);
    }
    const { data: folders } = useDatabaseListData<IFolderDoc>(foldersRef, { idField: 'id' });

    return folders;
}
