import React from 'react';
import PageMeta from '../../../../components/page/PageMeta';
import { Box, Container, Typography } from '@material-ui/core';
import { useParams, Route, Routes } from 'react-router-dom';
import useFolder from '../hooks/useFolder';
import FolderBreadcrumbs from '../components/FolderBreadcrumbs';
import MapSettings from './components/MapSettings';
import TagSettings from './components/TagSettings';
import UserSettings from './components/UserSettings';

const Settings: React.FunctionComponent = () => {
    let { folderID } = useParams();
    const folder = useFolder(folderID);
    return (
        <Box flex={1}>
            <PageMeta title={`${folder.name} | Settings`} />

            <Container>
                <Box mt={3} mb={2}>
                    <FolderBreadcrumbs folderID={folderID}>
                        <Typography variant="h6">Settings</Typography>
                    </FolderBreadcrumbs>
                </Box>

                <MapSettings />
                <TagSettings />
                <UserSettings />
            </Container>
        </Box>
    );
};

export const SettingsRoute: React.FunctionComponent = () => {
    return (
        <Routes>
            <Route path="/" element={<Settings />}></Route>
        </Routes>
    );
};

export default SettingsRoute;
