import { useDatabase, useDatabaseListData } from 'reactfire';
import { folderUsersPath, IUserDoc } from 'database';

export default function useUsersList(folderID: string, filter?: (user: IUserDoc) => boolean): Array<IUserDoc> {
    const UsersRef = useDatabase().ref(folderUsersPath({ folderID })).orderByChild('name');
    const { data: users } = useDatabaseListData<IUserDoc>(UsersRef);

    //Remove emails that match userID
    for (const user of users) {
        if (user.id === user.email) user.email = '';
    }
    return filter ? users.filter(filter) : users;
}
