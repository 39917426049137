import { useDatabase, useDatabaseObjectData } from 'reactfire';
import { folderInvitesPath } from 'database';
import { IInviteDoc } from 'database/invites';

export const useInvite = (folderID: string, inviteID?: string): IInviteDoc | null => {
    const database = useDatabase();

    const inviteRef = database.ref(folderInvitesPath({ folderID, inviteID }));
    const { data: invite } = useDatabaseObjectData<IInviteDoc>(inviteRef, { idField: 'id' });

    //TODO reactefire defaults returning object with id field remove react fire and fix this
    if (invite.id === folderID || Object.keys(invite).length <= 1) return null;

    return invite;
};

export default useInvite;
