import React, { useState } from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import { getTheme, ThemeName } from '../themes/Theme';
import useLocalStorage from '../hooks/useLocalStorage';
import { useMediaQuery } from '@material-ui/core';

type ProviderContext = {
    currentTheme: ThemeName;
    setTheme: (name: ThemeName) => void;
};

export const CustomThemeContext = React.createContext<ProviderContext>({
    currentTheme: 'light',
    setTheme: (_name: ThemeName) => {},
});

type Props = {
    children: React.ReactNode;
};

const CustomThemeProvider = ({ children }: Props) => {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
    const [currentTheme, setTheme] = useLocalStorage<ThemeName>('appTheme', prefersDarkMode ? 'dark' : 'light');

    const [themeName, _setThemeName] = useState<ThemeName>(currentTheme);

    const theme = getTheme(themeName);

    const setThemeName = (name: ThemeName) => {
        setTheme(name);
        _setThemeName(name);
    };

    const contextValue = {
        currentTheme: themeName,
        setTheme: setThemeName,
    };

    return (
        <CustomThemeContext.Provider value={contextValue}>
            <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </CustomThemeContext.Provider>
    );
};

export default CustomThemeProvider;
