import FriendlyError from 'core/FriendlyError';
import { mapDetailsPath, mapsPath, sharedMapsPath } from 'database/Config';
import firebase from 'firebase';

export const deleteAllMaps = async (database: firebase.database.Database, folderID: string): Promise<void> => {
    try {
        if (!folderID) throw new Error('Unable to delete all maps invalid params');

        const updates: { [path: string]: null } = {
            [mapsPath({ folderID })]: null,
            [mapDetailsPath({ folderID })]: null,
            [sharedMapsPath({ folderID })]: null,
        };

        await database.ref().update(updates);
    } catch (error) {
        throw new FriendlyError('Unable to delete all maps', null, error);
    }
};

export default deleteAllMaps;
