import React from 'react';
import { IUser, UserRole } from 'database';

type Props = {
    user: IUser;
    roles: Array<UserRole>;
};

export const HiddenForRoles: React.FunctionComponent<Props> = ({ roles, user, children }) => {
    if (roles.indexOf(user.role) > -1) {
        return null;
    } else {
        return <React.Fragment>{children}</React.Fragment>;
    }
};

export default HiddenForRoles;
