import FriendlyError from 'core/FriendlyError';
import firebase from 'firebase';
import { IInvite, IInviteDoc } from '../Invite';
import { InviteActionError } from '..';
import { folderUsersPath, folderInvitesPath, encodeEmail } from 'database/Config';
import { IFolderDoc } from 'database/folders';
import moment from 'moment';
import { IUser } from 'database/users';

type CreateInviteOptions = {
    folder: IFolderDoc;
    user: IUser;
};

type CreateInviteResult = {
    invite?: IInviteDoc;
    error?: FriendlyError;
};

export const createInvite = async (database: firebase.database.Database, { user, folder }: CreateInviteOptions): Promise<CreateInviteResult> => {
    try {
        if (!folder.id || !user.email) throw new Error('Create failed invalid params');

        const invite: IInvite = {
            folderName: folder.name,
            toEmail: encodeEmail(user.email),
            expiry: moment().add(48, 'hours').valueOf(),
            owner: folder.owner,
        };

        const inviteID = database.ref(folderInvitesPath({ folderID: folder.id })).push().key;

        if (!inviteID) throw new Error('Unable to get an inviteID');

        const updates = {
            [`${folderUsersPath({ folderID: folder.id, email: user.email })}/latestInviteID`]: inviteID,
            [folderInvitesPath({ folderID: folder.id, inviteID })]: invite,
        };

        await database.ref().update(updates);

        return { invite: { ...invite, id: inviteID } as IInviteDoc };
    } catch (error) {
        return { error: new FriendlyError(InviteActionError.CREATE_FAILED, null, error) };
    }
};

export default createInvite;
