import { useMediaQuery, useTheme } from '@material-ui/core';

type UseDeviceType = () => {
    isDesktop: boolean;
    isTablet: boolean;
    isMobile: boolean;
};

export const useDeviceType: UseDeviceType = () => {
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.down('md') && theme.breakpoints.up('sm'));

    return {
        isDesktop,
        isTablet,
        isMobile,
    };
};

export default useDeviceType;
