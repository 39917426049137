import { IUserComparatorParams, IUserDoc, UserComparator } from 'database';
import { useEffect, useState } from 'react';

type UseUserComparator = (users: Array<IUserDoc>, params?: IUserComparatorParams) => Array<IUserDoc>;

export const useUserComparator: UseUserComparator = (users, params) => {
    const [filteredUsers, setFilteredUsers] = useState(users);

    useEffect(() => {
        if (params) {
            let filteredMaps = users.filter((user) => UserComparator.includeMap(user, params));
            setFilteredUsers(filteredMaps);
        } else setFilteredUsers(users);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, users]);

    return filteredUsers;
};

export default useUserComparator;
