import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useDatabase, useUser } from 'reactfire';
import { createFolder, IFolderDoc, IFolder } from 'database';
import firebase from 'firebase';

interface IUseCreateFolder {
    running: boolean;
    create: (newFolder: IFolder) => Promise<IFolderDoc | void>;
}

export default function useCreateFolder(): IUseCreateFolder {
    const database = useDatabase();
    const {
        data: { uid, displayName, email },
    } = useUser<firebase.User>();
    const [running, setRunning] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const create = async (newFolder: IFolder) => {
        setRunning(true);

        if (uid && displayName && email) {
            return await createFolder(database, { uid, name: displayName, email }, newFolder)
                .then((folder) => {
                    enqueueSnackbar(`Folder ${folder.name} Created`, { variant: 'success' });
                    return folder;
                })
                .catch(() => {
                    enqueueSnackbar('Unable to create folder', { variant: 'error' });
                })
                .finally(() => setRunning(false));
        } else {
            enqueueSnackbar('Unable to create folder', { variant: 'error' });
        }
    };

    return {
        create,
        running,
    };
}
