import { Box, CssBaseline, Grid, makeStyles, Paper } from '@material-ui/core';
import React from 'react';
import { useNavigate, useParams } from 'react-router';
import PageMeta from '../../../../components/page/PageMeta';
import Copyright from 'components/copyright/Copyright';
import { InviteForm } from './components/InviteForm';
import useAcceptInvite from './hooks/useAcceptInvite';
import { IInviteDoc } from 'database';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage:
            'url(https://images.unsplash.com/photo-1527489377706-5bf97e608852?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2027&q=80)',
        backgroundRepeat: 'no-repeat',
        backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
    avatar: {
        margin: theme.spacing(1),
    },
    inviteSection: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        backgroundColor: theme.palette.background.default,
    },
    form: {
        width: '100%',
        maxWidth: theme.breakpoints.width('sm'),
    },
}));

export const InviteRoute: React.FunctionComponent = () => {
    const { folderID, inviteID } = useParams();
    const classes = useStyles();
    const { acceptInvite } = useAcceptInvite(folderID);
    const navigate = useNavigate();

    const accept = async (invite: IInviteDoc) => {
        const { error } = await acceptInvite(invite);
        if (!error) navigate('/', { replace: true });
    };

    return (
        <React.Fragment>
            <PageMeta title="Invite" />

            <Grid container component="main" className={classes.root}>
                <CssBaseline />

                <Grid item sm={false} md={7} className={classes.image} />

                <Grid className={classes.inviteSection} item xs={12} sm={12} md={5} component={Paper} elevation={6} square>
                    <div className={classes.paper}>
                        <Box className={classes.avatar}>
                            <img height="80" width="80" alt="Mapper" src="/logo.svg" />
                        </Box>

                        <Box className={classes.form} mt={2}>
                            <InviteForm folderID={folderID} inviteID={inviteID} onSubmit={accept} />
                        </Box>

                        <Box mt={5}>
                            <Copyright />
                        </Box>
                    </div>
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default InviteRoute;
