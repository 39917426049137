import { assignMap, AssignMapFields, IMapDoc, returnMap, ReturnMapFields } from 'database';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useDatabase } from 'reactfire';
import useUser from 'views/folders/folder/users/hooks/useUser';
import useUsers from 'views/folders/folder/users/hooks/useUsers';

type UseMapActions = {
    running: boolean;
    returnMap: (fields: ReturnMapFields) => Promise<void>;
    assignMap: (fields: AssignMapFields) => Promise<void>;
};

export const useMapActions = (folderID: string, map: IMapDoc): UseMapActions => {
    const [running, setRunning] = useState(false);
    const database = useDatabase();
    const { enqueueSnackbar } = useSnackbar();
    const user = useUser(folderID);
    const users = useUsers(folderID);

    const handleReturnMap = async (fields: ReturnMapFields) => {
        setRunning(true);
        await returnMap(database, folderID, map, user, fields)
            .then(() => enqueueSnackbar(`Map ${map.name} Returned`, { variant: 'success' }))
            .catch((error) => {
                enqueueSnackbar(error.message, { variant: 'error' });
            })
            .finally(() => setRunning(false));
    };

    const handleAssign = async (fields: AssignMapFields) => {
        setRunning(true);
        await assignMap(database, folderID, map.id, user, users, fields)
            .then(() => enqueueSnackbar(`Map ${map.name} Assigned`, { variant: 'success' }))
            .catch((error) => {
                enqueueSnackbar(error.message, { variant: 'error' });
            })
            .finally(() => setRunning(false));
    };

    return {
        running,
        returnMap: handleReturnMap,
        assignMap: handleAssign,
    };
};

export default useMapActions;
