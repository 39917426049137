import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    ListItemIcon,
    MenuItem,
    Typography,
} from '@material-ui/core';
import React from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { UserRole } from 'database';
import { UserSchema } from 'database/users/Schemas';
import AdminIcon from '@material-ui/icons/VerifiedUserOutlined';
import OverseerIcon from '@material-ui/icons/SupervisedUserCircleOutlined';
import AssigneeIcon from '@material-ui/icons/AssignmentIndOutlined';

type Props = {
    open: boolean;
    onClose: () => void;
    onSubmit: (fields: UserFormFields) => Promise<void>;
    initialValues?: UserFormFields;
    options: {
        title: string;
        description: string;
        submitLabel: string;
    };
};

export class UserFormFields {
    role: UserRole = UserRole.OVERSEER;
    name: string = '';
    email: string = '';
}

const roles = [
    {
        role: UserRole.ADMIN,
        label: 'Admin',
        icon: <AdminIcon />,
    },
    {
        role: UserRole.OVERSEER,
        label: 'Overseer',
        icon: <OverseerIcon />,
    },
    {
        role: UserRole.ASSIGNEE,
        label: 'Assignee',
        icon: <AssigneeIcon />,
    },
];

const UserFormDialog: React.FunctionComponent<Props> = ({ open, onClose, onSubmit, initialValues = new UserFormFields(), options }) => {
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={UserSchema}
            onSubmit={(values, { setSubmitting }) => {
                onSubmit(values).finally(() => setSubmitting(false));
            }}
        >
            {({ submitForm, isSubmitting }) => (
                <Dialog fullWidth={true} maxWidth={'xs'} open={open} onClose={() => onClose()} aria-labelledby="form-dialog-title">
                    <DialogTitle>{options.title}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>{options.description}</DialogContentText>
                        <Form autoComplete="off">
                            <Box pt={2} mb={2}>
                                <Field
                                    disabled={initialValues.role === UserRole.OWNER}
                                    required
                                    fullWidth
                                    component={TextField}
                                    type="text"
                                    name="role"
                                    label="Role"
                                    select
                                    variant="outlined"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                >
                                    {initialValues.role === UserRole.OWNER ? (
                                        <MenuItem value={UserRole.OWNER}>Owner</MenuItem>
                                    ) : (
                                        roles.map((option) => (
                                            <MenuItem key={option.role} value={option.role}>
                                                <ListItemIcon>{option.icon}</ListItemIcon>
                                                <Typography variant="inherit" noWrap>
                                                    {option.label}
                                                </Typography>
                                            </MenuItem>
                                        ))
                                    )}
                                </Field>
                            </Box>
                            <Box mb={2}>
                                <Field fullWidth required component={TextField} variant="outlined" name="name" type="text" label="Name" />
                            </Box>

                            <Box mb={2}>
                                <Field fullWidth component={TextField} variant="outlined" name="email" type="email" label="Email" />
                            </Box>
                        </Form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => onClose()} variant="outlined">
                            Cancel
                        </Button>
                        <Button type="submit" disabled={isSubmitting} onClick={submitForm} variant="contained" color="primary">
                            {options.submitLabel}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Formik>
    );
};

export default UserFormDialog;
