import React, { Suspense } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import SignInForm, { SignInFormLoading } from './components/SignInForm';
import { Box } from '@material-ui/core';
import Copyright from '../../components/copyright/Copyright';

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100vh',
    },
    image: {
        backgroundImage:
            'url(https://images.unsplash.com/photo-1527489377706-5bf97e608852?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2027&q=80)',
        backgroundRepeat: 'no-repeat',
        backgroundColor: theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    paper: {
        margin: theme.spacing(8, 4),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
    },
    avatar: {
        margin: theme.spacing(1),
    },
    signInSection: {
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        backgroundColor: theme.palette.background.default,
    },
    form: {
        width: '100%',
        maxWidth: theme.breakpoints.width('sm'),
    },
}));

export const SignIn: React.FunctionComponent = () => {
    const classes = useStyles();

    return (
        <Grid container component="main" className={classes.root}>
            <CssBaseline />

            <Grid item sm={false} md={7} className={classes.image} />

            <Grid className={classes.signInSection} item xs={12} sm={12} md={5} component={Paper} elevation={6} square>
                <div className={classes.paper}>
                    <Box className={classes.avatar}>
                        <img height="80" width="80" alt="Mapper" src="/logo.svg" />
                    </Box>

                    <Box className={classes.form} mt={2}>
                        <Suspense fallback={<SignInFormLoading />}>
                            <SignInForm />
                        </Suspense>
                    </Box>

                    <Box mt={5}>
                        <Copyright />
                    </Box>
                </div>
            </Grid>
        </Grid>
    );
};

export default SignIn;
