import { mapDetailsPath, mapsPath, sharedMapsPath } from 'database/Config';
import { IMapDoc } from '../Map';
import firebase from 'firebase';

export const deleteMap = async (database: firebase.database.Database, folderID: string, map: IMapDoc): Promise<void> => {
    if (!folderID || !map) throw new Error('Unable to delete map invalid params');

    const sharedWith = [...Object.keys(map.assignees || {}), ...Object.keys(map.overseers || {})];

    const mapID = map.id;

    const updates: { [path: string]: null } = {
        [mapsPath({ folderID, mapID })]: null,
        [mapDetailsPath({ folderID, mapID })]: null,
    };

    for (const userID of sharedWith) {
        updates[sharedMapsPath({ folderID, userID, mapID })] = null;
    }

    await database.ref().update(updates);
};

export default deleteMap;
