import React, { Suspense } from 'react';
import { Box, Button, createStyles, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from '@material-ui/core';
import { IMapDoc } from 'database';
import useMapActions from '../hooks/useMapActions';
import { Field, Formik, FormikHelpers } from 'formik';
import { DatePicker, KeyboardDatePicker } from 'formik-material-ui-pickers';
import moment from 'moment';
import useDeviceType from 'hooks/useDeviceType';
import { withErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/fallbacks/ErrorFallback';

const useStyles = makeStyles((theme) =>
    createStyles({
        content: {
            padding: theme.spacing(2),
            paddingTop: 0,
            paddingBottom: 0,
            [theme.breakpoints.up('sm')]: {
                padding: theme.spacing(2),
                paddingLeft: theme.spacing(3),
                paddingRight: theme.spacing(3),
                minWidth: 360,
            },
        },
    })
);

type Props = {
    map: IMapDoc;
    open: boolean;
    onClose: () => void;
    folderID: string;
};

type InitialValues = {
    date: moment.Moment;
};

export const ReturnMapDialogComponent: React.FunctionComponent<Props> = ({ folderID, map, open, onClose }) => {
    const { returnMap } = useMapActions(folderID, map);
    const classes = useStyles();
    const { isDesktop } = useDeviceType();
    const initialValues: InitialValues = { date: moment() };

    const submit = ({ date }: InitialValues, { resetForm }: FormikHelpers<InitialValues>) => {
        returnMap({ dateReturned: moment(date).toISOString() }).finally(() => {
            resetForm();
            onClose();
        });
    };

    return (
        <Formik initialValues={initialValues} onSubmit={submit}>
            {({ submitForm, resetForm, isSubmitting }) => (
                <Dialog open={open} aria-labelledby="map-action-dialog-title">
                    <DialogTitle id="map-action-dialog-title">Date Returned</DialogTitle>
                    <DialogContent className={classes.content}>
                        <Box mb={2}>
                            <Field
                                disabled={isSubmitting}
                                format={isDesktop ? 'DD/MM/yyyy' : 'LL'}
                                component={isDesktop ? KeyboardDatePicker : DatePicker}
                                variant={isDesktop ? 'inline' : 'static'}
                                fullWidth
                                name="date"
                                label="Date Returned"
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={isSubmitting}
                            onClick={() => {
                                resetForm();
                                onClose();
                            }}
                            variant="outlined"
                        >
                            Cancel
                        </Button>
                        <Button disabled={isSubmitting} type="submit" onClick={submitForm} variant="contained" color="primary">
                            Return Map
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Formik>
    );
};

const ReturnMapDialogWithSuspense: React.FunctionComponent<Props> = (props) => {
    return (
        <Suspense fallback={null}>
            <ReturnMapDialogComponent {...props} />
        </Suspense>
    );
};

export const ReturnMapDialog = withErrorBoundary(ReturnMapDialogWithSuspense, { FallbackComponent: ErrorFallback });

export default ReturnMapDialog;
