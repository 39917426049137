import { IMapDoc } from 'database';
import { deleteBoundary } from 'database/maps/actions/deleteBoundary';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useDatabase } from 'reactfire';

type UseDeleteMap = {
    running: boolean;
    removeBoundary: (map: IMapDoc) => Promise<void>;
};

export const useRemoveMapBoundary = (folderID: string): UseDeleteMap => {
    const [running, setRunning] = useState(false);
    const database = useDatabase();
    const { enqueueSnackbar } = useSnackbar();

    const deleteAction = async (map: IMapDoc) => {
        setRunning(true);
        await deleteBoundary(database, folderID, map.id)
            .then(() => enqueueSnackbar(`Boundary removed from ${map.name}`, { variant: 'info' }))
            .catch((error) => {
                console.error(error);
                enqueueSnackbar(error.message, { variant: 'error' });
            })
            .finally(() => setRunning(false));
    };

    return {
        removeBoundary: deleteAction,
        running,
    };
};

export default useRemoveMapBoundary;
