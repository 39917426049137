import { Button, InputAdornment, TextField } from '@material-ui/core';
import { ChangeEvent, useState } from 'react';
import RefreshIcon from '@material-ui/icons/RefreshOutlined';
import tinycolor from 'tinycolor2';

type Props = {
    disabled?: boolean;
    color: string;
    label: string;
    variant?: 'outlined' | 'filled';
    onChange: (color: string) => void;
};

export const ColorPicker: React.FunctionComponent<Props> = ({ color, label, variant = 'outlined', onChange, disabled }) => {
    const [error, setError] = useState<boolean>(false);
    const [value, setValue] = useState<string>(color);

    const onChanged = (event: ChangeEvent<HTMLInputElement>) => {
        if (!disabled && event && event.target) {
            let value = event.target.value as string;
            value = `#${value.replace(/#/g, '')}`;
            const newValue = value.length > 7 ? value.substring(0, 7) : value;

            if (newValue.match('^#(?:[0-9a-fA-F]{3}){1,2}$')) {
                setError(false);
                onChange(value);
            } else {
                setError(true);
            }
            setValue(newValue);
        }
    };

    const randomColor = () => {
        if (disabled) return;
        const randomColor = tinycolor.random().toHexString();
        setValue(randomColor);
        onChange(randomColor);
    };

    const contrastColor = tinycolor.mostReadable(tinycolor(value), [tinycolor(value).lighten(50), tinycolor(value).darken(50)]).toHexString();

    return (
        <TextField
            disabled={disabled}
            value={value}
            error={Boolean(error)}
            fullWidth
            label={label}
            id="filled-start-adornment"
            InputProps={{
                endAdornment: (
                    <InputAdornment position="start">
                        <Button
                            style={{
                                backgroundColor: tinycolor(value).setAlpha(0.7).toRgbString(),
                                color: contrastColor,
                                border: `1px solid ${tinycolor(value).brighten().setAlpha(1).toRgbString()}`,
                            }}
                            variant="outlined"
                            onClick={randomColor}
                        >
                            <RefreshIcon />
                        </Button>
                    </InputAdornment>
                ),
            }}
            onChange={onChanged}
            variant={variant}
        />
    );
};

export default ColorPicker;
