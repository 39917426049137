import { Box, Container, createStyles, Fab, InputAdornment, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import React, { ChangeEvent, Suspense, useState } from 'react';
import PageMeta from '../../../../components/page/PageMeta';
import SearchIcon from '@material-ui/icons/Search';
import UsersList from './components/UsersList';
import AddIcon from '@material-ui/icons/Add';
import UserFormDialog, { UserFormFields } from './components/UserFormDialog';
import { useParams } from 'react-router-dom';
import FolderBreadcrumbs from '../components/FolderBreadcrumbs';
import useAddUser from './hooks/useAddUser';
import { useDebounce } from '@react-hook/debounce';
import { IUserComparatorParams } from 'database';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        extendedIcon: {
            marginRight: theme.spacing(1),
        },
        searchContainer: {
            [theme.breakpoints.up('xs')]: {
                marginRight: theme.spacing(3),
            },
        },
        searchInput: {
            maxWidth: theme.breakpoints.width('sm'),
        },
    })
);

const addUserDialogOptions = {
    title: 'Add user',
    description: 'Enter the details of the new user. A invitation will be sent to the entered Email Address',
    submitLabel: 'Add user',
};

export const Users: React.FunctionComponent = () => {
    const classes = useStyles();
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const { folderID } = useParams();
    const { addUser } = useAddUser(folderID);
    const [comparator, setComparator] = useDebounce<IUserComparatorParams>({ searchTerm: '' }, 150);

    const handleAddUser = async (fields: UserFormFields) => {
        await addUser(fields).then(() => setDialogOpen(false));
    };

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setComparator({ ...comparator, searchTerm: event.target.value });
    };

    return (
        <React.Fragment>
            <PageMeta title="Users" />
            <Container>
                <Box mt={3} mb={2}>
                    <FolderBreadcrumbs folderID={folderID}>
                        <Typography variant="h6">Users</Typography>
                    </FolderBreadcrumbs>
                </Box>

                <Box display="flex" flexWrap="wrap-reverse" alignItems="center">
                    <Box className={classes.searchContainer} mb={3} flexGrow={1}>
                        <TextField
                            className={classes.searchInput}
                            fullWidth={true}
                            label="Search"
                            type="search"
                            variant="outlined"
                            onChange={handleSearch}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Box mb={3}>
                        <Fab onClick={() => setDialogOpen(true)} color="primary" variant="extended">
                            <AddIcon className={classes.extendedIcon} />
                            Add User
                        </Fab>
                    </Box>
                </Box>

                <Suspense fallback={<p>loading Users...</p>}>
                    <UsersList comparator={comparator} folderID={folderID} />
                </Suspense>

                <UserFormDialog onSubmit={handleAddUser} onClose={() => setDialogOpen(false)} open={dialogOpen} options={addUserDialogOptions} />
            </Container>
        </React.Fragment>
    );
};

export default Users;
