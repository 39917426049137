import { Chip } from '@material-ui/core';
import tinycolor from 'tinycolor2';

type Props = {
    label: string;
    color: string;
    onDelete?: () => void;
};

export const Tag: React.FunctionComponent<Props> = ({ label, color, onDelete }) => {
    const tagColor = tinycolor(color);
    const contrastColor = tinycolor.mostReadable(tinycolor(color), [tinycolor(color).lighten(50), tinycolor(color).darken(50)]).toHexString();

    return (
        <Chip
            onDelete={onDelete}
            label={label}
            style={{
                fontWeight: 600,
                backgroundColor: tinycolor(tagColor).setAlpha(0.7).toRgbString(),
                color: contrastColor,
                border: `1px solid ${tinycolor(color).brighten().setAlpha(1).toRgbString()}`,
            }}
        />
    );
};

export default Tag;
