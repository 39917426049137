import { useDatabase, useDatabaseListData } from 'reactfire';
import { mapBoundaryPath } from 'database';
import { IMapBoundaryDoc } from 'database/maps/MapBoundary';

export default function useMapBoundaries(folderID: string): Array<IMapBoundaryDoc> {
    const mapsRef = useDatabase().ref(mapBoundaryPath({ folderID }));
    const { data: boundaries } = useDatabaseListData<IMapBoundaryDoc>(mapsRef, { idField: 'id' });

    return boundaries.filter((b) => b.data);
}
