import { List } from '@material-ui/core';
import React from 'react';
import useMapHistory from '../hooks/useMapHistory';
import MapHistoryListItem from './MapHistoryListItem';

type Props = {
    className?: string;
    folderID: string;
    mapID: string;
};

export const MapHistoryList: React.FunctionComponent<Props> = ({ className, folderID, mapID }) => {
    const history = useMapHistory(folderID, mapID);

    return (
        <List className={className}>
            {history.map((item, index) => (
                <MapHistoryListItem key={index} history={item} />
            ))}
        </List>
    );
};

export default MapHistoryList;
