import { useEffect } from 'react';

import React from 'react';
type Props = {
    error: Error;
    errorMsg?: string;
};

export const ErrorFallback: React.FunctionComponent<Props> = ({ errorMsg, error }) => {
    useEffect(() => {
        if (errorMsg) console.error(errorMsg);
        if (error) console.error(error);
    }, [errorMsg, error]);

    return null;
};

export default ErrorFallback;
