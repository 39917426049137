import FriendlyError from 'core/FriendlyError';
import { mapHistoryPath, mapsPath, sharedMapsPath } from 'database/Config';
import { IMapHistory } from 'database';
import { fetchUsers, IUser } from 'database/users';
import { IMapDoc } from '../Map';
import firebase from 'firebase';

export type ReturnMapFields = {
    dateReturned: string;
};

export const returnMap = async (
    database: firebase.database.Database,
    folderID: string,
    map: IMapDoc,
    userPerformingAction: IUser,
    fields: ReturnMapFields
) => {
    try {
        if (folderID && map.id && fields.dateReturned) {
            const newFields: Partial<IMapDoc> = { returned: true, dateReturned: fields.dateReturned, assignees: {}, dateAssigned: null };

            const history: IMapHistory = {
                type: 'RETURNED',
                createdBy: userPerformingAction.name,
                date: fields.dateReturned,
            };
            const updates: Record<string, any> = {};

            Object.keys(newFields).forEach((field) => {
                updates[`${mapsPath({ folderID, mapID: map.id })}/${field}`] = (newFields as any)[field];
            });

            const { users } = await fetchUsers(database, folderID);

            if (!users) throw new Error('Unable to fetch users');

            // Unshare with assignees
            for (const userID of Object.keys(map.assignees || {})) {
                const user = users[userID];
                if (user.email) {
                    updates[sharedMapsPath({ folderID, userID, mapID: map.id })] = null;
                }
            }

            await database.ref().update(updates);
            await database.ref(mapHistoryPath({ folderID, mapID: map.id })).push(history);
        } else throw new Error('Unable to return map invalid args');
    } catch (error) {
        throw new FriendlyError('Unable to return map', null, error);
    }
};

export default returnMap;
