import React, { useEffect, useRef, useState } from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Box, CircularProgress } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { createInvite } from 'database/invites';
import { useDatabase } from 'reactfire';
import useFolder from '../hooks/useFolder';
import Config from 'Config';
import { withErrorBoundary } from 'react-error-boundary';
import { IUser } from 'database';
import useInvite from '../invites/hooks/useInvite';

type Props = {
    open: boolean;
    onClose: () => void;
    folderID: string;
    user: IUser;
};

type InviteInputProps = {
    inputRef: React.MutableRefObject<HTMLInputElement | null>;
    link: string;
};

const InviteInput: React.FunctionComponent<InviteInputProps> = ({ inputRef, link }) => {
    return (
        <TextField
            style={{ userSelect: 'text' }}
            inputRef={inputRef}
            fullWidth
            id="invite-url"
            multiline
            rowsMax={3}
            variant="outlined"
            value={link}
        ></TextField>
    );
};

const InviteInputLoading = () => {
    return (
        <Box p={1} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <CircularProgress /> <br />
            <Typography variant="body2" color="textSecondary">
                Creating Invite
            </Typography>
        </Box>
    );
};

export const DialogComponent: React.FunctionComponent<Props> = ({ open, onClose, folderID, user }) => {
    const database = useDatabase();
    const folder = useFolder(folderID);
    const inviteRef = useRef<HTMLInputElement | null>(null);
    const invite = useInvite(folderID, user.latestInviteID);
    const [link, setLink] = useState<string | null>(null);
    const [error, setError] = useState<string | undefined>();

    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        if (!open) return;

        if (invite) {
            setLink(Config.getInviteURL(folder.id, invite.id));
        } else {
            createInvite(database, { folder, user }).then((result) => {
                if (result.invite) {
                    setLink(Config.getInviteURL(folder.id, result.invite.id));
                } else if (result.error) {
                    setError(result.error.message);
                }
            });
        }
    }, [open, database, folder, user, invite]);

    const copy = () => {
        if (inviteRef && inviteRef.current) {
            inviteRef.current.select();
            document.execCommand('copy');
            enqueueSnackbar('Invite copied to clipboard', { variant: 'success' });
        }
    };

    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="invite-dialog-title" aria-describedby="invite-dialog-description">
            <DialogTitle id="invite-dialog-title">Invite Link</DialogTitle>
            <DialogContent>
                {Boolean(error) ? (
                    <Typography>{error}</Typography>
                ) : (
                    <Box mb={1}>{link ? <InviteInput link={link} inputRef={inviteRef}></InviteInput> : <InviteInputLoading />}</Box>
                )}

                <Typography color="textSecondary">
                    <small>Invite Links will automatically expire after 48 hours</small>
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="outlined">
                    Dismiss
                </Button>
                <Button disabled={!link && Boolean(error)} onClick={copy} variant="contained" color="primary">
                    Copy Link
                </Button>
            </DialogActions>
        </Dialog>
    );
};

const FallbackComponent = () => {
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        enqueueSnackbar('Unable to create invite', { variant: 'error' });
    });

    return null;
};

const FolderInviteDialog = withErrorBoundary(DialogComponent, { FallbackComponent });

export default FolderInviteDialog;
