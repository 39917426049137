import { Box, createStyles, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => {
    return createStyles({
        root: {
            width: '100%',
            height: '100%',
            backgroundColor: theme.palette.background.default,
        },
        logoContainer: {
            height: '6rem',
            width: '6rem',
        },
        logo: {
            height: '100%',
            width: '100%',
        },
    });
});

export const SplashScreen: React.FunctionComponent = () => {
    const classes = useStyles();

    return (
        <Box display="flex" justifyContent="center" alignItems="center" className={classes.root}>
            <Box className={classes.logoContainer}>
                <img className={classes.logo} src="/logo.svg" alt="" />
            </Box>
        </Box>
    );
};

export default SplashScreen;
