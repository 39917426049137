import { useState } from 'react';

function useLocalStorage<TValue>(key: string, initialValue?: TValue): [TValue, (value: TValue) => void] {
    // State to store our value
    // Pass initial state function to useState so logic is only executed once
    const [storedValue, setStoredValue] = useState<TValue>(() => {
        try {
            // Get from local storage by key
            const item = window.localStorage.getItem(key);
            if (item === undefined || item === null) return initialValue;
            if (typeof item === 'string') {
                return item;
            } else {
                return JSON.parse(item);
            }
            // Parse stored json or if none return initialValue
        } catch (error) {
            // If error also return initialValue
            console.error(error);
            return initialValue;
        }
    });

    // Return a wrapped version of useState's setter function that ...
    // ... persists the new value to localStorage.
    const setValue = (value: TValue) => {
        try {
            // Allow value to be a function so we have same API as useState
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            // Save state
            setStoredValue(valueToStore);
            // Save to local storage
            window.localStorage.setItem(key, valueToStore && typeof valueToStore !== 'string' ? JSON.stringify(valueToStore) : valueToStore);
        } catch (error) {
            // A more advanced implementation would handle the error case
            console.error(error);
        }
    };

    return [storedValue, setValue];
}

export default useLocalStorage;
