import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import { MapHistoryType } from 'database';
import React from 'react';
import { MapHistoryDetails } from '../hooks/useMapHistory';
import EditIcon from '@material-ui/icons/EditOutlined';
import ReturnedIcon from '@material-ui/icons/AssignmentReturnOutlined';
import AssignedIcon from '@material-ui/icons/AssignmentIndOutlined';

type MapHistoryListItemProps = {
    history: MapHistoryDetails;
};

const ActionIcon: React.FunctionComponent<{ type: MapHistoryType }> = ({ type }) => {
    switch (type) {
        case 'ASSIGNED':
            return <AssignedIcon />;
        case 'RETURNED':
            return <ReturnedIcon />;
        case 'EDITED':
            return <EditIcon />;
        default:
            return null;
    }
};

export const MapHistoryListItem: React.FunctionComponent<MapHistoryListItemProps> = ({ history }) => {
    return (
        <ListItem alignItems="flex-start">
            <ListItemAvatar>
                <Avatar>
                    <ActionIcon type={history.type} />
                </Avatar>
            </ListItemAvatar>
            <ListItemText
                primary={history.title}
                secondary={
                    <React.Fragment>
                        {history.description} <br />
                        <Typography component="span" variant="caption" color="textPrimary">
                            {history.createdBy} - {history.dateFormatted}
                        </Typography>
                    </React.Fragment>
                }
            />
        </ListItem>
    );
};

export default MapHistoryListItem;
