import { Chip, makeStyles } from '@material-ui/core';
import { IMapDoc, MapUtils } from 'database';
import LateIcon from '@material-ui/icons/AlarmOutlined';
import ReturnedIcon from '@material-ui/icons/AssignmentReturnOutlined';
import AssignedIcon from '@material-ui/icons/AssignmentIndOutlined';

type Props = {
    map: IMapDoc;
};

const useStyles = makeStyles({
    root: {
        textTransform: 'capitalize',
    },
});

export const MapAge: React.FunctionComponent<Props> = ({ map }) => {
    const age = MapUtils.getMapAgeFromNow(map);
    let color: 'primary' | 'secondary' | 'default' = 'default';
    let variant = 'outlined';
    let icon = map.returned ? <ReturnedIcon /> : <AssignedIcon />;
    const classes = useStyles();

    //TODO don't hard code overdue when amount
    const isLate = age.days > 186;

    if (isLate) {
        color = 'secondary';
        variant = 'default';
        icon = <LateIcon />;
    }

    return <Chip className={classes.root} size="small" icon={icon} label={age.label} color={color} variant={variant as any} />;
};

export default MapAge;
