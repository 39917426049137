import { Box, createStyles, Divider, List, ListItem, ListItemText, makeStyles, Theme } from '@material-ui/core';
import React, { Suspense } from 'react';
import MapIcon from '@material-ui/icons/TerrainOutlined';
import UsersIcon from '@material-ui/icons/PeopleOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import ListItemLink from '../../../../components/lists/ListItemLink';
import { useMatch } from 'react-router-dom';
import useFolder from '../hooks/useFolder';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        nested: {
            paddingLeft: theme.spacing(2),
        },
    })
);

type Props = {
    folderID: string;
};

export const FolderListItem: React.FunctionComponent<Props> = ({ folderID }) => {
    const classes = useStyles();
    const folder = useFolder(folderID);
    return (
        <Box mt={1} mb={2}>
            <ListItem>
                <ListItemText primary={folder.name} />
            </ListItem>
            <List component="div" className={classes.nested} disablePadding>
                <ListItemLink primary="Dashboard" icon={<DashboardIcon />} to={`/folders/${folder.id}`} />
                <ListItemLink primary="Maps" icon={<MapIcon />} to={`/folders/${folder.id}/maps`} />
                <ListItemLink primary="Users" icon={<UsersIcon />} to={`/folders/${folder.id}/users`} />
                <ListItemLink primary="Settings" icon={<SettingsIcon />} to={`/folders/${folder.id}/settings`} />
            </List>
        </Box>
    );
};

export const FolderNavComponent: React.FunctionComponent = () => {
    const match = useMatch('folders/:folderID/*');

    if (!match) return null;

    return (
        <Box>
            <Divider />
            <FolderListItem folderID={match?.params.folderID} />
            <Divider />
        </Box>
    );
};

const Fallback = () => {
    return null;
};

export const FolderNav: React.FunctionComponent = () => {
    return (
        <Suspense fallback={<Fallback />}>
            <FolderNavComponent />
        </Suspense>
    );
};

export default FolderNav;
