import { IMapDoc, IMapComparatorParams, MapComparator } from 'database';
import { useEffect, useState } from 'react';
import useUsers from '../../users/hooks/useUsers';

type UseMapComparator = (folderID: string, maps: Array<IMapDoc>, params?: IMapComparatorParams) => Array<IMapDoc>;

export const useMapComparator: UseMapComparator = (folderID: string, maps, params) => {
    const users = useUsers(folderID);
    const [filteredMaps, setFilteredMaps] = useState(maps);

    useEffect(() => {
        if (params) {
            let filteredMaps = maps.filter((map) => MapComparator.includeMap(map, params, users));

            if (params.sort.field) filteredMaps = MapComparator.sortMaps(filteredMaps, params.sort.field, params.sort.reverse);
            setFilteredMaps(filteredMaps);
        } else setFilteredMaps(maps);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, maps]);

    return filteredMaps;
};

export default useMapComparator;
