import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export type ConfirmDialogOptions = {
    title: string;
    description: string;
    confirmLabel: string;
};

type Props = {
    open: boolean;
    onClose: () => void;
    onSubmit: () => void;
    options: ConfirmDialogOptions;
};

export const ConfirmDialog: React.FunctionComponent<Props> = ({ open, options, onClose, onSubmit }) => {
    return (
        <Dialog open={open} onClose={onClose} aria-labelledby="confirm-dialog-title" aria-describedby="confirm-dialog-description">
            <DialogTitle id="confirm-dialog-title">{options.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="confirm-dialog-description">{options.description}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="outlined">
                    Cancel
                </Button>
                <Button onClick={onSubmit} variant="contained" color="primary">
                    {options.confirmLabel}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmDialog;
