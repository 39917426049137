import { Typography } from '@material-ui/core';
import React, { Suspense } from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import { useNavigate } from 'react-router-dom';
import useTags from 'views/folders/folder/tags/hooks/useTags';
import useUsersList from 'views/folders/folder/users/hooks/useUsersList';
import useMap from '../../map/hooks/useMap';
import useMapDetails from '../../map/hooks/useMapDetails';
import useUpdateMap from '../../map/hooks/useUpdateMap';
import { MapForm } from './MapForm';
import MapFormFields from './MapFormFields';

interface IEditMapFormProps {
    folderID: string;
    mapID: string;
}

const EditMapFormComponent: React.FunctionComponent<IEditMapFormProps> = ({ folderID, mapID }) => {
    const map = useMap(folderID, mapID);
    const mapDetails = useMapDetails(folderID, mapID);
    const users = useUsersList(folderID);
    const tags = useTags(folderID);
    const { updateMap, running } = useUpdateMap(folderID);
    const navigate = useNavigate();

    const save = async (mapFields: MapFormFields) => {
        const newFields = MapFormFields.getMap(mapFields);
        const mapDetails = MapFormFields.getMapDetails(mapFields);

        if (!running) await updateMap(map, newFields, mapDetails);
        navigate(-1);
    };

    const values = new MapFormFields(map, mapDetails, users, tags);

    return (
        <React.Fragment>
            <MapForm folderID={folderID} initialValues={values} onSubmit={save} />
        </React.Fragment>
    );
};

const EditMapFormWithSuspense: React.FunctionComponent<IEditMapFormProps> = (props) => {
    return (
        <Suspense fallback={null}>
            <EditMapFormComponent {...props} />
        </Suspense>
    );
};

const FallbackComponent: React.FunctionComponent = () => {
    return <Typography>Unable To Load Map Form</Typography>;
};

export const EditMapForm = withErrorBoundary(EditMapFormWithSuspense, { FallbackComponent });

export default EditMapForm;
