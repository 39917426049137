import React, { Suspense } from 'react';
import { Box, Button, createStyles, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, makeStyles } from '@material-ui/core';
import { IMapDoc, AssignMapSchema, IUserDoc } from 'database';
import useMapActions from '../hooks/useMapActions';
import { Field, Formik, FormikHelpers } from 'formik';
import SelectUserField from 'views/folders/folder/users/components/SelectUserField';
import { KeyboardDatePicker, DatePicker } from 'formik-material-ui-pickers';
import moment from 'moment';
import { Utils } from 'Utils';
import useDeviceType from 'hooks/useDeviceType';
import { withErrorBoundary } from 'react-error-boundary';
import ErrorFallback from 'components/fallbacks/ErrorFallback';

const useStyles = makeStyles((theme) =>
    createStyles({
        content: {
            [theme.breakpoints.up('sm')]: {
                paddingLeft: theme.spacing(3),
                paddingRight: theme.spacing(3),
                minWidth: 360,
            },
        },
    })
);

type Props = {
    map: IMapDoc;
    open: boolean;
    onClose: () => void;
    folderID: string;
};

type InitialValues = {
    assignees: Array<IUserDoc>;
    dateAssigned: moment.Moment;
};

export const AssignMapDialogComponent: React.FunctionComponent<Props> = ({ folderID, map, open, onClose }) => {
    const { assignMap } = useMapActions(folderID, map);
    const { isDesktop } = useDeviceType();
    const classes = useStyles();
    const initialValues: InitialValues = { assignees: [], dateAssigned: moment() };

    const submit = ({ assignees, dateAssigned }: InitialValues, { resetForm }: FormikHelpers<InitialValues>) => {
        assignMap({
            dateAssigned: moment(dateAssigned).toISOString(),
            assignees: Utils.docsToRecord(
                assignees,
                (doc) => doc.id,
                (_doc) => true
            ),
        }).finally(() => {
            resetForm();
            onClose();
        });
    };

    return (
        <Formik validationSchema={AssignMapSchema} initialValues={initialValues} onSubmit={submit}>
            {({ submitForm, resetForm, isSubmitting, errors, touched, isValid }) => (
                <Dialog open={open} aria-labelledby="map-action-dialog-title">
                    <DialogTitle id="map-action-dialog-title">Assign Map</DialogTitle>
                    <DialogContent className={classes.content}>
                        <DialogContentText>
                            Select which users to assign to the map <br /> {map.name}
                        </DialogContentText>
                        <Box pt={2} mb={2}>
                            <Field
                                autoOk
                                disabled={isSubmitting}
                                format={isDesktop ? 'DD/MM/yyyy' : 'LL'}
                                component={isDesktop ? KeyboardDatePicker : DatePicker}
                                variant={isDesktop ? 'inline' : 'dialog'}
                                fullWidth
                                inputVariant="outlined"
                                name="dateAssigned"
                                label="Date Assigned"
                            />
                        </Box>

                        <Box mb={2}>
                            <SelectUserField
                                required
                                name="assignees"
                                folderID={folderID}
                                label="Select Assignees"
                                disabled={isSubmitting}
                                error={Array.isArray(errors['assignees']) ? '' : touched['assignees'] ? errors['assignees'] : ''}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            disabled={isSubmitting}
                            onClick={() => {
                                resetForm();
                                onClose();
                            }}
                            variant="outlined"
                        >
                            Cancel
                        </Button>
                        <Button disabled={isSubmitting} type="submit" onClick={submitForm} variant="contained" color="primary">
                            Assign Map
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Formik>
    );
};

const AssignMapDialogWithSuspense: React.FunctionComponent<Props> = (props) => {
    return (
        <Suspense fallback={null}>
            <AssignMapDialogComponent {...props} />
        </Suspense>
    );
};

export const AssigmMapDialog = withErrorBoundary(AssignMapDialogWithSuspense, { FallbackComponent: ErrorFallback });

export default AssigmMapDialog;
