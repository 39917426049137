import { useState } from 'react';
import { useDatabase } from 'reactfire';
import { acceptInvite, IInviteDoc } from 'database';
import { useSnackbar } from 'notistack';
import FriendlyError from 'core/FriendlyError';

type AcceptInviteResult = { error?: FriendlyError };

type UseAcceptInvite = {
    running: boolean;
    acceptInvite: (invite: IInviteDoc) => Promise<AcceptInviteResult>;
};

export const useAcceptInvite = (folderID: string): UseAcceptInvite => {
    const database = useDatabase();
    const [running, setRunning] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const accept = async (invite: IInviteDoc) => {
        setRunning(true);
        const result: AcceptInviteResult = {};
        await acceptInvite(database, folderID, invite)
            .catch((error) => {
                enqueueSnackbar(error.message, { variant: 'error' });
                result.error = error;
            })
            .finally(() => setRunning(false));

        return result;
    };

    return {
        acceptInvite: accept,
        running,
    };
};

export default useAcceptInvite;
