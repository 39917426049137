import { IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, Typography } from '@material-ui/core';
import { DeleteForever } from '@material-ui/icons';

import TextFieldDialog, { TextFieldDialogOptions } from 'components/dialogs/TextFieldDialog';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useDatabase } from 'reactfire';
import useFolder from '../../hooks/useFolder';
import { deleteAllTags } from 'database';
import { useSnackbar } from 'notistack';

const ConfirmDeleteDialogOptions: TextFieldDialogOptions = {
    initialValue: '',
    label: 'Enter Folder Name',
    title: 'Confirm',
    confirmLabel: 'Delete Tags',
    description: `Enter the folder name to confirm.
    This action can not be undone.`,
};

export const TagSettings: React.FunctionComponent = () => {
    const [showConfirm, setShowConfirm] = React.useState(false);
    const { folderID } = useParams();

    const database = useDatabase();
    const { enqueueSnackbar } = useSnackbar();

    const folder = useFolder(folderID);

    const deleteAll = async () => {
        await deleteAllTags(database, folderID)
            .catch((error) => {
                enqueueSnackbar(error, { variant: 'error' });
            })
            .then(() => enqueueSnackbar('All tags deleted', { variant: 'info' }));
        setShowConfirm(false);
    };

    return (
        <React.Fragment>
            <Typography variant="subtitle2">Tags</Typography>

            <List dense={true}>
                <ListItem button onClick={() => setShowConfirm(true)}>
                    <ListItemText primary="Delete Tags" secondary={`Delete all tags in the ${folder.name} folder`} />
                    <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="Delete All Maps">
                            <DeleteForever />
                        </IconButton>
                    </ListItemSecondaryAction>
                </ListItem>
            </List>

            <TextFieldDialog
                onSubmit={deleteAll}
                open={showConfirm}
                onClose={() => setShowConfirm(false)}
                options={ConfirmDeleteDialogOptions}
                textToMatch={folder.name}
                matchErrorMsg={`Must match folder name ${folder.name}`}
            ></TextFieldDialog>
        </React.Fragment>
    );
};

export default TagSettings;
