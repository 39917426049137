import { IDoc } from '../Doc';

export interface IFolder {
    name: string;
}

export interface IFolderDoc extends IDoc, IFolder {
    owner: string;
}

export enum FolderRole {
    OWNER = 'owner',
    ADMIN = 'admin',
    OVERSEER = 'overseer',
    ASSIGNEE = 'assignee',
}

export enum FolderAction {
    RENAME = 'Edit',
    DELETE = 'Delete',
    INVITE = 'Invite',
}
