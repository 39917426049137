import { useDatabase, useDatabaseObjectData } from 'reactfire';
import { mapDetailsPath, IMapDetailsDoc } from 'database';

export const useMapDetails = (folderID: string, mapID: string): IMapDetailsDoc => {
    const database = useDatabase();
    const mapRef = database.ref(mapDetailsPath({ folderID, mapID }));
    const { data: mapDetails } = useDatabaseObjectData<IMapDetailsDoc>(mapRef, { idField: 'id' });

    return mapDetails;
};

export default useMapDetails;
