import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useDatabase } from 'reactfire';
import { createTag, ITag } from 'database';

type UseCreateTag = {
    running: boolean;
    createTag: (tag: ITag) => Promise<void>;
};

export default function useCreateTag(folderID: string): UseCreateTag {
    const database = useDatabase();
    const [running, setRunning] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const create = async (tag: ITag) => {
        setRunning(true);

        return await createTag(database, folderID, tag)
            .then((tag) => {
                enqueueSnackbar(`Tag ${tag.name} Created`, { variant: 'success' });
            })
            .catch(() => {
                enqueueSnackbar('Unable to create Tag', { variant: 'error' });
            })
            .finally(() => setRunning(false));
    };

    return {
        createTag: create,
        running,
    };
}
