import FriendlyError from 'core/FriendlyError';
import { mapsPath } from 'database/Config';
import { IMapDoc } from '../Map';
import firebase from 'firebase';

type FetchMapsResult = {
    error?: FriendlyError;
    maps?: Array<IMapDoc>;
};

const fetchMapsError = 'Unable to fetch maps';

export const fetchMaps = async (database: firebase.database.Database, folderID: string): Promise<FetchMapsResult> => {
    try {
        const mapsQuery = database.ref(mapsPath({ folderID })).orderByChild('reference');
        const mapsRef = await mapsQuery.once('value');
        const maps: Array<IMapDoc> = [];
        mapsRef.forEach((doc) => {
            maps.push({ id: doc.key, ...doc.val() });
        });

        return { maps };
    } catch (error) {
        return { error: new FriendlyError(fetchMapsError, null, error) };
    }
};

export default fetchMaps;
