import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useDatabase } from 'reactfire';
import { IFolderDoc, deleteFolder, updateFolder } from 'database';

type UseFolderActions = {
    running: boolean;
    deleteFolder: (folder: IFolderDoc) => Promise<void>;
    updateFolder: (folder: IFolderDoc, fields: Partial<IFolderDoc>) => Promise<void>;
};

export const useFolderActions = (): UseFolderActions => {
    const [running, setRunning] = useState(false);
    const database = useDatabase();
    const { enqueueSnackbar } = useSnackbar();

    const handleDelete = async (folder: IFolderDoc) => {
        setRunning(true);
        await deleteFolder(database, folder)
            .then(() => enqueueSnackbar(`${folder.name} Deleted`, { variant: 'info' }))
            .catch((error) => {
                enqueueSnackbar(error.message, { variant: 'error' });
            })
            .finally(() => setRunning(false));
    };

    const handleUpdate = async (folder: IFolderDoc, fields: Partial<IFolderDoc>) => {
        setRunning(true);
        await updateFolder(database, folder.id, fields)
            .then(() => enqueueSnackbar(`${fields.name || folder.name} Updated`, { variant: 'info' }))
            .catch((error) => {
                enqueueSnackbar(error.message, { variant: 'error' });
            })
            .finally(() => setRunning(false));
    };

    return {
        running,
        deleteFolder: handleDelete,
        updateFolder: handleUpdate,
    };
};

export default useFolderActions;
