import React from 'react';
import { Box, Button, Typography } from '@material-ui/core';
import { Field, Formik } from 'formik';
import { CheckboxWithLabel } from 'formik-material-ui';
import SelectUserField, { SelectUserOption } from 'views/folders/folder/users/components/SelectUserField';
import SelectTagsField, { SelectTagOption } from 'views/folders/folder/tags/components/SelectTagsField';

type Props = {
    onSubmit: () => void;
    folderID: string;
};

class MapFormFilters {
    assigned: boolean = true;
    returned: boolean = true;
    overseers: Array<SelectUserOption> = [];
    assignees: Array<SelectUserOption> = [];
    tags: Array<SelectTagOption> = [];
}

export const MapFilterForm: React.FunctionComponent<Props> = ({ onSubmit, folderID }) => {
    return (
        <Formik initialValues={new MapFormFilters()} onSubmit={onSubmit}>
            {({ resetForm, isSubmitting, submitForm, isValid, isInitialValid }) => (
                <Box pb={4}>
                    <Box pt={2} pb={2} display="flex">
                        <Box flex="1">
                            <Typography variant="h6">Filters</Typography>
                        </Box>

                        <Button type="submit" variant="contained" color="primary" onClick={submitForm}>
                            Apply Filters
                        </Button>
                    </Box>

                    <Typography variant="subtitle2">Map Status</Typography>

                    <Box mb={2}>
                        <Field component={CheckboxWithLabel} type="checkbox" name="returned" Label={{ label: 'Returned' }} />
                        <Field component={CheckboxWithLabel} type="checkbox" name="assigned" Label={{ label: 'Assigned' }} />
                    </Box>

                    <Typography variant="subtitle2">Overseers</Typography>

                    <Box pt={2} mb={2}>
                        <SelectUserField folderID={folderID} label="Selected Overseers" name="overseers" />
                    </Box>

                    <Typography variant="subtitle2">Assignees</Typography>

                    <Box pt={2} mb={2}>
                        <SelectUserField folderID={folderID} label="Selected Assignees" name="assignees" />
                    </Box>

                    <Typography variant="subtitle2">Tags</Typography>

                    <Box pt={2} mb={2}>
                        <SelectTagsField folderID={folderID} label="Selected Tags" name="tags" />
                    </Box>
                </Box>
            )}
        </Formik>
    );
};

export default MapFormFilters;
