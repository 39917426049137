import { Box, Button, LinearProgress, makeStyles, Paper, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { decodeEmail, IInviteDoc } from 'database';
import { Suspense, useState } from 'react';
import useInvite from '../hooks/useInvite';

type Props = {
    inviteID: string;
    folderID: string;
    onSubmit: (invite: IInviteDoc) => Promise<void>;
};

const useStyles = makeStyles(() => ({
    progress: {
        position: 'absolute',
        bottom: 0,
        width: '100%',
    },
}));

const inviteText = (invite: IInviteDoc | null) => {
    if (!invite || invite.expiry < new Date().getTime()) return 'Invite expired';

    return `${decodeEmail(invite?.toEmail)} you have been invited to ${invite.folderName}`;
};

const Form: React.FunctionComponent<Props> = ({ inviteID, folderID, onSubmit }) => {
    const invite = useInvite(folderID, inviteID);
    const [running, setRunning] = useState(false);
    const classes = useStyles();

    const submit = () => {
        setRunning(true);
        if (invite) {
            onSubmit(invite).finally(() => setRunning(false));
        }
    };

    return (
        <Box position="relative">
            <Paper>
                <Box p={3}>
                    {invite && <Typography variant="h5">Welcome</Typography>}
                    <Box mb={invite ? 2 : 0}>
                        <Typography>{inviteText(invite)}</Typography>
                    </Box>

                    {invite && (
                        <Button disabled={running} variant="contained" color="primary" onClick={submit}>
                            Accept Invite
                        </Button>
                    )}
                </Box>

                {running && <LinearProgress className={classes.progress} />}
            </Paper>
        </Box>
    );
};

const LoadingState = () => {
    return (
        <Box>
            <Box mb={1}>
                <Skeleton variant="text" />
            </Box>

            <Box display="flex" justifyContent="center">
                <Skeleton variant="rect" height={156} />
            </Box>
        </Box>
    );
};

export const InviteForm: React.FunctionComponent<Props> = (props) => {
    return (
        <Suspense fallback={<LoadingState />}>
            <Form {...props} />
        </Suspense>
    );
};
