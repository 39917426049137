import FriendlyError from 'core/FriendlyError';
import { encodeEmail, folderUsersPath, sharedMapsPath } from 'database/Config';
import { UserActionError } from '..';
import { IUser, IUserDoc } from '../User';
import firebase from 'firebase';
import { fetchUserMaps } from './fetchUserMaps';

export const updateUser = async (database: firebase.database.Database, folderID: string, user: IUserDoc, fields: Partial<IUser>): Promise<void> => {
    try {
        if (!fields) throw new Error('Unable to update user invalid params');

        const updates: Record<string, Partial<IUser | null>> = {};

        const emailHasChanged = (user.email && encodeEmail(fields.email) !== user.email) || (!user.email && encodeEmail(fields.email));

        //If the email has been updated update the current folder user key
        if (emailHasChanged) {
            const key = fields.email?.trim() ? encodeEmail(fields.email) : user.id;
            const sharedMaps = await fetchUserMaps(database, folderID, user.email || user.id);

            updates[folderUsersPath({ folderID, email: user.email || user.id })] = null;
            updates[sharedMapsPath({ folderID, userID: user.email || user.id })] = null;

            const movedUser: IUserDoc = {
                role: user.role,
                name: user.name,
                id: user.id,
                email: key,
            };

            updates[folderUsersPath({ folderID, email: key })] = {
                ...movedUser,
                ...fields,
                email: key,
            };

            updates[sharedMapsPath({ folderID, userID: key })] = sharedMaps;

            await database.ref().update(updates);
        } else {
            delete fields['email'];
            await database.ref(folderUsersPath({ folderID, email: user.email || user.id })).update(fields);
        }
    } catch (error) {
        throw new FriendlyError(UserActionError.UPDATE_FAILED, null, error);
    }
};

export default updateUser;
