import React from 'react';
import { Helmet } from 'react-helmet';

type Props = {
    title: string;
};

const PageMeta: React.FunctionComponent<Props> = ({ title }) => {
    return (
        <Helmet>
            <title>{title + ' - Mapper'}</title>
        </Helmet>
    );
};

export default PageMeta;
