import React, { useState } from 'react';
import { Box, Container, createStyles, Fab, InputAdornment, makeStyles, TextField, Theme, Typography } from '@material-ui/core';
import { Route, Routes } from 'react-router-dom';
import PageMeta from '../../components/page/PageMeta';
import { FolderRoute } from './folder/Folder';
import NewFolderDialog from './components/FolderDialog';
import FoldersList from './components/FoldersList';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/SearchOutlined';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
        },
        card: {
            width: '100%',
        },
        extendedIcon: {
            marginRight: theme.spacing(1),
        },
        searchContainer: {
            [theme.breakpoints.up('xs')]: {
                marginRight: theme.spacing(3),
            },
        },
        searchInput: {
            maxWidth: theme.breakpoints.width('sm'),
        },
    })
);

const Folders: React.FunctionComponent = () => {
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const classes = useStyles();

    return (
        <Box flex={1}>
            <PageMeta title="All Folders" />
            <Container>
                <Box pt={3} pb={2}>
                    <Typography variant="h6">All Folders</Typography>
                </Box>

                <Box display="flex" flexWrap="wrap-reverse" alignItems="center">
                    <Box className={classes.searchContainer} mb={3} flexGrow={1}>
                        <TextField
                            className={classes.searchInput}
                            fullWidth={true}
                            label="Search Folders"
                            type="search"
                            variant="outlined"
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                    <Box mb={3}>
                        <Fab onClick={() => setDialogOpen(true)} color="primary" variant="extended">
                            <AddIcon className={classes.extendedIcon} />
                            New Folder
                        </Fab>
                    </Box>
                </Box>

                <FoldersList />

                <NewFolderDialog onClose={() => setDialogOpen(false)} open={dialogOpen} />
            </Container>
        </Box>
    );
};

export const FoldersRoute: React.FunctionComponent = () => {
    return (
        <Routes>
            <Route path="/" element={<Folders />} />
            <Route path=":folderID/*" element={<FolderRoute />}></Route>
        </Routes>
    );
};

export default FoldersRoute;
