import { IMapBoundary } from '../MapBoundary';
import firebase from 'firebase';
import { mapBoundaryPath } from 'database/Config';

export const importBoundary = async (database: firebase.database.Database, folderID: string, mapID: string, boundary: IMapBoundary) => {
    try {
        await database.ref(mapBoundaryPath({ mapID, folderID })).update(boundary);
    } catch (error) {
        console.error(error);
        throw new Error('Unable to import map boundary');
    }
};
