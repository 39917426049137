import { IDoc } from '../Doc';
export interface IMap {
    name: string;
    reference?: string;
    color?: string;
    returned: boolean;
    dateReturned: string | null;
    dateAssigned: string | null;
    overseers: Record<string, true> | null;
    assignees: Record<string, true> | null;
    tags: Record<string, number> | null;
}

export interface IMapDoc extends IDoc, IMap {}

export enum MapAction {
    ASSIGN = 'Assign',
    RETURN = 'Return',
}
