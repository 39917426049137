import React from 'react';
import { Box, Container, CssBaseline, Typography } from '@material-ui/core';
import IconSentimentDissatisfied from '@material-ui/icons/SentimentDissatisfied';

interface Props {
    children?: React.ReactNode;
}

export const NotFound: React.FunctionComponent<Props> = ({ children }) => {
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />

            <Box display="flex" justifyContent="center" alignItems="center">
                <Box mb={2}>
                    <IconSentimentDissatisfied />
                </Box>
                <Box>{children ? children : <Typography>Page Not Found</Typography>}</Box>
            </Box>
        </Container>
    );
};

export default NotFound;
