import FriendlyError from 'core/FriendlyError';
import { folderTagsPath } from 'database/Config';
import { ITag, ITagDoc, TagActionError } from '../Tag';
import firebase from 'firebase';

export const createTag = async (database: firebase.database.Database, folderID: string, newTag: ITag): Promise<ITagDoc> => {
    try {
        const tagID = database.ref(folderTagsPath({ folderID })).push().key;

        if (!tagID) throw new Error('Unable to create tag no id was created');

        const tagDoc: ITagDoc = {
            ...newTag,
            id: tagID,
        };

        await database.ref(folderTagsPath({ folderID, tagID })).set(tagDoc);

        return tagDoc;
    } catch (error) {
        throw new FriendlyError(TagActionError.CREATE_FAILED, null, error);
    }
};

export default createTag;
