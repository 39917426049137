import { Box, Button, Container, Drawer, Menu, MenuItem } from '@material-ui/core';
import FilterIcon from '@material-ui/icons/FilterList';
import SortIcon from '@material-ui/icons/SortOutlined';
import React, { useState } from 'react';
import { MapsViewMode } from '../Maps';
import { MapFilterForm } from './forms/MapFiltersForm';
import GridIcon from '@material-ui/icons/ViewModuleRounded';
import TableIcon from '@material-ui/icons/ViewColumnRounded';
import { IMap } from 'database';

type Props = {
    folderID: string;
    viewMode: MapsViewMode;
    onViewModeChanged: (mode: MapsViewMode) => void;
    onSortChanged: (field: keyof IMap, reverse?: boolean) => void;
};

const ViewModeIcon = ({ viewMode }: { viewMode: MapsViewMode }) => {
    if (viewMode === 'table') return <TableIcon />;
    if (viewMode === 'grid') return <GridIcon />;
    return null;
};

export const MapsToolbar: React.FunctionComponent<Props> = ({ folderID, viewMode, onViewModeChanged, onSortChanged }) => {
    const [sortByAnchor, setSortByAnchor] = useState<null | HTMLElement>(null);
    const [viewModeAnchor, setViewModeAnchor] = useState<null | HTMLElement>(null);

    const [showFilterMenu, setShowFilterMenu] = useState<boolean>(false);

    const handleClose = () => {
        setSortByAnchor(null);
        setViewModeAnchor(null);
    };

    const applyFilters = () => {
        setShowFilterMenu(false);
    };

    const switchViewMode = (mode: MapsViewMode) => {
        onViewModeChanged(mode);
        handleClose();
    };

    const sortChanged = (field: keyof IMap, reverse?: boolean) => {
        onSortChanged(field, reverse);
        handleClose();
    };

    return (
        <Box display="flex" alignItems="center">
            <Box mr={1} mb={1}>
                <Button variant="outlined" color="default" startIcon={<FilterIcon />} onClick={() => setShowFilterMenu(true)}>
                    Filters
                </Button>
            </Box>
            {viewMode === 'grid' && (
                <Box mb={1} flex={1}>
                    <Button onClick={(event) => setSortByAnchor(event.currentTarget)} variant="outlined" color="default" startIcon={<SortIcon />}>
                        Sort By
                    </Button>
                    <Menu anchorEl={sortByAnchor} keepMounted open={Boolean(sortByAnchor)} onClose={handleClose}>
                        <MenuItem onClick={() => sortChanged('name')}>Name A-Z</MenuItem>
                        <MenuItem onClick={() => sortChanged('reference')}>Reference A-Z</MenuItem>
                        <MenuItem onClick={() => sortChanged('dateAssigned')}>Most Overdue</MenuItem>
                        <MenuItem onClick={() => sortChanged('dateReturned')}>Longest Since Worked</MenuItem>
                    </Menu>
                </Box>
            )}

            <Box display="flex" justifyContent="flex-end" flex={1} mb={1}>
                <Button onClick={(event) => setViewModeAnchor(event.currentTarget)} variant="outlined" color="default">
                    <ViewModeIcon viewMode={viewMode} />
                </Button>
                <Menu anchorEl={viewModeAnchor} keepMounted open={Boolean(viewModeAnchor)} onClose={handleClose}>
                    <MenuItem onClick={() => switchViewMode('grid')}>
                        <Box mr={1}>Grid</Box> <ViewModeIcon viewMode={'grid'} />
                    </MenuItem>
                    <MenuItem onClick={() => switchViewMode('table')}>
                        <Box mr={1}>Table</Box> <ViewModeIcon viewMode={'table'} />
                    </MenuItem>
                </Menu>
            </Box>

            <Drawer anchor={'bottom'} open={showFilterMenu} onClose={() => setShowFilterMenu(false)}>
                <Container>
                    <MapFilterForm folderID={folderID} onSubmit={applyFilters} />
                </Container>
            </Drawer>
        </Box>
    );
};

export default MapsToolbar;
