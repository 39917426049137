import firebase from 'firebase';
import { useEffect, useState } from 'react';
import { useDatabase, useDatabaseObjectData, useUser } from 'reactfire';
import { foldersPath, sharedFoldersPath, IFolderDoc } from 'database';

export default function useSharedFolders(): Array<IFolderDoc> {
    const { data: user } = useUser<firebase.User>();
    const database = useDatabase();
    const [folders, setFolders] = useState<IFolderDoc[]>([]);

    const foldersRef = useDatabase().ref(sharedFoldersPath({ email: user.email as string }));
    const { data: sharedFolderIDs } = useDatabaseObjectData<string>(foldersRef);

    useEffect(() => {
        const folderIDs = Object.keys(sharedFolderIDs);
        const indexOfIDField = folderIDs.indexOf('NO_ID_FIELD');
        if (indexOfIDField > -1) folderIDs.splice(indexOfIDField, 1);

        Promise.all(folderIDs.map((id) => database.ref(foldersPath({ folderID: id })).once('value'))).then((value) => {
            const newFolders = value.map((doc) => doc.val());
            setFolders(newFolders);
        });
    }, [database, sharedFolderIDs]);

    return folders;
}
