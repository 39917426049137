import { useDatabase, useDatabaseListData } from 'reactfire';
import { IMap, IMapHistory, mapHistoryPath, MapHistoryType } from 'database';
import moment from 'moment';
import useMap from './useMap';

export type MapHistoryDetails = {
    title: string;
    description: string;
    dateFormatted: string;
    createdBy: string;
    type: MapHistoryType;
};

const toMapHistoryDetails = (mapHistory: IMapHistory, map: IMap): MapHistoryDetails => {
    const { date, userName, createdBy, type } = mapHistory;
    let title = '';
    let description = '';

    switch (mapHistory.type) {
        case 'ASSIGNED':
            title = 'Assigned';
            description = `Map assigned to ${userName}`;
            break;
        case 'RETURNED':
            title = 'Returned';
            description = `Map returned`;
            break;
        case 'EDITED':
            title = 'Edited';
            description = `Map edited`;
            break;
    }

    return {
        type,
        title,
        description,
        dateFormatted: moment(date).format('LL'),
        createdBy,
    };
};

export const useMapHistory = (folderID: string, mapID: string): Array<MapHistoryDetails> => {
    const database = useDatabase();
    const map = useMap(folderID, mapID);
    const ref = database.ref(mapHistoryPath({ folderID, mapID })).limitToLast(100);
    const { data: mapHistory } = useDatabaseListData<IMapHistory>(ref, { idField: 'id' });

    return mapHistory.map((history) => toMapHistoryDetails(history, map)).reverse();
};

export default useMapHistory;
