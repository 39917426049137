import FriendlyError from 'core/FriendlyError';
import { folderUsersPath, sharedMapsPath } from 'database/Config';
import { UserActionError } from '..';
import firebase from 'firebase';
import { IUserDoc } from '../User';

export const deleteAllUsers = async (database: firebase.database.Database, folderID: string, folderOwner: IUserDoc): Promise<void> => {
    try {
        const updates: Record<string, any> = {
            [sharedMapsPath({ folderID })]: null,
            [folderUsersPath({ folderID })]: { [folderOwner.email || folderOwner.id]: folderOwner },
        };

        await database.ref().update(updates);
    } catch (error) {
        throw new FriendlyError(UserActionError.DELETE_FAILED, null, error);
    }
};

export default deleteAllUsers;
