import { Box, createStyles, Grid, makeStyles, Paper, Typography } from '@material-ui/core';
import MapHistoryList from '../history/MapHistoryList';
import MapOverview from 'components/maps/Map';
import { MapAction } from 'database';
import useDeviceType from 'hooks/useDeviceType';
import React from 'react';
import { useParams } from 'react-router-dom';
import AssignMapDialog from '../components/AssignMapDialog';
import MapActionButton from '../components/MapActionButton';
import MapActionButtons from '../components/MapActionButtons';
import MapNotes from '../components/MapNotes';
import ReturnMapDialog from '../components/ReturnMapDialog';
import useMap from '../hooks/useMap';
import useMapDetails from '../hooks/useMapDetails';
import useMapHistory from '../hooks/useMapHistory';
import MapAvatar from '../components/MapAvatar';
import MapTags from '../components/MapTags';
import useUsers from 'views/folders/folder/users/hooks/useUsers';
import useTags from 'views/folders/folder/tags/hooks/useTags';
import useMapBoundary from '../hooks/useMapBoundary';

const useStyles = makeStyles((theme) =>
    createStyles({
        map: {
            overflow: 'hidden',
            height: '50vh',
            borderRadius: 6,
            position: 'relative',
        },
        mapHistory: {
            maxHeight: '50vh',
            overflow: 'auto',
        },
        history: {
            order: 3,
            [theme.breakpoints.up('lg')]: {
                order: 2,
            },
        },
        notes: {
            order: 2,
            [theme.breakpoints.up('lg')]: {
                order: 3,
            },
        },
    })
);

export const MapDashboard: React.FunctionComponent = () => {
    const { folderID, mapID } = useParams();
    const classes = useStyles();
    const map = useMap(folderID, mapID);
    const mapDetails = useMapDetails(folderID, mapID);
    const { isDesktop } = useDeviceType();
    const [openAssignDialog, setOpenAssignDialog] = React.useState<boolean>(false);
    const [openReturnDialog, setOpenReturnDialog] = React.useState<boolean>(false);
    const mapHistory = useMapHistory(folderID, mapID);
    const users = useUsers(folderID);
    const tags = useTags(folderID);
    const boundary = useMapBoundary(folderID, mapID);

    const handleMapAction = (action: MapAction) => {
        if (action === MapAction.ASSIGN) setOpenAssignDialog(true);
        else if (action === MapAction.RETURN) setOpenReturnDialog(true);
    };

    return (
        <React.Fragment>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper>
                        <Grid container>
                            <Grid item xs={12}>
                                <Box p={2}>
                                    <Box display="flex" alignItems="center">
                                        <Box mr={2}>
                                            <MapAvatar map={map} />
                                        </Box>
                                        <Typography variant="h4">{map.name}</Typography>
                                    </Box>
                                    <Box pt={3}>
                                        <MapTags showOverseers={true} map={map} users={users} tags={tags} />
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item xs={12}>
                                <Box pb={1} pl={2} pr={2} display="flex" flexWrap="wrap" alignItems="center">
                                    <Box flex={1}>
                                        <MapActionButton handleMapAction={handleMapAction} map={map} />
                                    </Box>
                                    <Box flex={1} display="flex" justifyContent="flex-end">
                                        <MapActionButtons map={map} boundary={boundary} folderID={folderID} />
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={12} md={12} lg={mapHistory.length ? 9 : 12}>
                    <Paper elevation={3} className={classes.map}>
                        <MapOverview boundaries={boundary ? [boundary] : []} dragging={isDesktop} scrollWheelZoom={isDesktop} />
                    </Paper>
                </Grid>

                {!!mapHistory.length && (
                    <Grid item xs={12} md={12} lg={3} className={classes.history}>
                        <Paper className={classes.mapHistory}>
                            <Box pt={2} pl={2}>
                                <Typography variant="h6">History</Typography>
                            </Box>
                            <Box pl={1}>
                                <MapHistoryList mapID={mapID} folderID={folderID} />
                            </Box>
                        </Paper>
                    </Grid>
                )}

                {mapDetails.notes && (
                    <Grid item xs={12} className={classes.notes}>
                        <Paper>
                            <Box p={2}>
                                <Typography variant="h6">Notes</Typography>

                                <Box pl={2} pr={2}>
                                    <MapNotes notes={mapDetails.notes} />
                                </Box>
                            </Box>
                        </Paper>
                    </Grid>
                )}
            </Grid>
            <AssignMapDialog folderID={folderID} onClose={() => setOpenAssignDialog(false)} map={map} open={openAssignDialog} />
            <ReturnMapDialog folderID={folderID} onClose={() => setOpenReturnDialog(false)} map={map} open={openReturnDialog} />
        </React.Fragment>
    );
};

export default MapDashboard;
