import React from 'react';
import { Link as NavLink } from 'react-router-dom';
import { Card, CardHeader, CardActionArea, CardActions, CardContent, Box, makeStyles, createStyles } from '@material-ui/core';
import { IMapDoc, ITagDoc, IUser, MapAction, MapUtils } from 'database';

import MapAvatar from './MapAvatar';
import MapActionButton from './MapActionButton';
import MapTags from './MapTags';
import MapAge from './MapAge';

interface MapCardProps {
    map: IMapDoc;
    handleMapAction: (action: MapAction, map: IMapDoc) => void;
    users: Record<string, IUser>;
    tags: Array<ITagDoc>;
}

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            height: '100%',
        },
        fullwidth: {
            width: '100%',
        },
        content: {
            width: '100%',
            flex: 1,
        },
        actionArea: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            height: '100%',
        },
    })
);

export const MapCard: React.FunctionComponent<MapCardProps> = ({ map, handleMapAction, users, tags }: MapCardProps) => {
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardActionArea className={classes.actionArea} to={`${map.id}`} component={NavLink}>
                <CardHeader
                    className={classes.fullwidth}
                    avatar={<MapAvatar map={map} />}
                    title={map.name}
                    subheader={MapUtils.getRelevantDate(map)}
                />
                <CardContent className={classes.content}>
                    <MapTags map={map} users={users} tags={tags} />
                </CardContent>
                <CardActions className={classes.fullwidth} disableSpacing>
                    <Box pl={1} flex="1" display="flex" justifyContent="flex-start">
                        <MapAge map={map} />
                    </Box>
                    <Box flex="1" display="flex" justifyContent="flex-end">
                        <MapActionButton handleMapAction={handleMapAction} map={map} />
                    </Box>
                </CardActions>
            </CardActionArea>
        </Card>
    );
};

export default MapCard;
