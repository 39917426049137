import React, { useState } from 'react';
import { Box, List, Paper, Typography } from '@material-ui/core';
import { withErrorBoundary } from 'react-error-boundary';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import { decodeEmail, IUserDoc, UserAction, UserComparator } from 'database';
import useUsersList from '../hooks/useUsersList';
import UserListItem from './UserListItem';
import ConfirmDialog, { ConfirmDialogOptions } from 'components/dialogs/ConfirmDialog';
import UserFormDialog, { UserFormFields } from './UserFormDialog';
import FolderInviteDialog from '../../components/FolderInviteDialog';
import useUserActions from '../hooks/useUserActions';
import useUserComparator from '../hooks/useUserComparator';

const NoUsers = () => {
    return (
        <Box p={3} textAlign="center">
            <Typography variant="subtitle1">You haven't added any Users yet.</Typography>
        </Box>
    );
};

interface Props {
    folderID: string;
    comparator: UserComparator;
}

const editUserDialogOptions = {
    title: 'Edit user',
    description: 'Edit the details of this user.',
    submitLabel: 'Save',
};

const confirmOptions = (user: IUserDoc): ConfirmDialogOptions => {
    return {
        confirmLabel: 'Delete',
        description: `Are you sure you wan't to delete this user ${user.name}?`,
        title: 'Confirm',
    };
};

export const UsersListComponent: React.FunctionComponent<Props> = ({ folderID, comparator }) => {
    const users = useUsersList(folderID);
    const filteredUsers = useUserComparator(users, comparator);

    const [selectedUser, setSelectedUser] = React.useState<IUserDoc | null>(null);
    const { updateUser, deleteUser } = useUserActions(folderID);

    const [editDialogOpen, setEditDialogOpen] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);
    const [showInviteDialog, setShowInviteDialog] = useState(false);

    const handleDialogClose = () => {
        setSelectedUser(null);
        setEditDialogOpen(false);
        setShowConfirm(false);
        setShowInviteDialog(false);
    };

    const handleDelete = async () => {
        if (selectedUser) deleteUser(selectedUser);
        handleDialogClose();
    };

    const handleUserAction = (action: UserAction, user: IUserDoc) => {
        setSelectedUser(user);

        if (action === UserAction.EDIT) setEditDialogOpen(true);
        if (action === UserAction.DELETE) setShowConfirm(true);
        if (action === UserAction.INVITE) setShowInviteDialog(true);
    };

    const handleEdit = async (fields: UserFormFields) => {
        if (selectedUser) updateUser(fields, selectedUser).then(() => handleDialogClose());
    };

    if (!filteredUsers.length) {
        return <NoUsers />;
    }

    return (
        <React.Fragment>
            <Paper>
                <List disablePadding={true}>
                    {filteredUsers.map((user, index) => (
                        <UserListItem
                            folderID={folderID}
                            key={index}
                            user={user}
                            divider={index !== filteredUsers.length - 1}
                            onUserAction={handleUserAction}
                        />
                    ))}
                </List>
            </Paper>

            {selectedUser && (
                <React.Fragment>
                    <ConfirmDialog options={confirmOptions(selectedUser)} open={showConfirm} onClose={handleDialogClose} onSubmit={handleDelete} />
                    <UserFormDialog
                        onSubmit={handleEdit}
                        onClose={handleDialogClose}
                        open={editDialogOpen}
                        initialValues={{
                            email: selectedUser.email === selectedUser.id ? '' : decodeEmail(selectedUser.email),
                            name: selectedUser.name,
                            role: selectedUser.role,
                        }}
                        options={editUserDialogOptions}
                    />

                    <FolderInviteDialog open={showInviteDialog} onClose={handleDialogClose} folderID={folderID} user={selectedUser} />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

const FallbackComponent: React.FunctionComponent = () => {
    return (
        <Box p={3} textAlign="center">
            <ErrorOutline />
            <Typography variant="subtitle1">Unable to load Users</Typography>
        </Box>
    );
};

const UsersList = withErrorBoundary(UsersListComponent, { FallbackComponent });

export default UsersList;
