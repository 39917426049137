import { useMapsStats } from '../maps/hooks/useMapsStats';
import useUsersList from '../users/hooks/useUsersList';

type MapStats = {
    total: number;
    overdue: number;
    returned: number;
    assigned: number;
};

type UseFolderStats = (
    folderID: string
) => {
    maps: MapStats;
    users: { total: number };
};

export const useFolderStats: UseFolderStats = (folderID: string) => {
    const mapStats = useMapsStats(folderID);
    const users = useUsersList(folderID);

    return {
        maps: mapStats,
        users: { total: users.length },
    };
};

export default useFolderStats;
