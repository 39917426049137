import { IconButton } from '@material-ui/core';
import React from 'react';
import EditIcon from '@material-ui/icons/EditOutlined';
import PrintIcon from '@material-ui/icons/PrintOutlined';
import ShareIcon from '@material-ui/icons/ShareOutlined';
import { IMapDoc, UserRole } from 'database';
import { useNavigate } from 'react-router-dom';
import HiddenForRoles from 'views/folders/folder/users/components/HiddenForRole';
import useUser from 'views/folders/folder/users/hooks/useUser';
import MapSettingsMenu from './MapSettingsMenu';
import { IMapBoundaryDoc } from 'database/maps/MapBoundary';

type Props = {
    folderID: string;
    map: IMapDoc;
    boundary?: IMapBoundaryDoc;
};
export const MapActionButtons: React.FunctionComponent<Props> = ({ map, folderID, boundary }) => {
    const navigate = useNavigate();
    const user = useUser(folderID);

    return (
        <React.Fragment>
            <IconButton>
                <ShareIcon />
            </IconButton>

            <IconButton>
                <PrintIcon />
            </IconButton>

            <HiddenForRoles user={user} roles={[UserRole.ASSIGNEE, UserRole.OVERSEER]}>
                <IconButton onClick={() => navigate('edit')}>
                    <EditIcon />
                </IconButton>

                <MapSettingsMenu folderID={folderID} map={map} boundary={boundary} />
            </HiddenForRoles>
        </React.Fragment>
    );
};

export default MapActionButtons;
