import { IMapDoc } from 'database';
import { importBoundary } from 'database/maps/actions/importBoundary';
import { IMapBoundary } from 'database/maps/MapBoundary';
import { GeoJsonObject } from 'geojson';
import { geoJSON } from 'leaflet';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useDatabase } from 'reactfire';

type UseImportBoundaries = {
    running: boolean;
    importBoundaries: (mapID: IMapDoc, file: File) => Promise<void>;
};

export const useImportBoundaries = (folderID: string): UseImportBoundaries => {
    const [running, setRunning] = useState(false);
    const database = useDatabase();
    const { enqueueSnackbar } = useSnackbar();

    const importBoundaries = async (map: IMapDoc, file: File) => {
        try {
            const reader = new FileReader();

            const data = (await new Promise((resolve, reject) => {
                reader.onload = (e) => {
                    if (typeof e.target?.result === 'string') {
                        return resolve(JSON.parse(e.target.result));
                    }
                    return reject('Unable to load local file');
                };

                reader.readAsText(file);
            })) as GeoJsonObject;

            const boundary: IMapBoundary = {
                data: geoJSON(data).toGeoJSON(),
                color: map.color || '#2196f3',
            };

            setRunning(true);
            await importBoundary(database, folderID, map.id, boundary);
            enqueueSnackbar(`Map Boundary imported`, { variant: 'success' });
        } catch (error) {
            enqueueSnackbar('Unable to import map boundary', { variant: 'error' });
        } finally {
            setRunning(false);
        }
    };

    return {
        running,
        importBoundaries,
    };
};

export default useImportBoundaries;
