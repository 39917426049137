import { ListItemIcon, Menu, MenuItem, Typography } from '@material-ui/core';
import React from 'react';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import { FolderAction, IFolderDoc } from 'database';

type Props = {
    folder: IFolderDoc;
    anchorEl: HTMLButtonElement | null;
    onFolderAction: (action: FolderAction, folder: IFolderDoc) => void;
    onClose: () => void;
};

const FolderMenu: React.FunctionComponent<Props> = ({ folder, anchorEl, onClose, onFolderAction }) => {
    const handleAction = (event: React.MouseEvent, action: FolderAction) => {
        event.stopPropagation();
        event.preventDefault();
        onFolderAction(action, folder);
        onClose();
    };

    return (
        <React.Fragment>
            <Menu
                id="folder-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={(event: any) => {
                    if (event && event.stopPropagation) event.stopPropagation();
                    onClose();
                }}
            >
                <MenuItem
                    button
                    onClick={(event) => {
                        handleAction(event, FolderAction.RENAME);
                    }}
                >
                    <ListItemIcon>
                        <EditIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">Rename</Typography>
                </MenuItem>
                <MenuItem button onClick={(event) => handleAction(event, FolderAction.DELETE)}>
                    <ListItemIcon>
                        <DeleteIcon fontSize="small" />
                    </ListItemIcon>
                    <Typography variant="inherit">Delete</Typography>
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
};

export default FolderMenu;
