import FriendlyError from 'core/FriendlyError';
import {
    foldersPath,
    folderUsersPath,
    mapDetailsPath,
    mapHistoryPath,
    mapsPath,
    sharedFoldersPath,
    sharedMapsPath,
    folderInvitesPath,
} from 'database/Config';
import { FolderActionError } from '.';
import { IFolderDoc } from '../Folder';
import firebase from 'firebase';

export const deleteFolder = async (database: firebase.database.Database, folder: IFolderDoc): Promise<void> => {
    try {
        const folderID = folder.id;
        const folderLocation = foldersPath({ folderID });
        const maps = mapsPath({ folderID });
        const folderUsersLocation = folderUsersPath({ folderID });
        const users = await database.ref(folderUsersLocation).orderByKey().once('value');
        const userMaps = sharedMapsPath({ folderID });
        const mapDetails = mapDetailsPath({ folderID });
        const mapHistory = mapHistoryPath({ folderID });
        const invites = folderInvitesPath({ folderID });

        const deletes: Record<string, null> = {
            [folderLocation]: null,
            [folderUsersLocation]: null,
            [userMaps]: null,
            [maps]: null,
            [mapDetails]: null,
            [mapHistory]: null,
            [invites]: null,
        };

        users.forEach((user) => {
            const email = user.key;
            if (email) deletes[sharedFoldersPath({ email, folderID })] = null;
        });

        await database.ref().update(deletes);
    } catch (error) {
        throw new FriendlyError(FolderActionError.DELETE_FAILED, { name: folder.name }, error);
    }
};

export default deleteFolder;
