import FriendlyError from 'core/FriendlyError';
import { folderTagsPath } from 'database/Config';
import firebase from 'firebase';
import { ITagDoc } from '../Tag';

type FetchtagsResult = {
    error?: FriendlyError;
    tags?: Record<string, ITagDoc>;
};

const fetchError = 'Unable to fetch tags';

export const fetchTags = async (database: firebase.database.Database, folderID: string): Promise<FetchtagsResult> => {
    try {
        const tagsQuery = database.ref(folderTagsPath({ folderID })).orderByChild('name');
        const tagRef = await tagsQuery.once('value');

        const tags: Record<string, ITagDoc> = {};
        tagRef.forEach((doc) => {
            const user = doc.val();
            tags[doc.key || ''] = user;
        });

        return { tags };
    } catch (error) {
        return { error: new FriendlyError(fetchError, null, error) };
    }
};

export default fetchTags;
