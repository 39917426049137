export class Utils {
    static docsToRecord<TType, ValueType>(
        docs: Array<TType>,
        keyGetter: (doc: TType) => string,
        valueGetter: (doc: TType, index: number) => ValueType
    ): Record<string, ValueType> {
        const record: Record<string, ValueType> = {};

        for (const doc of docs) {
            const index = docs.indexOf(doc);
            const key = keyGetter(doc);
            record[key] = valueGetter(doc, index);
        }

        return record;
    }
}
