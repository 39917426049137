import { Breadcrumbs, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { Suspense } from 'react';
import AppLink from '../../../../components/links/AppLink';
import ChevronRight from '@material-ui/icons/ChevronRightOutlined';
import useFolder from '../hooks/useFolder';
interface Props {
    folderID: string;
    children?: React.ReactNode;
}

export const FolderName: React.FunctionComponent<{ folderID: string; hasChildren: boolean }> = ({ folderID, hasChildren }) => {
    const folder = useFolder(folderID);

    if (hasChildren) {
        return (
            <AppLink to={`/folders/${folderID}`}>
                <Typography color="textPrimary" variant="h6">
                    {folder.name}
                </Typography>
            </AppLink>
        );
    }

    return <Typography variant="h6">{folder.name}</Typography>;
};

const FolderBreadcrumbs: React.FunctionComponent<Props> = ({ folderID, children }) => {
    return (
        <Breadcrumbs separator={<ChevronRight />} aria-label="breadcrumb">
            <Suspense
                fallback={
                    <Typography color="textPrimary" variant="h6">
                        <Skeleton variant="text" width={100} />
                    </Typography>
                }
            >
                <FolderName folderID={folderID} hasChildren={!!children}></FolderName>
            </Suspense>

            {children}
        </Breadcrumbs>
    );
};

export default FolderBreadcrumbs;
