import { mapBoundaryPath, mapDetailsPath, mapsPath, sharedMapsPath } from 'database/Config';
import { IMapDoc } from '../Map';
import { IMapDetailsDoc } from '../MapDetails';
import firebase from 'firebase';
import { fetchUsers } from 'database/users';

export const updateMap = async (
    database: firebase.database.Database,
    folderID: string,
    existingMap: IMapDoc,
    map: Partial<IMapDoc>,
    mapDetails?: Partial<IMapDetailsDoc>
): Promise<IMapDoc> => {
    // Get all the ids of the existing shared with users
    const existingSharedWith = [...Object.keys(existingMap.assignees || {}), ...Object.keys(existingMap.overseers || {})];
    // Get the new shared user ids
    const newSharedWith = [...Object.keys(map.assignees || {}), ...Object.keys(map.overseers || {})];
    // Get a list of all ids on the existing map that don't existing on the update
    const usersToDelete = existingSharedWith.filter((userId) => newSharedWith.indexOf(userId) === -1);

    const updates: { [path: string]: any } = {};

    Object.keys(map).forEach((field) => {
        updates[`${mapsPath({ folderID, mapID: existingMap.id })}/${field}`] = (map as any)[field];
    });

    if (mapDetails) {
        Object.keys(mapDetails).forEach((field) => {
            updates[`${mapDetailsPath({ folderID, mapID: existingMap.id })}/${field}`] = (mapDetails as any)[field];
        });
    }

    const { users } = await fetchUsers(database, folderID, 'email');
    if (!users) throw new Error('Unable to fetch users');

    for (const userID of usersToDelete) {
        const user = users[userID];
        if (user && user.email) {
            updates[sharedMapsPath({ folderID, userID: user.email, mapID: existingMap.id })] = null;
        }
    }

    for (const userID of newSharedWith) {
        const user = users[userID];
        if (user && user.email) {
            updates[sharedMapsPath({ folderID, userID: user.email, mapID: existingMap.id })] = true;
        }
    }

    if (map.color !== existingMap.color) {
        updates[`${mapBoundaryPath({ folderID, mapID: existingMap.id })}/color`] = map.color;
    }

    await database.ref().update(updates);

    const updatedMap = Object.assign(existingMap, map);

    return updatedMap;
};

export default updateMap;
