export type SupportedDateFormats = 'DD-MM-YYYY' | 'DD/MM/YYYY' | 'YYYY-MM-DD' | 'YYYY/MM/DD' | 'MM-DD-YYYY' | 'MM/DD/YYYY';

export type MapCSV = {
    rows: MapCSVRow[];
    invalidRows: MapCSVRow[];
    valid: boolean;
};

export type MapCSVRow = {
    Reference?: string;
    Color?: string;
    'Map Name': string;
    Overseers: string;
    Assignees?: string;
    Tags?: string;
    'Date Assigned': SupportedDateFormats;
    'Date Returned': SupportedDateFormats | null;
    Notes?: string | null;
};

export const MAP_TAG_COLOR_SEPERATOR = '|';

export const MAP_USER_SEPERATOR = '|';
