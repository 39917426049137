import React, { Suspense } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import MapCard from '../map/components/MapCard';
import { withErrorBoundary } from 'react-error-boundary';
import ErrorOutline from '@material-ui/icons/ErrorOutline';
import { IMapDoc, IMapComparatorParams, MapAction } from 'database';
import AssignMapDialog from '../map/components/AssignMapDialog';
import ReturnMapDialog from '../map/components/ReturnMapDialog';

import useMaps from '../hooks/useMaps';
import useUsers from '../../users/hooks/useUsers';
import useTags from '../../tags/hooks/useTags';
import useMapFilter from '../hooks/useMapComparator';

const NoMaps = ({ searchTerm }: { searchTerm?: string }) => {
    return (
        <Box p={3} textAlign="center">
            <Typography variant="subtitle1">{searchTerm ? `No maps found matching ${searchTerm}` : 'You have no maps'}</Typography>
        </Box>
    );
};

type Props = {
    folderID: string;
    comparator?: IMapComparatorParams;
};

const MapsListComponent: React.FunctionComponent<Props> = ({ folderID, comparator }) => {
    const users = useUsers(folderID);
    const maps = useMaps(folderID);
    const filteredMaps = useMapFilter(folderID, maps, comparator);
    const tags = useTags(folderID);
    const [selectedMap, setSelectedMap] = React.useState<IMapDoc | null>(null);
    const [openAssignDialog, setOpenAssignDialog] = React.useState<boolean>(false);
    const [openReturnDialog, setOpenReturnDialog] = React.useState<boolean>(false);

    const handleDialogClose = () => {
        setSelectedMap(null);
        setOpenAssignDialog(false);
        setOpenReturnDialog(false);
    };

    const handleMapAction = (action: MapAction, map: IMapDoc) => {
        setSelectedMap(map);

        if (action === MapAction.ASSIGN) setOpenAssignDialog(true);
        else if (action === MapAction.RETURN) setOpenReturnDialog(true);
    };

    if (!filteredMaps.length) {
        return <NoMaps searchTerm={comparator?.searchTerm} />;
    }

    return (
        <React.Fragment>
            <Grid container spacing={2}>
                {filteredMaps.map((map, index) => (
                    <Grid key={index} item xs={12} sm={6} md={6} lg={4}>
                        <MapCard map={map} handleMapAction={handleMapAction} users={users} tags={tags} />
                    </Grid>
                ))}
            </Grid>

            {selectedMap && (
                <React.Fragment>
                    <AssignMapDialog folderID={folderID} onClose={handleDialogClose} map={selectedMap} open={openAssignDialog} />
                    <ReturnMapDialog folderID={folderID} onClose={handleDialogClose} map={selectedMap} open={openReturnDialog} />
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export const MapsListWithSuspense: React.FunctionComponent<Props> = (props) => {
    return (
        <Suspense fallback={<Typography>Loading maps...</Typography>}>
            <MapsListComponent {...props} />
        </Suspense>
    );
};

const FallbackComponent: React.FunctionComponent = () => {
    return (
        <Box p={3} textAlign="center">
            <ErrorOutline />
            <Typography variant="subtitle1">Unable to load maps</Typography>
        </Box>
    );
};

export const MapsList = withErrorBoundary(MapsListWithSuspense, { FallbackComponent });

export default MapsList;
