import FriendlyError from 'core/FriendlyError';
import { encodeEmail, folderUsersPath } from 'database/Config';
import { UserActionError } from '..';
import { IUser, IUserDoc } from '../User';
import firebase from 'firebase';
import { generate as uuid } from 'short-uuid';

export const addUser = async (database: firebase.database.Database, folderID: string, newUser: IUser): Promise<IUserDoc> => {
    try {
        const id = uuid();
        const userDoc: IUserDoc = {
            ...newUser,
            email: newUser.email ? encodeEmail(newUser.email) : id,
            id,
        };

        await database.ref(folderUsersPath({ folderID, email: newUser.email ? encodeEmail(userDoc.email) : userDoc.id })).set(userDoc);

        return userDoc;
    } catch (error) {
        throw new FriendlyError(UserActionError.CREATE_FAILED, null, error);
    }
};

export default addUser;
