import FriendlyError from 'core/FriendlyError';
import { folderTagsPath } from 'database/Config';
import { TagActionError } from '../Tag';
import firebase from 'firebase';

export const deleteAllTags = async (database: firebase.database.Database, folderID: string): Promise<void> => {
    try {
        const updates = {
            [folderTagsPath({ folderID })]: null,
        };
        await database.ref().update(updates);
    } catch (error) {
        throw new FriendlyError(TagActionError.DELETE_FAILED, null, error);
    }
};

export default deleteAllTags;
