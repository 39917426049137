import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import React from 'react';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'formik-material-ui';
import { ITag, TagSchema } from 'database';
import ColorPicker from 'components/color/ColorPicker';
import Tag from './Tag';
import tinycolor from 'tinycolor2';

type Props = {
    open: boolean;
    onClose: () => void;
    onSubmit: (fields: TagFormFields) => Promise<void>;
    initialValues?: TagFormFields;
    options: {
        title: string;
        submitLabel: string;
    };
};

export class TagFormFields {
    name: string = '';
    color: string = tinycolor.random().toHexString();

    public toTag(): ITag {
        return {
            name: this.name,
            color: this.color,
        };
    }
}

const TagFormDialog: React.FunctionComponent<Props> = ({ open, onClose, onSubmit, initialValues = new TagFormFields(), options }) => {
    return (
        <Formik
            initialValues={initialValues}
            validationSchema={TagSchema}
            validateOnMount={true}
            onSubmit={(values, { resetForm }) => {
                onSubmit(values).finally(() => {
                    onClose();
                    resetForm();
                });
            }}
        >
            {({ submitForm, isSubmitting, setFieldValue, values, isValid }) => (
                <Dialog fullWidth={true} maxWidth={'xs'} open={open} onClose={() => onClose()} aria-labelledby="form-dialog-title">
                    <DialogTitle>{options.title}</DialogTitle>
                    <DialogContent>
                        <Box textAlign="right" mb={2}>
                            <Tag color={values.color || initialValues.color} label={values.name || 'Preview'} />
                        </Box>
                        <Form autoComplete="off">
                            <Box mb={2}>
                                <Field
                                    fullWidth
                                    disabled={isSubmitting}
                                    required
                                    component={TextField}
                                    variant="outlined"
                                    name="name"
                                    type="text"
                                    label="Name"
                                />
                            </Box>

                            <Box mb={2}>
                                <ColorPicker
                                    disabled={isSubmitting}
                                    onChange={(color) => setFieldValue('color', color)}
                                    label="Tag Colour"
                                    color={values.color || initialValues.color}
                                />
                            </Box>
                        </Form>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => onClose()} variant="outlined">
                            Cancel
                        </Button>
                        <Button type="submit" disabled={isSubmitting || !isValid} onClick={submitForm} variant="contained" color="primary">
                            {options.submitLabel}
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Formik>
    );
};

export default TagFormDialog;
