import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import ErrorFallback from 'components/fallbacks/ErrorFallback';
import { FolderSchema } from 'database';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { TextField } from 'formik-material-ui';
import React, { Suspense } from 'react';
import { withErrorBoundary } from 'react-error-boundary';
import useCreateFolder from '../folder/hooks/useCreateFolder';

type Props = {
    open: boolean;
    onClose: () => void;
};

class FolderFields {
    name = 'Untitled Folder';
}

const DialogComponent: React.FunctionComponent<Props> = ({ open, onClose }) => {
    const { create } = useCreateFolder();

    const close = () => {
        onClose();
    };

    const submit = ({ name }: FolderFields, { setSubmitting }: FormikHelpers<FolderFields>) => {
        setSubmitting(true);
        create({ name }).finally(() => {
            setSubmitting(false);
            close();
        });
    };

    const initialValues = new FolderFields();

    return (
        <Formik validationSchema={FolderSchema} initialValues={initialValues} onSubmit={submit}>
            {({ resetForm, isSubmitting, submitForm }) => (
                <Dialog
                    fullWidth={true}
                    maxWidth={'xs'}
                    open={open}
                    onClose={() => {
                        close();
                        resetForm();
                    }}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle>Create New Folder</DialogTitle>
                    <DialogContent>
                        <Form noValidate autoComplete="off">
                            <Field
                                required
                                disabled={isSubmitting}
                                fullWidth
                                component={TextField}
                                variant="outlined"
                                name="name"
                                type="text"
                                label="Folder Name"
                            />
                        </Form>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                close();
                                resetForm();
                            }}
                            color="primary"
                        >
                            Cancel
                        </Button>
                        <Button type="submit" onClick={submitForm} color="primary">
                            Create
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </Formik>
    );
};

const DialogLoading: React.FunctionComponent<Props> = (props) => {
    return (
        <Suspense fallback={null}>
            <DialogComponent {...props} />
        </Suspense>
    );
};

const FolderDialog = withErrorBoundary(DialogLoading, { FallbackComponent: ErrorFallback });

export default FolderDialog;
