import { IMap, IMapDoc, ITagDoc, IUserDoc } from 'database';
import { SelectUserOption } from 'views/folders/folder/users/components/SelectUserField';
import moment from 'moment';
import { Utils } from 'Utils';
import { ContentState, convertToRaw, convertFromRaw } from 'draft-js';
import { IMapDetails, IMapDetailsDoc } from 'database/maps/MapDetails';
import { SelectTagOption } from '../../../tags/components/SelectTagsField';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';

export default class MapFormFields {
    name: string;
    reference: string;
    color: string;
    dateReturned: moment.Moment;
    dateAssigned: moment.Moment;
    returned: boolean;
    overseers: Array<SelectUserOption> = [];
    assignees: Array<SelectUserOption> = [];
    tags: Array<SelectTagOption> = [];
    notes?: ContentState;

    constructor(map: Partial<IMapDoc> = {}, mapDetails: Partial<IMapDetailsDoc> = {}, users?: IUserDoc[], tagsList?: ITagDoc[]) {
        const { name, reference, color, returned, dateReturned, dateAssigned, overseers, assignees, tags } = map;
        const now = moment();

        this.name = name || '';
        this.reference = reference || '';
        this.color = color || '#bdbdbd';
        this.dateReturned = moment(dateReturned) || now;
        this.returned = returned || Boolean(dateReturned);
        this.dateAssigned = this.returned ? now : moment(dateAssigned) || now;
        this.overseers =
            users && overseers
                ? users
                      .filter((user) => overseers[user.id])
                      .map((user) => {
                          return { name: user.name, id: user.id };
                      })
                : [];
        this.assignees =
            users && assignees
                ? users
                      .filter((user) => assignees[user.id])
                      .map((user) => {
                          return { name: user.name, id: user.id };
                      })
                : [];

        this.tags =
            tags && tagsList
                ? tagsList
                      .filter((tag) => tags[tag.id])
                      .sort((a, b) => {
                          if (map.tags) return map.tags[a.id] - map.tags[b.id];
                          return 0;
                      })
                      .map((tag) => {
                          return { name: tag.name, id: tag.id, color: tag.color };
                      })
                : [];

        this.notes = mapDetails.notes ? convertFromRaw(markdownToDraft(mapDetails.notes)) : undefined;
    }

    static getMap(fields: MapFormFields): IMap {
        const overseers = Utils.docsToRecord<any, true>(
            fields.overseers,
            (doc) => doc.id,
            () => true
        );
        const assignees = Utils.docsToRecord<any, true>(
            fields.assignees,
            (doc) => doc.id,
            () => true
        );
        const tags = Utils.docsToRecord(
            fields.tags,
            (doc) => doc.id,
            (_doc, index) => index + 1
        );

        return {
            name: fields.name,
            reference: fields.reference,
            color: fields.color,
            returned: fields.returned,
            //Don't use the default date created for the date picker if map is not returned we don't want a date saved
            dateReturned: fields.returned ? fields.dateReturned.toISOString() : null,
            dateAssigned: fields.dateAssigned ? fields.dateAssigned.toISOString() : null,
            overseers,
            assignees,
            tags,
        };
    }

    static getMapDetails(fields: MapFormFields): IMapDetails {
        const notes = fields.notes && fields.notes.hasText() ? draftToMarkdown(convertToRaw(fields.notes)) : null;

        return {
            notes,
        };
    }
}
