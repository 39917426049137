import { SupportedDateFormats } from 'core/MapperCSV';
import { ImportMaps } from 'database';
import { useSnackbar } from 'notistack';
import React from 'react';
import { useDatabase } from 'reactfire';

type UseImportMaps = (
    folderID: string
) => {
    importMaps: (csv: File, dateFormat: SupportedDateFormats) => Promise<void>;
    running: boolean;
};

export const useImportMaps: UseImportMaps = (folderID: string) => {
    const [running, setRunning] = React.useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const database = useDatabase();

    const importMaps = async (csv: File, dateFormat: SupportedDateFormats) => {
        setRunning(true);
        const { error, rows } = await ImportMaps.fromCSV(database, { csv, dateFormat, folderID });
        setRunning(false);
        if (error) {
            enqueueSnackbar(error, { variant: 'error' });
        } else enqueueSnackbar(`Successfully imported ${rows?.length} maps`, { variant: 'success' });
    };

    return {
        running,
        importMaps,
    };
};

export default useImportMaps;
