import { useNavigate } from 'react-router-dom';
import React from 'react';
import { useAuth } from 'reactfire';
import { Formik, Form, Field } from 'formik';
import { Box, Button, createStyles, Divider, LinearProgress, makeStyles, Theme } from '@material-ui/core';
import { TextField } from 'formik-material-ui';
import { MicrosoftIcon, GoogleIcon } from './SocialIcons';
import firebase from 'firebase/app';
import { useSnackbar } from 'notistack';
import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
            flexWrap: 'wrap',
            flexDirection: 'column',
            position: 'relative',
        },
        socialBtn: {
            marginRight: theme.spacing(1),
            marginLeft: theme.spacing(1),
            backgroundColor: '#fff',
            flex: 1,
            padding: 0,
            minWidth: 180,
            minHeight: theme.spacing(5),
            marginBottom: theme.spacing(2),
        },
        loader: {
            position: 'absolute',
            width: '100%',
            bottom: 0,
        },
    })
);

interface FormInputs {
    email: string;
    password: string;
}

export const SignInFormLoading: React.FunctionComponent = () => {
    return (
        <React.Fragment>
            <Box mb={3}>
                <Skeleton variant="rect" height={56} />
            </Box>
            <Box mb={3}>
                <Skeleton variant="rect" height={56} />
            </Box>
            <Box mb={3}>
                <Skeleton variant="rect" height={36} />
            </Box>
            <Divider />
            <Box mt={3}>
                <Skeleton variant="rect" height={56} />
            </Box>
        </React.Fragment>
    );
};

export const SignInForm: React.FunctionComponent = () => {
    const classes = useStyles();
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const auth = useAuth();
    const navigate = useNavigate();

    auth.onAuthStateChanged((user) => {
        if (user) {
            //TODO hack to kinda fix the auth issue with it not being defined
            setTimeout(() => {
                if (window.history.length > 1) navigate(-1);
                else {
                    navigate('/folders', { replace: true });
                }
            }, 200);
        }
    });

    const signInError = (error: any) => {
        console.error(error);
        closeSnackbar();
        if (error && error.code === 'auth/wrong-password') {
            enqueueSnackbar(`Invalid email or password`, { variant: 'error' });
        } else if (error) {
            enqueueSnackbar(`Unable to Sign in`, { variant: 'error' });
        }
    };

    const signInWithEmail = async (email: string, password: string) => {
        if (email && password) {
            try {
                await auth.signInWithEmailAndPassword(email, password);
            } catch (error) {
                signInError(error);
            }
        }
    };

    const googleSignIn = async () => {
        try {
            await auth.signInWithPopup(new firebase.auth.GoogleAuthProvider());
        } catch (error) {
            console.error(error);
        }
    };

    const microsoftSignIn = async () => {
        try {
            await auth.signInWithPopup(new firebase.auth.OAuthProvider('microsoft.com'));
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <Formik
            initialValues={{
                email: '',
                password: '',
            }}
            validate={(values) => {
                const errors: Partial<FormInputs> = {};
                if (!values.email) errors.email = 'Email is required';
                else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) errors.email = 'Invalid email address';

                if (!values.password) errors.password = 'Password is required';

                return errors;
            }}
            onSubmit={async (values, { setSubmitting }) => {
                signInWithEmail(values.email, values.password).finally(() => {
                    setSubmitting(false);
                });
            }}
        >
            {({ submitForm, isSubmitting }) => (
                <Form className={classes.root}>
                    <Box mb={3}>
                        <Field fullWidth component={TextField} variant="outlined" name="email" type="email" label="Email" />
                    </Box>
                    <Box mb={3}>
                        <Field fullWidth component={TextField} variant="outlined" type="password" label="Password" name="password" />
                    </Box>

                    <Box mb={3}>
                        <Button fullWidth variant="contained" color="primary" disabled={isSubmitting} onClick={submitForm}>
                            Sign in
                        </Button>
                    </Box>

                    <Divider />

                    <Box mt={3} display="flex" justifyContent="center" flexWrap="wrap">
                        <Button
                            onClick={googleSignIn}
                            startIcon={<GoogleIcon />}
                            className={classes.socialBtn}
                            variant="contained"
                            disabled={isSubmitting}
                        >
                            Sign in with Google
                        </Button>

                        <Button
                            onClick={microsoftSignIn}
                            startIcon={<MicrosoftIcon />}
                            className={classes.socialBtn}
                            variant="contained"
                            disabled={isSubmitting}
                        >
                            Sign in with Microsoft
                        </Button>
                    </Box>

                    {isSubmitting && <LinearProgress className={classes.loader} />}
                </Form>
            )}
        </Formik>
    );
};

export default SignInForm;
