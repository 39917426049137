import React, { Suspense } from 'react';
import { TextField, Checkbox } from '@material-ui/core';
import { Field } from 'formik';
import { Autocomplete, AutocompleteRenderInputParams } from 'formik-material-ui-lab';
import { IUser } from 'database';
import { Skeleton } from '@material-ui/lab';
import useUsersList from '../hooks/useUsersList';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Props = {
    folderID: string;
    name: string;
    label?: string;
    disabled?: boolean;
    error?: string;
    filter?: (user: IUser) => boolean;
    required?: boolean;
};

export type SelectUserOption = {
    name: string;
    id: string;
    selected?: boolean;
};

const SelectInput: React.FunctionComponent<Props> = (props) => {
    const users = useUsersList(props.folderID, props.filter);

    const [options] = React.useState<SelectUserOption[]>(
        users.map(({ name, id }) => {
            return { name, id };
        })
    );

    return (
        <Field
            name={props.name}
            component={Autocomplete}
            disabled={props.disabled}
            multiple
            disableCloseOnSelect
            getOptionSelected={(option: SelectUserOption, value: SelectUserOption) => option.id === value.id}
            getOptionLabel={(option: SelectUserOption) => option.name}
            options={options}
            noOptionsText="No users"
            renderOption={(option: SelectUserOption, { selected }: { selected: boolean }) => (
                <React.Fragment>
                    <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                    {option.name}
                </React.Fragment>
            )}
            renderInput={(params: AutocompleteRenderInputParams) => (
                <TextField
                    required={props.required}
                    {...params}
                    error={!!props.error}
                    helperText={props.error}
                    label={props.label}
                    variant="outlined"
                />
            )}
        />
    );
};

const SelectUserField: React.FunctionComponent<Props> = (props) => {
    return (
        <Suspense fallback={<Skeleton variant="rect" width="100%" height={56} />}>
            <SelectInput {...props} />
        </Suspense>
    );
};

export default SelectUserField;
