import React, { Suspense } from 'react';
import { TextField, Checkbox, Box } from '@material-ui/core';
import { Field } from 'formik';
import { Autocomplete, AutocompleteRenderInputParams } from 'formik-material-ui-lab';
import { Skeleton } from '@material-ui/lab';
import useTags from '../hooks/useTags';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Tag from './Tag';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

type Props = {
    folderID: string;
    name: string;
    label: string;
    disabled?: boolean;
    error?: string;
    required?: boolean;
};

export type SelectTagOption = {
    name: string;
    color: string;
    id: string;
    selected?: boolean;
};

const TagsInput: React.FunctionComponent<Props> = ({ folderID, required, error, label, name, disabled }) => {
    const tags = useTags(folderID);

    const options = tags.map(({ name, id, color }) => {
        return { name, id, color };
    });

    return (
        <React.Fragment>
            <Field
                name={name}
                component={Autocomplete}
                disabled={disabled}
                multiple
                disableCloseOnSelect
                getOptionSelected={(option: SelectTagOption, value: SelectTagOption) => option.id === value.id}
                getOptionLabel={(option: SelectTagOption) => option.name}
                options={options}
                noOptionsText="No Tags"
                renderTags={(tagValue: SelectTagOption[], getTagProps: any) =>
                    tagValue.map((option, index) => (
                        <Box key={index} display="inline" mr={1}>
                            <Tag label={option.name} color={option.color} {...getTagProps({ index })} />
                        </Box>
                    ))
                }
                renderOption={(option: SelectTagOption, { selected }: { selected: boolean }) => (
                    <React.Fragment>
                        <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                        {option.name}
                    </React.Fragment>
                )}
                renderInput={(params: AutocompleteRenderInputParams) => (
                    <TextField required={required} {...params} error={!!error} helperText={error} label={label} variant="outlined" />
                )}
            />
        </React.Fragment>
    );
};

const SelectTagsField: React.FunctionComponent<Props> = (props) => {
    return (
        <Suspense fallback={<Skeleton variant="rect" width="100%" height={56} />}>
            <TagsInput {...props} />
        </Suspense>
    );
};

export default SelectTagsField;
