import { Box, Breadcrumbs, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { useUser } from 'reactfire';
import PageMeta from '../../components/page/PageMeta';

export const Profile: React.FunctionComponent = () => {
    const { data: user } = useUser<{ displayName: string }>();

    return (
        <React.Fragment>
            <PageMeta title="My Profile" />
            <Box p={3}>
                <Box mb={2}>
                    <Breadcrumbs aria-label="breadcrumb">
                        <Typography variant="h6">Profile</Typography>
                    </Breadcrumbs>
                </Box>

                <Paper>
                    <Box p={3}>{user.displayName}</Box>
                </Paper>
            </Box>
        </React.Fragment>
    );
};

export default Profile;
