import { useDatabase, useDatabaseObjectData } from 'reactfire';
import { IMapDoc, mapsPath } from 'database';

export const useMap = (folderID: string, mapID: string): IMapDoc => {
    const database = useDatabase();
    const mapRef = database.ref(mapsPath({ folderID, mapID }));
    const { data: map } = useDatabaseObjectData<IMapDoc>(mapRef, { idField: 'id' });

    return map;
};

export default useMap;
