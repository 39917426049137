import { MapUtils } from 'database';
import useMaps from './useMaps';

type UseMapsStats = (
    folderID: string
) => {
    total: number;
    overdue: number;
    returned: number;
    assigned: number;
};

export const useMapsStats: UseMapsStats = (folderID: string) => {
    const maps = useMaps(folderID);

    // Todo this can be optomised by only itterating over all maps once
    return {
        total: maps.length,
        overdue: MapUtils.filterOverdue(maps).length,
        returned: MapUtils.filterReturned(maps).length,
        assigned: MapUtils.filterAssigned(maps).length,
    };
};

export default useMapsStats;
