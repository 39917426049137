import { createMap, IMap, IMapDetails } from 'database/maps';
import { UserRole, folderUsersPath, IUser } from 'database';
import Papa from 'papaparse';
import firebase from 'firebase';
import FriendlyError from 'core/FriendlyError';
import moment from 'moment';
import { MapCSV, MapCSVRow, MAP_TAG_COLOR_SEPERATOR, MAP_USER_SEPERATOR, SupportedDateFormats } from 'core/MapperCSV';
import { fetchUsers, IUserDoc } from 'database/users';
import { ITag } from 'database/tags';
import fetchTags from 'database/tags/actions/fetchTags';
import { encodeEmail, folderTagsPath } from 'database/Config';
import { generate as uuid } from 'short-uuid';

enum ImportError {
    IMPORT_ERROR = 'Unable to import CSV',
    INVALID = 'Invalid csv unable to import ',
}

type ImportResult = {
    error?: ImportError;
    rows?: Array<MapCSVRow>;
};

type ImportOptions = {
    csv: File | string;
    dateFormat: SupportedDateFormats;
    folderID: string;
};

type TagsWithID = Record<string, ITag & { id: string }>;

export class ImportMaps {
    static async fromCSV(database: firebase.database.Database, options: ImportOptions): Promise<ImportResult> {
        return new Promise((resolve) => {
            try {
                Papa.parse(options.csv, {
                    header: true,
                    complete: async (result: Papa.ParseResult<MapCSVRow>) => {
                        try {
                            const mapCSV = ImportMaps.validate(result.data);
                            if (mapCSV.valid) {
                                await ImportMaps.import(database, mapCSV, options);
                                resolve({ rows: mapCSV.rows });
                            } else {
                                console.error('The following rows are invalid', mapCSV.invalidRows);
                                resolve({ error: ImportError.INVALID });
                            }
                        } catch (error) {
                            resolve({ error: ImportError.IMPORT_ERROR });
                        }
                    },
                });
            } catch (error) {
                resolve({ error: ImportError.IMPORT_ERROR });
            }
        });
    }

    private static validate(rows: Array<MapCSVRow>): MapCSV {
        const csv: MapCSV = { rows: [], invalidRows: [], valid: false };

        for (const row of rows) {
            if (
                (row['Date Assigned'] || row['Date Returned']) &&
                row['Map Name'] &&
                row['Map Name'].trim() &&
                row.Overseers &&
                row.Overseers.trim()
            ) {
                csv.rows.push(row);
            } else {
                csv.invalidRows.push(row);
            }
        }

        csv.valid = !csv.invalidRows.length;

        return csv;
    }

    private static textToMatchingID = (textKey: string, docs: Record<string, any> = {}): Record<string, string> => {
        const textToId: Record<string, string> = {};
        const docIds = Object.keys(docs);

        for (const id of docIds) {
            const doc = docs[id];
            const text = doc[textKey];
            textToId[text] = id;
        }

        return textToId;
    };

    private static async import(db: firebase.database.Database, csv: MapCSV, options: ImportOptions) {
        try {
            const newUsersMap: Record<string, IUserDoc> = {};
            const newTagsMap: TagsWithID = {};

            const updates: Record<string, IMap | IUser | ITag | true> = {};
            const { users } = await fetchUsers(db, options.folderID);
            const { tags } = await fetchTags(db, options.folderID);

            if (!users) throw new Error('Unable to fetch existing users');

            const usersNameToID = ImportMaps.textToMatchingID('name', users);
            const tagNamesToId = ImportMaps.textToMatchingID('name', tags);

            for (const row of csv.rows) {
                const overseers = ImportMaps.mapUserFromRow(row.Overseers, UserRole.OVERSEER, newUsersMap, usersNameToID, users);
                let assignees = row.Assignees ? ImportMaps.mapUserFromRow(row.Assignees, UserRole.ASSIGNEE, newUsersMap, usersNameToID, users) : {};
                const mapTags = ImportMaps.mapTagsFromRow(db, row, newTagsMap, options.folderID, tagNamesToId);

                const dateReturned = row['Date Returned'] ? moment(row['Date Returned'], options.dateFormat).toISOString() : null;
                const dateAssigned = moment(row['Date Assigned'], options.dateFormat).toISOString();
                const returned = Boolean(dateReturned);

                //If there is not a assignee and the map has been assigned use the overseer as the assignee
                if (!assignees && !returned) assignees = overseers;

                const map: IMap = {
                    name: row['Map Name'].trim(),
                    returned,
                    dateReturned,
                    dateAssigned,
                    overseers: overseers || {},
                    assignees: assignees || {},
                    tags: mapTags || {},
                };

                if (row.Reference && row.Reference.trim()) map.reference = row.Reference.trim();

                if (row.Color) map.color = row.Color.trim();

                const mapDetails: IMapDetails = {
                    notes: row['Notes'] || null,
                };

                const { updates: mapUpdates } = await createMap(db, options.folderID, map, mapDetails, false);

                if (updates) Object.assign(updates, mapUpdates);
            }

            Object.keys(newUsersMap).forEach((key) => {
                const { name, email, role, id } = newUsersMap[key];
                if (email && id) {
                    const user: IUserDoc = {
                        id,
                        name,
                        email: encodeEmail(email),
                        role,
                    };
                    updates[folderUsersPath({ folderID: options.folderID, email })] = user;
                }
            });

            Object.keys(newTagsMap).forEach((key) => {
                const { id, color, name } = newTagsMap[key];
                if (id) updates[folderTagsPath({ folderID: options.folderID, tagID: id })] = { name, color };
            });

            await db.ref().update(updates);
        } catch (error) {
            console.error(error);
            throw new FriendlyError(ImportError.IMPORT_ERROR, null, error);
        }
    }

    private static csvUserToMapperUser(user: string, role: UserRole): IUser {
        const [name, email] = user.split(MAP_USER_SEPERATOR);

        return {
            email: email ? encodeEmail(email.trim()) : '',
            name: name.trim(),
            role,
        };
    }

    private static mapUserFromRow(
        column: string,
        role: UserRole,
        newUsers: Record<string, IUserDoc>,
        usersNameToID: Record<string, string>,
        folderUsers: Record<string, IUserDoc>
    ): Record<string, true> {
        const users =
            column
                .trim()
                .split(',')
                .map((name) => name.trim())
                .filter((name) => Boolean(name)) || [];

        const mapUsers: Record<string, true> = {};

        for (const user of users) {
            const { email, name } = ImportMaps.csvUserToMapperUser(user, role);

            const existingUser = folderUsers[usersNameToID[name]] || newUsers[usersNameToID[name]];
            if (existingUser) {
                mapUsers[existingUser.id] = true;
            } else {
                const newID = uuid();
                const newUser: IUserDoc = { name, email: email ? email : newID, role, id: newID };
                newUsers[newUser.id] = newUser;
                mapUsers[newUser.id] = true;
                usersNameToID[newUser.name] = newUser.id;
            }
        }

        return mapUsers;
    }

    private static mapTagsFromRow(
        db: firebase.database.Database,
        row: MapCSVRow,
        newTags: TagsWithID,
        folderID: string,
        tagNameToId: Record<string, string>
    ): Record<string, number> {
        const tagTexts =
            row.Tags?.trim()
                .split(',')
                .map((name) => name.trim())
                .filter((name) => Boolean(name)) || [];

        const mapTags: Record<string, number> = {};

        for (const tagText of tagTexts) {
            const [name, color] = tagText.split(MAP_TAG_COLOR_SEPERATOR);
            const index = tagTexts.indexOf(tagText) + 1;
            const existingTagId = tagNameToId[name];
            if (existingTagId) {
                // Keep the same tag ordering;
                mapTags[existingTagId] = index;
            } else {
                const tagID = db.ref(folderTagsPath({ folderID })).push().key;
                if (!tagID) throw new Error('Unexpected Error unable to create id for tag');

                newTags[tagID] = { name: name.trim(), id: tagID, color: color.trim() };
                mapTags[tagID] = index;
                tagNameToId[name] = tagID;
            }
        }

        return mapTags;
    }
}

export default ImportMaps;
