import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { IMapDoc } from 'database';
import React, { useState } from 'react';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import useDeleteMap from '../hooks/useDeleteMap';
import { useNavigate } from 'react-router';
import ConfirmDialog, { ConfirmDialogOptions } from 'components/dialogs/ConfirmDialog';
import FileDialog from 'components/dialogs/FileDialog';
import useImportBoundaries from '../hooks/useImportBoundaries';
import { IMapBoundaryDoc } from 'database/maps/MapBoundary';
import useRemoveMapBoundary from '../hooks/useRemoveMapBoundary';
import TextFieldDialog, { TextFieldDialogOptions } from 'components/dialogs/TextFieldDialog';

type Props = {
    map: IMapDoc;
    boundary?: IMapBoundaryDoc;
    folderID: string;
};

export const MapSettingsMenu: React.FunctionComponent<Props> = ({ map, folderID, boundary }) => {
    const { deleteMap } = useDeleteMap(folderID);
    const { importBoundaries } = useImportBoundaries(folderID);
    const { removeBoundary } = useRemoveMapBoundary(folderID);

    const navigate = useNavigate();

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [showConfirm, setShowConfirm] = useState(false);
    const [showConfirmRemoveBoundary, setShowConfirmRemoveBoundary] = useState(false);

    const [showImport, setShowImport] = useState(false);

    const handleDelete = async () => {
        deleteMap(map).then(() => navigate(-1));
        setShowConfirm(false);
    };

    const handleImport = (files: Array<File>) => {
        importBoundaries(map, files[0]).then(() => {
            console.log('Imported');
            setShowImport(false);
        });
    };

    const handleRemoveBoundary = () => {
        removeBoundary(map).finally(() => {
            setShowConfirmRemoveBoundary(false);
        });
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const dialogOptions: TextFieldDialogOptions = {
        initialValue: '',
        label: 'Enter Map Name',
        title: 'Confirm',
        confirmLabel: 'Delete Map',
        description: `Enter the map name to confirm.
        This action can not be undone.`,
    };

    const confirmRemoveBoundary: ConfirmDialogOptions = {
        confirmLabel: 'Remove',
        description: `Are you sure you want to remove the boundary from the map ${map.name}?`,
        title: 'Confirm',
    };

    return (
        <React.Fragment>
            <ConfirmDialog
                open={showConfirmRemoveBoundary}
                onSubmit={handleRemoveBoundary}
                onClose={() => setShowConfirmRemoveBoundary(false)}
                options={confirmRemoveBoundary}
            />

            <TextFieldDialog
                onSubmit={handleDelete}
                open={showConfirm}
                onClose={() => setShowConfirm(false)}
                options={dialogOptions}
                textToMatch={map.name}
                matchErrorMsg={`Must match map name ${map.name}`}
            ></TextFieldDialog>

            <FileDialog allowed={['.geojson']} open={showImport} onSubmit={handleImport} onClose={() => setShowImport(false)} />

            <IconButton aria-label="settings" aria-controls="map-settings-menu" aria-haspopup="true" onClick={handleClick}>
                <SettingsIcon />
            </IconButton>
            <Menu id="map-settings-menu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
                <MenuItem
                    onClick={() => {
                        handleClose();
                        setShowImport(true);
                    }}
                >
                    Import Boundary
                </MenuItem>

                {boundary && (
                    <MenuItem
                        onClick={() => {
                            handleClose();
                            setShowConfirmRemoveBoundary(true);
                        }}
                    >
                        Remove Boundary
                    </MenuItem>
                )}
                <MenuItem
                    onClick={() => {
                        handleClose();
                        setShowConfirm(true);
                    }}
                >
                    Delete Map
                </MenuItem>
            </Menu>
        </React.Fragment>
    );
};

export default MapSettingsMenu;
