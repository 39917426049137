import { Avatar, createStyles, makeStyles } from '@material-ui/core';
import { IMap } from 'database';
import React from 'react';
import tinycolor from 'tinycolor2';

const useStyles = makeStyles((theme) =>
    createStyles({
        sm: {
            width: theme.spacing(4),
            height: theme.spacing(4),
        },
        md: {},
        lg: {
            width: theme.spacing(7),
            height: theme.spacing(7),
        },
    })
);

type Props = {
    map: IMap;
    size?: 'sm' | 'md' | 'lg';
};

export const MapAvatar: React.FunctionComponent<Props> = ({ map, size = 'md' }) => {
    const avatarText = (map.reference || map.name || '').substring(0, 2).toUpperCase();

    const mapColour = map.color || '#bdbdbd';

    const classes = useStyles();
    const bgColour = tinycolor(mapColour);
    const contrastColor = tinycolor
        .mostReadable(tinycolor(mapColour), [tinycolor(mapColour).lighten(50), tinycolor(mapColour).darken(50)])
        .toHexString();

    return (
        <Avatar
            className={classes[size]}
            style={{
                backgroundColor: tinycolor(bgColour).setAlpha(0.7).toRgbString(),
                color: contrastColor,
                border: `1px solid ${tinycolor(mapColour).brighten().setAlpha(1).toRgbString()}`,
            }}
        >
            {avatarText}
        </Avatar>
    );
};

export default MapAvatar;
