import { folderTagsPath, ITagDoc } from 'database';
import { useDatabase, useDatabaseListData } from 'reactfire';

export const useTags = (folderID: string): Array<ITagDoc> => {
    const ref = useDatabase().ref(folderTagsPath({ folderID }));
    const { data: tags } = useDatabaseListData<ITagDoc>(ref, { idField: 'id' });

    return tags;
};

export default useTags;
