import firebase from 'firebase';

export default class Config {
    static readonly VERSION = '0.1.0-alpha.4';

    static readonly APP_NAME: string = 'Mapper';

    static readonly IS_DEV = typeof window === 'undefined' || (window && window.location.hostname === 'localhost');

    private static projectID = 'mapper-1601156723572';

    static get databaseURL() {
        return Config.IS_DEV ? `http://localhost:9000?ns=${Config.projectID}` : `https://${Config.projectID}.firebaseio.com`;
    }

    static get authURL() {
        return Config.IS_DEV ? `http://localhost:9099` : `${Config.projectID}.firebaseapp.com`;
    }

    static get host() {
        return window.location.host;
    }

    static get firebaseConfig() {
        return {
            apiKey: 'AIzaSyAgQu5VNpOnZSAv33IQwrV6iUjcQMsdu5s',
            authDomain: Config.authURL,
            databaseURL: Config.databaseURL,
            projectId: Config.projectID,
            appId: Config.projectID,
        };
    }

    static configureFirebase(app: firebase.app.App) {
        if (Config.IS_DEV) {
            app.auth().useEmulator(Config.authURL);
        }
    }

    static getInviteURL(folderID: string, inviteID: string) {
        return `${Config.host}/invite/${folderID}/${inviteID}`;
    }
}
