import React from 'react';
import { Avatar, IconButton, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import MoreIcon from '@material-ui/icons/MoreVert';
import { IUserDoc, UserAction, decodeEmail, UserRole } from 'database';
import UserMenu from './UserMenu';
import RoleCheck from './RoleCheck';

type Props = {
    user: IUserDoc;
    onUserAction: (action: UserAction, user: IUserDoc) => void;
    divider?: boolean;
    folderID: string;
};

export const UserListItem: React.FunctionComponent<Props> = ({ folderID, user, divider, onUserAction }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLButtonElement>(null);

    const openMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const email = user.email === user.id ? null : decodeEmail(user.email);

    return (
        <ListItem divider={divider}>
            <ListItemAvatar>
                <Avatar>
                    <PersonIcon />
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={user.name} secondary={email} />

            <RoleCheck folderID={folderID} shownFor={[UserRole.OWNER, UserRole.ADMIN]}>
                <ListItemSecondaryAction>
                    <IconButton aria-haspopup="true" onClick={(event) => openMenu(event)}>
                        <MoreIcon />
                    </IconButton>

                    <UserMenu folderID={folderID} onClose={handleClose} user={user} anchorEl={anchorEl} onUserAction={onUserAction} />
                </ListItemSecondaryAction>
            </RoleCheck>
        </ListItem>
    );
};

export default UserListItem;
