import React from 'react';
import { UserRole } from 'database';
import useUser from '../hooks/useUser';

type Props = {
    shownFor: Array<UserRole>;
    folderID: string;
};

export const RoleCheck: React.FunctionComponent<Props> = ({ folderID, shownFor, children }) => {
    const authedUser = useUser(folderID);

    if (shownFor.indexOf(authedUser.role) > -1) {
        return <React.Fragment>{children}</React.Fragment>;
    } else {
        return null;
    }
};

export default RoleCheck;
