import React from 'react';
import { IMapDoc, ITagDoc, IUser } from 'database';
import { Box, Chip } from '@material-ui/core';
import AssigneeIcon from '@material-ui/icons/AssignmentIndOutlined';
import Tag from 'views/folders/folder/tags/components/Tag';
import OverseerIcon from '@material-ui/icons/SupervisedUserCircleOutlined';

type Props = {
    map: IMapDoc;
    users: Record<string, IUser>;
    tags: Array<ITagDoc>;
    showOverseers?: boolean;
};

const recordToTag = (record: Record<string, true>, users: Record<string, IUser | undefined>): Array<string> => {
    return Object.keys(record).map((key) => {
        const user = users[key];
        return user ? user.name : 'Unknown';
    });
};

export const MapTags: React.FunctionComponent<Props> = ({ map, users, tags, showOverseers }) => {
    const mapTags = tags
        .filter((tag) => map.tags && map.tags[tag.id])
        .sort((a, b) => {
            if (map.tags) return map.tags[a.id] - map.tags[b.id];
            return 0;
        });

    return (
        <Box display="flex" flexWrap="wrap">
            {showOverseers &&
                map.overseers &&
                recordToTag(map.overseers, users).map((tag, index) => (
                    <Box mr={1} mb={1} key={index}>
                        <Chip icon={<OverseerIcon />} label={tag} variant="outlined" />
                    </Box>
                ))}
            {map.assignees &&
                recordToTag(map.assignees, users).map((tag, index) => (
                    <Box mr={1} mb={1} key={index}>
                        <Chip icon={<AssigneeIcon />} label={tag} variant="outlined" />
                    </Box>
                ))}
            {map.tags &&
                mapTags.map((tag, index) => (
                    <Box mb={1} mr={1} key={index}>
                        <Tag key={index} label={tag.name} color={tag.color} />
                    </Box>
                ))}
        </Box>
    );
};

export default MapTags;
